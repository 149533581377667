import { Button } from 'antd'
import React from 'react'
import TextBoldParagraph from './TextBoldParagraph'

export default function ButtonWallet({ text, textRed, colorText, textAlign, buttonAction, width, background }) {
    return (
        <Button type='default' onClick={buttonAction} style={{
            background: background ? background : "linear-gradient(black, black) padding-box, linear-gradient(to right, #B3122A, #CA1BA3) border-box",
            borderRadius: "50em",
            border: "2px solid transparent",
            textAlign: textAlign ? textAlign : "center",
            color: colorText ? colorText : "#FFFFFF",
            width: width ? width : "100%",
        }}>
            <TextBoldParagraph text={text} textRed={textRed} colorText={colorText} textAlign={"center"} />
        </Button>
    )
}