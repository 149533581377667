import React, { useEffect, useState } from "react";
import TextSemiboldH4 from "../../atomos/TextSemiboldH4";
import MissionsCard from "../MissionsCard/MissionsCard";
import { Parallax } from "rc-scroll-anim";
import "./MissionToWinRedCoins.css";
import { Flex, Segmented, Select, Typography } from "antd";
import ImageRedCoins from "../../atomos/ImageRedCoins";
import TextBoldParagraph from "../../atomos/TextBoldParagraph";
import ImageTimerRed from "../../atomos/ImageTimerRed";
import ImageRare from "../../atomos/ImageRare";
import checkIsMobile from "../../../utils/CheckIsMobile";
import { MissionService } from "red-canids-client-module/client-web/services/MissionService";
import { useDispatch, useSelector } from "react-redux";
export default function MissionToWinRedCoins({
  refTour,
  showOrder,
  limitListShow,
  textTitle,
}) {
  const colors = require("../../../styles/Colors.json");
  const [missions, setMissions] = useState([]);
  const [selectedOption, setSelectedOption] = useState("option_1");
  const account = useSelector((state) => state.account);
  useEffect(() => {
    var filter = [];
    MissionService.returnAllMission()
      .then((res) => {
        var filteredMissions = [];
        filteredMissions = res.data.filter((mission) => {
          // Verifica se a missão não está no array de missões completas
          return !account.missions_completed.some(
            (completedMission) =>
              completedMission.id_mission === mission._id.$oid
          );
        });
        setMissions(filteredMissions);
      })
      .catch((error) => {
        
      });
  }, [account.missions_completed]);
  const convertTimeStringToMilliseconds = (timeString) => {
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    return (hours * 3600 + minutes * 60 + seconds) * 1000;
  };
  const isMissionExpired = (mission) => {
    if (mission.expiration_date) {
      const expiratedDate = new Date(mission.expiration_date);
      return expiratedDate < new Date();
    }
    return false;
  };
  const filteredMissions = missions.filter((mission) => {
    if (selectedOption === "option_1") {
      return mission && !isMissionExpired(mission);
    }
    if (selectedOption === "option_2") {
      return mission.classification === "rare" && !isMissionExpired(mission);
    } else if (selectedOption === "option_3") {
      return mission.points > 0 && !isMissionExpired(mission);
    } else if (selectedOption === "option_4") {
      return (
        mission.time_mission &&
        convertTimeStringToMilliseconds(mission.time_mission) > 0 &&
        convertTimeStringToMilliseconds(mission.time_mission) <= Date.now() &&
        !isMissionExpired(mission)
      );
    }
    return true;
  });
  const sortedMissions =
    selectedOption === "option_2"
      ? filteredMissions.sort((a, b) => {
          if (b.points !== a.points) {
            return b.points - a.points;
          } else {
            return (
              convertTimeStringToMilliseconds(b.time_mission) -
              convertTimeStringToMilliseconds(a.time_mission)
            );
          }
        })
      : selectedOption === "option_3"
      ? filteredMissions.sort((a, b) => b.points - a.points)
      : selectedOption === "option_4"
      ? filteredMissions.sort(
          (a, b) =>
            convertTimeStringToMilliseconds(b.time_mission) -
            convertTimeStringToMilliseconds(a.time_mission)
        )
      : selectedOption === "option_1"
      ? filteredMissions.sort((a, b) => {
          if (b.points !== a.points) {
            return b.points - a.points;
          } else {
            return (
              convertTimeStringToMilliseconds(b.time_mission) -
              convertTimeStringToMilliseconds(a.time_mission)
            );
          }
        })
      : filteredMissions;
  return (
    <Flex vertical ref={refTour}>
      <Flex
        align="center"
        justify="center"
        style={{
          marginTop: 20,
          alignSelf: "center",
          width: "90%",
        }}
      >
        {!checkIsMobile() ? (
          showOrder ? (
            <Flex
              gap={20}
              align="center"
              justify="space-between"
              style={{
                width: "100%",
              }}
            >
              <TextSemiboldH4
                text={textTitle ? textTitle : "Missões para ganhar RED Coins"}
              />
              <Segmented
                options={[
                  {
                    label: (
                      <Flex
                        align="center"
                        justify="center"
                        style={{
                          width: "100%",
                          height: 50,
                        }}
                      >
                        <TextBoldParagraph
                          text={"Todas as Missões"}
                          colorText={colors.Grayscale}
                        />
                      </Flex>
                    ),
                    value: "option_1",
                  },
                  {
                    label: (
                      <Flex
                        align="center"
                        justify="center"
                        style={{
                          width: "100%",
                          height: 50,
                        }}
                      >
                        <ImageRare />
                        <TextBoldParagraph
                          text={"Missões raras"}
                          colorText={colors.Grayscale}
                        />
                      </Flex>
                    ),
                    value: "option_2",
                  },
                  {
                    label: (
                      <Flex
                        align="center"
                        justify="center"
                        gap={5}
                        style={{
                          width: "100%",
                          height: 50,
                        }}
                      >
                        <ImageRedCoins />
                        <TextBoldParagraph
                          text={"Para ganhar mais"}
                          colorText={colors.Grayscale}
                        />
                        <TextBoldParagraph
                          text={"RED Coins"}
                          colorText={colors.BaseSlot2}
                        />
                      </Flex>
                    ),
                    value: "option_3",
                  },
                  {
                    label: (
                      <Flex
                        align="center"
                        justify="center"
                        gap={5}
                        style={{
                          width: "100%",
                          height: 50,
                        }}
                      >
                        <ImageTimerRed />
                        <TextBoldParagraph
                          text={"Por tempo limitado"}
                          colorText={colors.Grayscale}
                        />
                      </Flex>
                    ),
                    value: "option_4",
                  },
                ]}
                onChange={(value) => setSelectedOption(value)}
              />
            </Flex>
          ) : (
            <Flex
              gap={20}
              align="center"
              justify="center"
              style={{
                width: "90%",
              }}
            >
              <Typography.Title
                level={2}
                style={{
                  color: "white",
                  alignSelf: "center",
                  justifySelf: "center",
                }}
              >
                Missões para ganhar RED Coins
              </Typography.Title>
            </Flex>
          )
        ) : (
          <Flex
            vertical
            gap={20}
            align="center"
            justify="center"
            style={{
              width: "100%",
            }}
          >
            <Typography.Title
              level={2}
              style={{
                color: "white",
                alignSelf: "center",
                justifySelf: "center",
              }}
            >
              Missões para ganhar RED Coins
            </Typography.Title>
            {!showOrder ? (
              <></>
            ) : (
              <Select
                style={{
                  width: "100%",
                  height: 50,
                }}
                onChange={(value) => setSelectedOption(value)}
                defaultValue={"option_1"}
              >
                <Select.Option value="option_1">
                  <Flex
                    align="center"
                    justify="center"
                    style={{
                      width: "100%",
                      height: 50,
                      background: colors.GrayscaleSlot5,
                    }}
                  >
                    <TextBoldParagraph
                      text={"Todas as Missões"}
                      colorText={colors.Grayscale}
                    />
                  </Flex>
                </Select.Option>
                <Select.Option value="option_2">
                  <Flex
                    align="center"
                    justify="center"
                    style={{
                      width: "100%",
                      height: 50,
                      background: colors.GrayscaleSlot5,
                    }}
                  >
                    <ImageRare />
                    <TextBoldParagraph
                      text={"Missões raras"}
                      colorText={colors.Grayscale}
                    />
                  </Flex>
                </Select.Option>
                <Select.Option value="option_3">
                  <Flex
                    align="center"
                    justify="center"
                    gap={5}
                    style={{
                      width: "100%",
                      height: 50,
                      background: colors.GrayscaleSlot5,
                    }}
                  >
                    <ImageRedCoins />
                    <TextBoldParagraph
                      text={"Para ganhar mais"}
                      colorText={colors.Grayscale}
                    />
                    <TextBoldParagraph
                      text={"RED Coins"}
                      colorText={colors.BaseSlot2}
                    />
                  </Flex>
                </Select.Option>
                <Select.Option value="option_4">
                  <Flex
                    align="center"
                    justify="center"
                    gap={5}
                    style={{
                      width: "100%",
                      height: 50,
                      background: colors.GrayscaleSlot5,
                    }}
                  >
                    <ImageTimerRed />
                    <TextBoldParagraph
                      text={"Por tempo limitado"}
                      colorText={colors.Grayscale}
                    />
                  </Flex>
                </Select.Option>
              </Select>
            )}
          </Flex>
        )}
      </Flex>
      <Flex
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {sortedMissions.length <= 0 ? (
          <>
            <p>O Rufus está preparando novas missões, aguarde ...</p>
          </>
        ) : (
          ""
        )}
        {sortedMissions
          .slice(limitListShow ? sortedMissions.length - limitListShow : 0)
          .map((item) => {
            return (
              <Parallax
                always={false}
                animation={{ x: 0, opacity: 1, playScale: [0.1, 0.5] }}
                style={{ transform: "translateX(100px)", opacity: 0 }}
                key={item._id}
              >
                <MissionsCard params={item} />
              </Parallax>
            );
          })}
      </Flex>
    </Flex>
  );
}
