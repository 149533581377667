import React from "react";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { AccountService } from "red-canids-client-module/client-web";
import { addUser } from "../features/account/account";

export default function ProtectedRoute(params) {
    const dispatch = useDispatch();
    const loginSession = localStorage.getItem("@_auth_sessions");
    if (loginSession) {
        AccountService.returnAccountByEmailAndCodeAccess(
            JSON.parse(atob(loginSession)).email,
            JSON.parse(atob(loginSession)).code_access
        )
            .then((response) => {
                
                dispatch(addUser(response.data));
            })
            .catch((error) => {
                console.log("ERROR GET ACCOUNT API", error);
            });
        return <Navigate to="/home" />;
    } else {
        return <Navigate to="/login" />;
    }
}
