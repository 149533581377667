import React from "react";
import BaseLayout from "../../components/templates/BaseLayout/BaseLayout";
import ManagerUsersStatisticsTemplate from "../../components/templates/ManagerUsersStatisticsTemplate/ManagerUsersStatisticsTemplate";

export default function ManagerUsersStatistics() {
  return (
    <>
      <BaseLayout currentKey="ManagerUsersStatistics" content={<ManagerUsersStatisticsTemplate />} />
    </>
  );
}
