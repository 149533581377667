import React from "react";

export default function ImageYoutubeRed({ width, height, style }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="41" height="40" viewBox="0 0 41 40" fill="none"
            style={{
                width: width ? width : 30,
                height: height ? height : 30,
                ...style,
            }}>
            <path d="M36.4037 10.8298C37.1654 13.7998 37.1654 19.9998 37.1654 19.9998C37.1654 19.9998 37.1654 26.1998 36.4037 29.1698C35.9804 30.8115 34.742 32.1032 33.1737 32.5398C30.3254 33.3332 20.4987 33.3332 20.4987 33.3332C20.4987 33.3332 10.677 33.3332 7.8237 32.5398C6.2487 32.0965 5.01203 30.8065 4.5937 29.1698C3.83203 26.1998 3.83203 19.9998 3.83203 19.9998C3.83203 19.9998 3.83203 13.7998 4.5937 10.8298C5.01703 9.18817 6.25536 7.8965 7.8237 7.45984C10.677 6.6665 20.4987 6.6665 20.4987 6.6665C20.4987 6.6665 30.3254 6.6665 33.1737 7.45984C34.7487 7.90317 35.9854 9.19317 36.4037 10.8298ZM17.1654 25.8332L27.1654 19.9998L17.1654 14.1665V25.8332Z" fill="#FE304E" />
        </svg>
    )
} 