import React, { useEffect, useRef, useState } from "react";
import "./FinderMissionTemplate.css";
import { TikTok } from "react-tiktok";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import MenuDefaultClient from "../../organisms/Menu/Menu";
import HeaderAfterMenu from "../../organisms/HeaderAfterMenu/HeaderAfterMenu";
import ProgressPointWatchVideo from "../../organisms/ProgressPointWatchVideo/ProgressPointWatchVideo";
import Footer from "../../organisms/Footer.js/Footer";
import { AccountService, MissionService, OpenAPI } from "red-canids-client-module/client-web";
import { useDispatch, useSelector } from "react-redux";
import { addRedCoins } from "../../../features/account/account";
import TextRegularH6 from "../../atomos/TextRegularH6";
import { Flex, Button, Form, Input, message, Alert } from "antd";
import MenuInfoUser from "../../organisms/MenuInfoUser/MenuInfoUser";
import TextSemiboldH4 from "../../atomos/TextSemiboldH4";
import TextRegularLabel from "../../atomos/TextRegularLabel";
import TextInputBox from "../../atomos/TextInputBox";
import Confetti from 'react-confetti';

export default function FinderMissionTemplate() {
  const { id_mission } = useParams();

  const colors = require("../../../styles/Colors.json");

  const navigate = useNavigate();

  const missionSelec = useSelector((state) => state.missionSelected);
  const account = useSelector((state) => state.account);

  const [progress, setProgress] = React.useState(0);
  const [sizeScreenWidth, setSizeScreenWidth] = React.useState(
    window.innerWidth
  );
  const [mission, setMision] = useState(missionSelec);
  const [indexVideo, setIndexVideo] = React.useState(0);
  const [showConfetti, setShowConfetti] = useState(false);

  const videoFirstTime = useRef(true);

  const reactPlayerRef = useRef(null);

  const isPlayer = useRef(false);

  const location = useLocation();
  const dispatch = useDispatch();

  const setTimeoutProgress = useRef(null);

  useEffect(() => { }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!setTimeoutProgress.current) {
      setTimeoutProgress.current = setInterval(() => {
        if (isPlayer.current) {
          setProgress((oldProgress) => {
            return oldProgress >= 100 ? 100 : oldProgress + 5;
          });
        }
      }, 1000);
    }
  }, []);

  useEffect(() => {
    if (progress >= 100) {
      clearInterval(setTimeoutProgress.current);
      dispatch(addRedCoins(missionSelec.points));
    }
  }, [progress]);

  const itemsBreadCrumb = [
    {
      title: "Home",
      path: "/home",
    },
    {
      title: "Missões",
      path: "/missions",
    },
    {
      title: "Detalhe da missão",
      path: "/missions/detail/" + id_mission,
    },
    {
      title: "Assistir vídeo",
    },
  ];

  function onFinish(values) {
    // OpenAPI.BASE = "http://localhost:8000";
    MissionService.validateMissionFinder(missionSelec._id.$oid, values.code_mission).then((response) => {
      AccountService.updateMissionCompleted({
        id_account: account._id.$oid,
        id_mission: missionSelec._id.$oid,
        points: missionSelec.points,
        type_mission: missionSelec.type_mission,
        type_points: missionSelec.type_points,
      }).then((response) => {
        setShowConfetti(true);
        dispatch(addRedCoins(missionSelec.points))
      }).catch((error) => {

      }).finally(() => {

      })
    }).catch((error) => {
      errorToast();
    }
    ).finally(() => {

    });
  }

  function onFinishFailed(errorInfo) {

  }
  const [messageApi, contextHolder] = message.useMessage();

  function errorToast() {
    message.error('Código inválido!');
  }

  return (
    <>
      {contextHolder}
      <MenuDefaultClient page={location} />
      <HeaderAfterMenu
        title={missionSelec.name_mission}
        items={itemsBreadCrumb}
      />
      {/* <ProgressPointWatchVideo
        progress={progress}
        width={sizeScreenWidth > 853 ? 853 : sizeScreenWidth}
      /> */}
      {
        showConfetti && (
          <Confetti
            width={window.innerWidth}
            height={window.innerHeight}
            recycle={false}
            gravity={7}
            numberOfPieces={500}
            colors={["#ff0000", "#ff7300", "#fffb00", "#48ff00", "#00ffd5", "#002bff", "#7a00ff", "#ff00c8", "#ff0000"]}
          />
        )
      }

      <Flex
        className="root-finder-mission-template"
        style={{ width: sizeScreenWidth > 853 ? 853 : sizeScreenWidth }}
      >
        <Flex className="div-template-content">
          <div className="div-title">
            <TextSemiboldH4
              text={
                "Olá " + account.name + ", entre no link abaixo e encontre o código para completar a sua missão!"
              }
            />
          </div>
          <Button
            type="primary"
            className="button-border"
            style={{ width: 300, marginBottom: 20, alignSelf: "center" }}
            onClick={() => navigate("/missions")}
          >
            Voltar para missões
          </Button>
          <Form
            name="basic"
            labelAlign="left"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignSelf: "center",
                marginTop: 18,
              }}
            >
              <a
                href={missionSelec.url_mission}
                target="_blank"
                rel="noreferrer"
              >
                <TextRegularH6 text={missionSelec.url_mission} />
              </a>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  marginTop: 18,
                }}
              >
                <TextRegularLabel
                  fontSize={14}
                  text={"Digite o código"}
                  colorText={colors.Grayscale}
                />
              </div>
              <div
                style={{
                  marginTop: 8,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Form.Item
                  name="code_mission"
                  rules={[
                    {
                      required: true,
                      message: "Por favor por código da missão!",
                    },
                  ]}
                >
                  <Input placeholder="Código da missão" color={colors.Grayscale} style={{ maxWidth: 480, width: "90vw" }} />
                </Form.Item>
                {/* <Form.Item>
                  <TextInputBox 
                  placeholder="Digite o código"
                  colorText={colors.Grayscale} />
                </Form.Item> */}
              </div>
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  marginTop: 10,
                }}
              >
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ maxWidth: 480, width: "90vw" }}
                    className="button-border"
                  >
                    Resgatar
                  </Button>
                  {
                    showConfetti && (
                      <Alert message="Parabéns, código encontrado com sucesso!" type="success" style={{ marginTop: 10 }} />
                    )
                  }
                </Form.Item>
              </div>
            </div>
          </Form>
        </Flex>
      </Flex>
      <Footer />
    </>
  );
}
