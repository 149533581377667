import React from "react";
import TextRegularH6 from "../../atomos/TextRegularH6";
import ImageRedExp from "../../atomos/ImageRedExp";
import ImageRedCoins from "../../atomos/ImageRedCoins";
import "./MenuInfoUser.css";
import checkIsMobile from "../../../utils/CheckIsMobile";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import RedStaffIcon from "../../atomos/RedStaffIcon";
import SilverIcon from "../../atomos/SilverIcon";
import GoldIcon from "../../atomos/GoldIcon";
import { Flex } from "antd";

export default function MenuInfoUser() {
  const navigate = useNavigate();

  const accont = useSelector((state) => state.account);

  return (
    <>
      {!checkIsMobile() ? (
        <div
          className="div-menu-five"
          onClick={() => navigate("/profile-page")}
        >
          <div className="image-menu-five">
            <img
              src={
                accont?.photo_profile
                  ? accont?.photo_profile
                  : "https://redcanidsstorage.blob.core.windows.net/redcanids/7fffe9fd-2386-41d4-b2b3-dda09720accf.png"
              }
              className="image-menu-five"
            ></img>
          </div>
          <div className="info-menu-five">
            <div className="info-menu-five-one">
              <Flex align="center" justify="center">
                <TextRegularH6 text={accont?.name} />
                {accont?.is_staff ? (
                  <RedStaffIcon height={16} width={16} />
                ) : accont?.rank == 1 ? (
                  <SilverIcon height={16} width={16} />
                ) : accont?.rank == 2 ? (
                  <GoldIcon height={16} width={16} />
                ) : (
                  <></>
                )}
              </Flex>
            </div>
            <div className="info-menu-five-two">
              <ImageRedExp />
              <div style={{ marginRight: 5, marginLeft: 5 }}>
                <TextRegularH6 text={accont.red_xp?.toFixed(0)} />
              </div>
              <ImageRedCoins />
              <div style={{ marginRight: 5, marginLeft: 5 }}>
                <TextRegularH6 text={accont.red_coins?.toFixed(0)} />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="div-menu-five-mobile">
          <div className="image-menu-five">
            <img
              src={
                accont?.photo_profile
                  ? accont?.photo_profile
                  : "https://redcanidsstorage.blob.core.windows.net/redcanids/7fffe9fd-2386-41d4-b2b3-dda09720accf.png"
              }
              className="image-menu-five"
            ></img>
          </div>
        </div>
      )}
    </>
  );
}
