
import React from 'react'

export default function ImageRuby({ width, height, style }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="30" viewBox="0 0 21 30" fill="none"
            style={{
                width: width ? width : 30,
                height: height ? height : 30,
                ...style,
            }}>
            <path d="M0.647437 7.24358C0.647437 7.15007 0.684582 7.06039 0.750701 6.99428L7.09685 0.648161C7.16297 0.582043 7.25265 0.544898 7.34615 0.544898L12.9872 0.544898C13.0807 0.544899 13.1704 0.582043 13.2365 0.648161L19.5826 6.99428C19.6487 7.06039 19.6859 7.15007 19.6859 7.24358L19.6859 22.7563C19.6859 22.8498 19.6487 22.9395 19.5826 23.0056L13.2365 29.3517C13.1704 29.4178 13.0807 29.455 12.9872 29.455L7.34615 29.455C7.25264 29.455 7.16297 29.4178 7.09685 29.3517L0.7507 23.0056C0.684581 22.9395 0.647436 22.8498 0.647436 22.7563L0.647437 7.24358Z" fill="#FE304E" stroke="#CA1B35" stroke-width="0.705126" stroke-linejoin="round" />
            <path d="M0.999999 22.7564L1 7.24365L6.28846 9.00646L6.28846 20.9936L0.999999 22.7564Z" fill="#FE304E" />
            <path d="M7.34615 0.897461L1 7.24358L6.28846 9.0064L10.1667 4.42308L12.9872 0.897461L7.34615 0.897461Z" fill="#FF5E75" />
            <path d="M14.0464 20.9935L10.1682 25.5768L7.34766 29.1024L12.9887 29.1024L19.3348 22.7563L19.3348 7.24358L12.9887 0.897461L10.1682 4.42308L14.0464 9.00639L14.0464 20.9935Z" fill="#FE304E" />
            <path d="M19.3314 7.24365L19.3314 22.7564L14.043 20.9936L14.043 9.00646L19.3314 7.24365Z" fill="#CA1B35" />
            <ellipse cx="17.9239" cy="10.7686" rx="0.705128" ry="0.705124" fill="#CA1B35" />
            <ellipse cx="17.0406" cy="13.7652" rx="0.528846" ry="0.528843" fill="#CA1B35" />
            <ellipse cx="18.2764" cy="12.8843" rx="0.352564" ry="0.352562" fill="#CA1B35" />
            <ellipse cx="16.5127" cy="11.8262" rx="0.352564" ry="0.352562" fill="#CA1B35" />
            <ellipse cx="18.2764" cy="8.65334" rx="0.352564" ry="0.352562" fill="#CA1B35" />
            <ellipse cx="18.2764" cy="17.1153" rx="0.352564" ry="0.352562" fill="#CA1B35" />
            <ellipse cx="18.2764" cy="15.3516" rx="0.352564" ry="0.352562" fill="#CA1B35" />
            <path d="M6.28906 9.00616L6.28906 20.9933L10.1673 25.5766L14.0455 21.0098L14.0455 9.00616L10.1673 4.42285L6.28906 9.00616Z" fill="#FF5E75" />
            <path d="M6.28906 20.9933L6.28906 9.00616L10.1673 4.42285L14.0455 9.00616L6.28906 20.9933Z" fill="#FF9BAD" />
            <path d="M10.1653 5.48096L6.99219 9.35914V16.7629" stroke="#FFD5E4" stroke-width="0.705126" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M1.70703 8.30078V21.6981" stroke="#FF5E75" stroke-width="0.705126" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M2.41016 6.89097L7.69862 1.60254" stroke="#FFE7F2" stroke-width="0.705126" stroke-linecap="round" stroke-linejoin="round" />
            <ellipse cx="16.689" cy="23.6377" rx="0.528846" ry="0.528843" fill="#B3122A" />
            <ellipse cx="13.1636" cy="27.1636" rx="0.528846" ry="0.528843" fill="#B3122A" />
            <ellipse cx="14.5738" cy="25.7535" rx="0.528846" ry="0.528843" fill="#B3122A" />
            <ellipse cx="12.9873" cy="24.8716" rx="0.352564" ry="0.352562" fill="#B3122A" />
            <ellipse cx="15.4541" cy="24.8716" rx="0.352564" ry="0.352562" fill="#B3122A" />
            <ellipse cx="14.3985" cy="23.4615" rx="0.705128" ry="0.705124" fill="#B3122A" />
        </svg>
    )
}
