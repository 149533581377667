import React from "react";
import "./Footer.css";
import TextSemiboldH6 from "../../atomos/TextSemiboldH6";
import TextCaption from "../../atomos/TextCaption";
import checkIsMobile from "../../../utils/CheckIsMobile";
import {
  BrowserRouter as Router,
  Link,
  Route,
  Switch,
  Routes,
} from "react-router-dom";
import ProtectedRouteSubPages from "../../../utils/ProtectedRouterSubPages";
import ProtectedRoute from "../../../utils/ProtectedRouter";
import HowItWorks from "../../../pages/howItWorks/howItWorks";
import FAQ from "../../../pages/faq/FAQ";
import MissionPage from "../../../pages/missions/MissionPage";
import ProfilePage from "../../../pages/profilePage/ProfilePage";
import UseRedCoins from "../../../pages/useRedCoins/UseRedCoins";
import TextBoldH6 from "../../atomos/TextBoldH6";
import TextBoldH2 from "../../atomos/TextBoldH2";
import ModalTermsOfUse from "../ModalTermsOfUse/ModalTermsOfUse";
import ModalPrivacyPolicy from "../ModalPrivacyPolicy/ModalPrivacyPolicy";

export default function Footer() {
  return (
    <>
      {!checkIsMobile() ? (
        <div className="div-footer">
          <div className="div-footer-sponsor">
            <a href="https://www.kalunga.com.br/">
              <img
                src={"/images/kalunga.png"}
                alt={"sponsor"}
                className="image-sponsor"
              />
            </a>
            {/* <a href="https://www.santander.com.br/">
              <img
                src={"/images/santander.png"}
                alt={"sponsor"}
                className="image-sponsor"
              />
            </a> */}
            <a href="https://www.logitech.com/pt-br">
              <img
                src={"/images/logitech.png"}
                alt={"sponsor"}
                className="image-sponsor"
              />
            </a>
            <a href="https://www.kaspersky.com.br/">
              <img
                src={"/images/kaspersky.png"}
                alt={"sponsor"}
                className="image-sponsor"
              />
            </a>
            <a href="https://www.3m.com/">
              <img
                src={
                  "https://redcanidsstorage.blob.core.windows.net/redcanids/1eafd618-e7a6-4021-8fb0-34c462307757.png"
                }
                alt={"sponsor"}
                className="image-sponsor"
                style={{ width: 50 }}
              />
            </a>
            <a href="https://www.multilaser.com.br/">
              <img
                src={
                  "https://redcanidsstorage.blob.core.windows.net/redcanids/397eaca4-f370-45e9-a51b-5be14e26c958.png"
                }
                alt={"sponsor"}
                className="image-sponsor"
                style={{ width: 70 }}
              />
            </a>
            <a href="https://www.logofury.com/">
              <img
                src={
                  "https://redcanidsstorage.blob.core.windows.net/redcanids/ed7e126f-1ab1-4937-b756-7ae9d12b66f0.png"
                }
                alt={"logo fury"}
                className="image-sponsor"
                style={{ width: 70 }}
              />
            </a>
            <a href="https://www.matrixfitness.com/br/ptb">
              <img
                src={"/images/matrix.png"}
                alt={"sponsor"}
                className="image-sponsor"
              />
            </a>
            
          </div>
          <div className="div-footer-two">
            <div className="div-footer-two-one">
              <div className="div-image-logo">
                <img
                  src="https://redcanidsstorage.blob.core.windows.net/redcanids/Logo_RC_Big.png"
                  alt="logo"
                  className="home-logo"
                />
              </div>
              <div
                className="div-image-logo"
                style={{ flexDirection: "column" }}
              >
                <TextBoldH6
                  fontSize={10}
                  text={"Clube de Esports. com orgulho de ser 🇧🇷"}
                  textAlign={"left"}
                />
                <TextBoldH6
                  text={"Competindo no LoL, TFT, Valorant e CS:GO."}
                  textAlign={"left"}
                  fontSize={10}
                />
              </div>
            </div>
            <div className="div-footer-two-two">
              <TextSemiboldH6
                text={"Sobre a Plataforma RED Canids"}
                colorText={"#FFFFFF"}
                textAlign={"left"}
              />
              <div className="div-footer-two-two-father-grid">
                <div className="div-footer-two-two-grid">
                  <Link to="/how-it-works" className="link-style">
                    <TextBoldH6 text={"Como funciona"} fontSize={10} />
                  </Link>
                  <Link to="/faq" className="link-style">
                    <TextBoldH6 text={"Principais dúvidas"} fontSize={10} />
                  </Link>
                  <Link to="/missions" className="link-style">
                    <TextBoldH6 text={"Missões"} fontSize={10} />
                  </Link>
                  <Link to="/how-it-works" className="link-style">
                    <TextBoldH6 text={"Suporte Rufus"} fontSize={10} />
                  </Link>
                  <ModalTermsOfUse />
                </div>
                <div className="div-footer-two-two-grid">
                  <Link to="/profile-page" className="link-style">
                    <TextBoldH6 text={"Sua conta"} fontSize={10} />
                  </Link>
                  <Link to="/missions" className="link-style">
                    <TextBoldH6 text={"Farmar RED Coins e XP"} fontSize={10} />
                  </Link>
                  <Link to="/missions" className="link-style">
                    <TextBoldH6 text={"Ganhar RED Coins"} fontSize={10} />
                  </Link>
                  <Link to="/use-red-coins" className="link-style">
                    <TextBoldH6 text={"Resgatar"} fontSize={10} />
                  </Link>
                  <ModalPrivacyPolicy/>
                </div>
                <Routes>
                  <Route
                    path="/how-it-works"
                    element={
                      <ProtectedRouteSubPages>
                        <HowItWorks />
                      </ProtectedRouteSubPages>
                    }
                  />
                  <Route
                    path="/faq"
                    element={
                      <ProtectedRouteSubPages>
                        <FAQ />
                      </ProtectedRouteSubPages>
                    }
                  />
                  <Route
                    path="/missions"
                    element={
                      <ProtectedRouteSubPages>
                        <MissionPage />
                      </ProtectedRouteSubPages>
                    }
                  />
                  <Route
                    path="/profile-page"
                    element={
                      <ProtectedRouteSubPages>
                        <ProfilePage />
                      </ProtectedRouteSubPages>
                    }
                  />
                  <Route
                    path="/use-red-coins"
                    element={
                      <ProtectedRouteSubPages>
                        <UseRedCoins />
                      </ProtectedRouteSubPages>
                    }
                  />
                </Routes>
              </div>
            </div>
            {/* <div className="div-footer-two-three">
              <TextSemiboldH6
                text={"Sobre Nós"}
                colorText={"#FFFFFF"}
                textAlign={"left"}
              />
              <div className="div-footer-two-two-father-grid">
                <div className="div-footer-two-two-grid">
                  <Link to="/how-it-works" className="link-style">
                    <TextBoldH6 text={"Como funciona"} fontSize={10} />
                  </Link>
                  <Link to="/faq" className="link-style">
                    <TextBoldH6 text={"Principais dúvidas"} fontSize={10} />
                  </Link>
                  <Link to="/missions" className="link-style">
                    <TextBoldH6 text={"Missões"} fontSize={10} />
                  </Link>
                  <Link to="/how-it-works" className="link-style">
                    <TextBoldH6 text={"Suporte Rufus"} fontSize={10} />
                  </Link>
                </div>
              </div>
            </div> */}
            <div className="div-footer-two-four">
              {/* <TextSemiboldH6
                text={"Inscreva-se"}
                colorText={"#FFFFFF"}
                textAlign={"left"}
              />
              <TextSemiboldH6
                text={"INPUT EMAIL"}
                colorText={"#FFFFFF"}
                textAlign={"left"}
              /> */}
              <div className="div-footer-social">
                <a href="https://twitter.com/REDCanids">
                  <img
                    src="/images/x.png"
                    alt="logo"
                    className="footer-logo-social"
                  />
                </a>
                <a href="/">
                  <img
                    src="/images/telegram.png"
                    alt="logo"
                    className="footer-logo-social"
                  />
                </a>
                <a href="https://www.youtube.com/channel/UCx_D-SUSPW2S0GCRdI82ayg">
                  <img
                    src="/images/youtube.png"
                    alt="logo"
                    className="footer-logo-social"
                  />
                </a>
                <a href="https://www.tiktok.com/@redcanids">
                  <img
                    src="/images/tiktok.png"
                    alt="logo"
                    className="footer-logo-social"
                  />
                </a>
                <a href="/">
                  <img
                    src="/images/discord.png"
                    alt="logo"
                    className="footer-logo-social"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="div-footer-mobile">
          <div className="div-image-footer-mobile">
            <img
              src="https://redcanidsstorage.blob.core.windows.net/redcanids/Logo_RC_Big.png"
              alt="logo"
              className="home-logo"
            />
            <TextCaption
              text={"Clube de Esports. com orgulho de ser 🇧🇷"}
              textAlign={"left"}
            />
            <TextCaption
              text={"Competindo no LoL, TFT, Valorant e CS:GO."}
              textAlign={"left"}
            />
          </div>
          
          <div className="div-footer-sponsor">
            <a href="https://www.kalunga.com.br/">
              <img
                src={"/images/kalunga.png"}
                alt={"sponsor"}
                className="image-sponsor"
              />
            </a>
            {/* <a href="https://www.santander.com.br/">
              <img
                src={"/images/santander.png"}
                alt={"sponsor"}
                className="image-sponsor"
              />
            </a> */}
            <a href="https://www.logitech.com/pt-br">
              <img
                src={"/images/logitech.png"}
                alt={"sponsor"}
                className="image-sponsor"
              />
            </a>
            <a href="https://www.kaspersky.com.br/">
              <img
                src={"/images/kaspersky.png"}
                alt={"sponsor"}
                className="image-sponsor"
              />
            </a>
            
            <a href="https://www.3m.com/">
              <img
                src={
                  "https://redcanidsstorage.blob.core.windows.net/redcanids/1eafd618-e7a6-4021-8fb0-34c462307757.png"
                }
                alt={"sponsor"}
                className="image-sponsor"
                style={{ width: 50 }}
              />
            </a>
            <a href="https://www.multilaser.com.br/">
              <img
                src={
                  "https://redcanidsstorage.blob.core.windows.net/redcanids/397eaca4-f370-45e9-a51b-5be14e26c958.png"
                }
                alt={"sponsor"}
                className="image-sponsor"
                style={{ width: 70 }}
              />
            </a>
            <a href="https://www.logofury.com/">
              <img
                src={
                  "https://redcanidsstorage.blob.core.windows.net/redcanids/ed7e126f-1ab1-4937-b756-7ae9d12b66f0.png"
                }
                alt={"logo fury"}
                className="image-sponsor"
                style={{ width: 70 }}
              />
            </a>

            <a href="https://www.matrixfitness.com/br/ptb">
              <img
                src={"/images/matrix.png"}
                alt={"sponsor"}
                className="image-sponsor"
                style={{ width: 70 }}
              />
            </a>
            
          </div>
          <div className="div-footer-two-two-mobile">
            <TextSemiboldH6
              text={"Sobre a Plataforma RED Canids"}
              colorText={"#FFFFFF"}
              textAlign={"left"}
            />
            <Link to="/how-it-works" className="link-style">
              <TextBoldH6 text={"Como funciona"} fontSize={10} />
            </Link>
            <Link to="/faq" className="link-style">
              <TextBoldH6 text={"Principais dúvidas"} fontSize={10} />
            </Link>
            <Link to="/missions" className="link-style">
              <TextBoldH6 text={"Missões"} fontSize={10} />
            </Link>
            <Link to="/how-it-works" className="link-style">
              <TextBoldH6 text={"Suporte Rufus"} fontSize={10} />
            </Link>
            <Link to="/profile-page" className="link-style">
              <TextBoldH6 text={"Sua conta"} fontSize={10} />
            </Link>
            <Link to="/missions" className="link-style">
              <TextBoldH6 text={"Farmar RED Coins e XP"} fontSize={10} />
            </Link>
            <Link to="/missions" className="link-style">
              <TextBoldH6 text={"Ganhar RED Coins"} fontSize={10} />
            </Link>
            <Link to="/use-red-coins" className="link-style">
              <TextBoldH6 text={"Resgatar"} fontSize={10} />
            </Link>
            <ModalTermsOfUse/>
            <ModalPrivacyPolicy/>
          </div>
          {/* <div className="div-footer-two-two-mobile">
            <TextSemiboldH6
              text={"Sobre Nós"}
              colorText={"#FFFFFF"}
              textAlign={"left"}
            />
            <Link to="/how-it-works" className="link-style">
              <TextBoldH6 text={"Como funciona"} fontSize={10} />
            </Link>
            <Link to="/faq" className="link-style">
              <TextBoldH6 text={"Principais dúvidas"} fontSize={10} />
            </Link>
            <Link to="/missions" className="link-style">
              <TextBoldH6 text={"Missões"} fontSize={10} />
            </Link>
            <Link to="/how-it-works" className="link-style">
              <TextBoldH6 text={"Suporte Rufus"} fontSize={10} />
            </Link>
          </div> */}
          <div className="div-footer-two-four">
            {/*   <TextSemiboldH6
              text={"Inscreva-se"}
              colorText={"#FFFFFF"}
              textAlign={"left"}
            />
            <TextSemiboldH6
              text={"INPUT EMAIL"}
              colorText={"#FFFFFF"}
              textAlign={"left"}
            /> */}
            <div className="div-footer-social-mobile">
              <a href="https://twitter.com/REDCanids">
                <img
                  src="/images/x.png"
                  alt="logo"
                  className="footer-logo-social"
                />
              </a>
              <a href="/">
                <img
                  src="/images/telegram.png"
                  alt="logo"
                  className="footer-logo-social"
                />
              </a>
              <a href="https://www.youtube.com/channel/UCx_D-SUSPW2S0GCRdI82ayg">
                <img
                  src="/images/youtube.png"
                  alt="logo"
                  className="footer-logo-social"
                />
              </a>
              <a>
                <img
                  src="/images/tiktok.png"
                  alt="logo"
                  className="footer-logo-social"
                />
              </a>
              <a>
                <img
                  src="/images/discord.png"
                  alt="logo"
                  className="footer-logo-social"
                />
              </a>
            </div>
          </div>
        </div>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          marginTop: "10px"
        }}
        onClick={() => {
          window.location.href = "https://www.mios.pt/";
        }}
      >
        <TextBoldH6 text={"Desenvolvido por MIOS"} fontSize={10} />
      </div>
    </>
  );
}
