import React, { useState } from "react";
import { Modal, Button, Flex, Image, message } from "antd";
import TextBoldH3 from "../../atomos/TextBoldH3";
import TextBoldH6 from "../../atomos/TextBoldH6";
import ButtonWallet from "../../atomos/ButtonWallet";
import { useSelector } from "react-redux";

export default function ModalToInviteFriends() {
  const [modalVisible, setModalVisible] = useState(false);
  const account = useSelector((state) => state.account);
  const handleOpenModal = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const createLinkInvite = () => {
    navigator.clipboard
      .writeText("https://" + window.location.hostname + "/login/" + account._id.$oid)
      .then(() => {
        message.success("Link Copiado");
      })
      .catch((err) => console.error(err));
  };

  return (
    <>
      <div style={{ marginTop: 30 }}>
        {/* <ButtonWallet
          buttonAction={handleOpenModal}
          text={"Convidar amigos"}
          width={300}
          height={54}
        /> */}
      </div>
      <Modal
        open={modalVisible}
        footer={null}
        onCancel={handleCloseModal}
        width={700}
        centered
      >
        <Flex
          style={{
            minHeight: "300px",
            minWidth: "100%",
            backgroundImage: "url(/images/Welcome.png)",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          &nbsp;
        </Flex>
        <Flex vertical justify="center" align="center" gap={30}>
          <Flex
            vertical
            justify="center"
            align="center"
            style={{ paddingTop: 20, padding: 10 }}
          >
            <TextBoldH3
              text={"Convide seu amigo para fazer parte da MATILHA"}
              colorText={"white"}
              textAlign={"center"}
            />
          </Flex>
          <Flex vertical>
            <TextBoldH6
              text={
                "Envie um convite via email para seus amigos e ganhe RED XP para novos inscritos na plataforma"
              }
              colorText={"white"}
              textAlign={"center"}
            />
          </Flex>
          <Flex
            vertical
            gap={10}
            align="center"
            justify="center"
            style={{ width: "100%" }}
          >
            <ButtonWallet
              buttonAction={createLinkInvite}
              text={"Copiar link personalizado"}
              width={"80%"}
              height={54}
            />
            <Button
              type="primary"
              htmlType="submit"
              style={{
                width: "80%",
                backgroundColor: "#121617",
                borderRadius: "50em",
                height: 30,
              }}
              onClick={handleCloseModal}
            >
              Talvez depois
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
}
