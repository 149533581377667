import React, { useEffect, useState } from "react";
import "./Menu.css";
import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import { Button, ConfigProvider, Menu, Space } from 'antd';
import checkIsMobile from "../../../utils/CheckIsMobile";
import TextBoldH3 from "../../atomos/TextBoldH3";
import TextRegularH6 from "../../atomos/TextRegularH6";
import ImageRedExp from "../../atomos/ImageRedExp";
import ImageRedCoins from "../../atomos/ImageRedCoins";
import MenuInfoUser from "../MenuInfoUser/MenuInfoUser";
import { useNavigate } from "react-router-dom";
import { MissionService, OpenAPI } from "red-canids-client-module/client-web";
import { encryptAES } from "../../../utils/Crypt";
import TextBoldH6 from "../../atomos/TextBoldH6";

export default function MenuDefaultClient({ page }) {
    const colors = require("../../../styles/Colors.json");
    const navigate = useNavigate();

    useEffect(() => {
        if (page?.pathname != undefined) {
            if (page?.pathname == "/missions/detail")
                setCurrent("missions");
            else if (page?.pathname == "/missions/detail/watch-video")
                setCurrent("missions");
            else if (String(page?.pathname).includes("/missions/detail")) {
                setCurrent("missions");
            }
            else
                setCurrent(page?.pathname?.replace("/", "") || "home");
        }
    }, [page]);

    const [current, setCurrent] = useState('');
    const onClick = (e) => {

        setCurrent(e.key);
        navigate(`/${e.key}`);
    };

    const items = [
        {
            label: 'Inicio',
            key: 'home',
        },
        {
            label: 'Como funciona',
            key: 'how-it-works',
            disabled: false,
        },
        {
            label: 'Principais dúvidas',
            key: 'faq',
        },
        {
            label: 'Missões',
            key: 'missions',
        },
        {
            label: 'Usar RED Coins',
            key: 'use-red-coins',
        },
    ];

    function iconFarmarRedXP() {
        return (
            <img src="/images/ic_linear_play-circle.png" className="image-farmar-xp"></img>
        )
    }

    function iconNotifications() {
        return (
            <img src="/images/ic_linear_notification-bing.png" className="image-farmar-xp" style={{ height: 20, width: 20 }}></img>
        )
    }

    function maxSizeMenu() {
        if (window.innerWidth < 500 && window.innerWidth > 400) {
            return 270;
        } else if (window.innerWidth < 900 && window.innerWidth > 500) {
            return 600;
        }else if (window.innerWidth < 1200 && window.innerWidth > 900) {
            return 900;
        }else {
            return 1200;
        }
    }

    return (
        <div className="menu-default-base" style={{ justifyContent: "center", display: "flex" }}>
            <div className="menu-one" onClick={() => navigate("/")}>
                <img src="https://redcanidsstorage.blob.core.windows.net/redcanids/Logo_RC_Big.png" alt="logo" className="home-logo" />
            </div>
            <div className="menu-one">
                <TextBoldH6 text="Beta" color={colors.BaseSlot4} />
            </div>
            <div className="menu-two">
                <ConfigProvider
                    menu={{
                        style: {
                            alignSelf: "center",
                            alignContent: "center",
                            maxWidth: maxSizeMenu(),
                            color: colors.Grayscale,
                            backgroundColor: "transparent",
                        },
                    }}
                    theme={{
                        components: {
                            Menu: {
                                colorPrimary: colors.BaseSlot4,
                                colorText: "white",
                                horizontalLineHeight: 6,
                                
                            }
                        }
                    }}
                >
                    <Menu
                        direction="rtl"
                        property="primary"
                        onClick={onClick}
                        selectedKeys={[current]}
                        mode="horizontal"
                        items={items}>

                    </Menu>
                </ConfigProvider>
            </div>
            {
                !checkIsMobile() && (
                    <>
                        <div className="menu-three">
                            <Button onClick={() => {
                                MissionService.returnAllMission().then((response) => {
                                    response.data.forEach(element => {
                                        if (element.name_mission.includes("FARMAR")) {
                                            navigate("/missions/detail/watch-video/" + element._id.$oid)
                                        }
                                    });
                                })
                                navigate("/missions")
                            }} type="primary" htmlType="submit" className="button-border" style={{ alignItems: "center", display: "flex" }} icon={iconFarmarRedXP()}>Farmar RED Coins e XP</Button>
                            <div style={{ width: 10 }}></div>
                            {/* <Button type="primary" htmlType="submit" className="button-border" style={{ width: 40 }} icon={iconNotifications()}></Button> */}
                        </div>
                        <div className="menu-four">
                            <div style={{ width: 2, height: "80%", backgroundColor: "gray" }} />
                        </div>
                    </>
                )
            }
            {
                !checkIsMobile() ? (
                    <div className="menu-five">
                        <MenuInfoUser />
                    </div>
                ) : (
                    <div className="menu-five-mobile">
                        <MenuInfoUser />
                    </div>
                )
            }
        </div>
    )
}