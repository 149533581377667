import React, { useEffect, useState } from "react";
import TextBoldH2 from "../../atomos/TextBoldH2";
import {
  Button,
  ConfigProvider,
  Flex,
  Menu,
  Segmented,
  Typography,
} from "antd";
import {
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  AccountService,
  MissionService,
} from "red-canids-client-module/client-web";
import AccountsAnswers from "../../organisms/AccountsAnswers/AccountsAnswers";
import TextBoldH3 from "../../atomos/TextBoldH3";
import TextBoldH5 from "../../atomos/TextBoldH5";

// Import statements...

export default function QuestionsTemplate() {
  const [missions, setMissions] = useState([]);
  const [currentMission, setCurrentMission] = useState("");
  const [currentAccount, setCurrentAccount] = useState("");
  const [currentAccountKey, setCurrentAccountKey] = useState("");
  const [accountItems, setAccountItems] = useState([]);

  useEffect(() => {
    MissionService.returnAllMission()
      .then((res) => {
        const filteredMissions = res.data.filter(
          (item) => item.type_mission === "question"
        );
        setMissions(filteredMissions);
      })
      .catch((err) => console.error(err));
    return () => {
      setMissions([]);
      setCurrentMission("");
    };
  }, []);

  const onClickMission = (e) => {
    setCurrentMission(e.key);
    setCurrentAccount("");
    setCurrentAccountKey("");
    setAccountItems([]);
    MissionService.returnAccountsByIdMissions(e.key)
      .then((res) => {
        const items = res.data.map((item) => ({
          label: item.name,
          key: item._id.$oid,
        }));
        setAccountItems(items);
      })
      .catch((err) => console.error(err));
  };

  const onClickAccount = (e) => {
    setCurrentAccountKey(e.key);
    AccountService.returnAllAccountManager()
      .then((res) => {
        const filteredAccount = res.data.find(
          (item) => item._id.$oid === e.key
        );
        if (filteredAccount) {
          const updatedAccount = {
            name: filteredAccount.name,
            missions_completed: filteredAccount.missions_completed?.filter(
              (mission) => mission.id_mission === currentMission
            ),
          };
          setCurrentAccount(updatedAccount);
          console.log(updatedAccount);
        } else {
          console.error("Account not found");
        }
      })
      .catch((err) => console.error(err));
  };

  return (
    <ConfigProvider
      theme={
        {
          // Theme configuration...
        }
      }
    >
      <Flex style={{}}>
        <Flex
          style={{
            flexDirection: "column",
            width: "100%",
            backgroundColor: "white",
          }}
          flex={1}
        >
          <Typography.Text style={{ fontSize: 16, fontWeight: 500 }}>
            Missões
          </Typography.Text>
          <Menu
            onClick={onClickMission}
            selectedKeys={[currentMission]}
            mode="inline"
            items={missions.map((item) => ({
              label: item.name_mission,
              key: item._id.$oid,
            }))}
          />
        </Flex>
        <Flex
          style={{
            flexDirection: "column",
            width: "100%",
            backgroundColor: "white",
          }}
          flex={1}
        >
          {accountItems.length !== 0 ? (
            <Typography.Text style={{ fontSize: 16, fontWeight: 500 }}>
              Contas
            </Typography.Text>
          ) : null}
          <Menu
            onClick={onClickAccount}
            selectedKeys={[currentAccountKey]}
            mode="inline"
            items={accountItems}
          />
        </Flex>
        <Flex
          style={{
            flexDirection: "column",
            width: "100%",
            backgroundColor: "white",
            padding: 10,
          }}
          flex={4}
        >
          <AccountsAnswers account={currentAccount} />
        </Flex>
      </Flex>
    </ConfigProvider>
  );
}
