import React from "react";
import BaseLayout from "../../components/templates/BaseLayout/BaseLayout";
import ManagerInsertCoinsTemplate from "../../components/templates/ManagerInsertCoins/ManagerInsertCoins";

export default function ManagerUsersStatistics() {
  return (
    <>
      <BaseLayout currentKey="ManagerInsertCoins" content={<ManagerInsertCoinsTemplate />} />
    </>
  );
}
