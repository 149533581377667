import React, { useEffect, useState } from "react";
import "./MissionDetail.css";
import MenuDefaultClient from "../../components/organisms/Menu/Menu";
import MissionToWinRedCoins from "../../components/organisms/MissionToWinRedCoins/MissionToWinRedCoins";
import Footer from "../../components/organisms/Footer.js/Footer";
import DetailMission from "../../components/organisms/DetailMission/DetailMission";
import { useLocation, useParams } from "react-router-dom";
import HeaderAfterMenu from "../../components/organisms/HeaderAfterMenu/HeaderAfterMenu";
import { MissionService } from "red-canids-client-module/client-web";
import { useDispatch, useSelector } from "react-redux";
import { selectMission } from "../../features/missionSelected/missionSelected";

export default function MissionDetail() {
    const location = useLocation();
    const dispatch = useDispatch();
    const { id_mission } = useParams();
    const missionSelec = useSelector((state) => state.missionSelected);
    const [mission, setMision] = useState(missionSelec);

    const itemsBreadCrumb = [
        {
            title: "Home",
            path: "/home"
        },
        {
            title: "Missão",
            path: "/missions"
        },
        {
            title: "Detalhes da missão",
        }];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <>
            <MenuDefaultClient page={location} />
            <HeaderAfterMenu title={"Detalhes da missão"} items={itemsBreadCrumb} />
            <DetailMission />
            <MissionToWinRedCoins limitListShow={4} textTitle={"Outras missões pra você"} />
            <Footer />
        </>
    )
}