import React from "react";
import "./ModalHomeWelcome.css";
import TextBoldH3 from "../../atomos/TextBoldH3";
import TextSemiboldH6 from "../../atomos/TextSemiboldH6";
import { Button } from "antd";

export default function ModalHomeWelcome({ cancel, confirm }) {
    return (
        <div className="div-modal-home-welcome">
            <img src="https://redcanidsstorage.blob.core.windows.net/redcanids/f6192c06-8796-4408-b10e-a2c482d1c5eb.png" className="image-modal-home-welcome" />
            <TextBoldH3 text={"Seja bem-vindo à matilha!"} colorText={"white"} textAlign={"center"} />
            <TextSemiboldH6 text={"Aqui você apoia sua reduda, interage com os amigos e ainda garante benefícios digitais e físicos na nossa loja!"} colorText={"white"} />
            <Button type="primary" htmlType="submit" className="button-border" style={{width: "100%"}} onClick={() => confirm()}>Tá chegando agora? O rufus te ensina o caminho!</Button>
            <Button type="primary" htmlType="submit" className="button-border" style={{width: "100%"}} onClick={() => cancel()}>Já conheço a plataforma</Button>
        </div>
    )
}