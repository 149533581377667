import React, { useEffect, useState } from 'react';
import { Modal, Button, Flex, Image, Alert } from 'antd';
import TextBoldH3 from '../../atomos/TextBoldH3';
import TextSemiboldH6 from '../../atomos/TextSemiboldH6';
import TextBoldH6 from '../../atomos/TextBoldH6';
import ButtonWallet from '../../atomos/ButtonWallet';
import { useDispatch, useSelector } from 'react-redux';
import { PurchasesService } from 'red-canids-client-module/client-web';
import { subtractRedCoins } from '../../../features/account/account';
import { useNavigate } from 'react-router-dom';
const colors = require('../../../styles/Colors.json');
export default function ModalToRescueProduct({ product }) {
    const [modalVisible, setModalVisible] = useState(false);
    const [modalVirtualVisible, setModalVirtualVisible] = useState(false);
    const [confirmModalVisible, setConfirmModalVisible] = useState(false);
    const [buyProduct, setBuyProduct] = useState(false);
    const [clicked, setClicked] = useState(false);
    const account = useSelector(state => state.account);
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const handleOpenModal = () => {
        if (product?.type_product === "physical")
            setModalVisible(true);
        else
            setModalVirtualVisible(true);
    };

    const handleCloseModal = () => {
        setModalVisible(false);
        setClicked(false)
    };

    const handleConfirmRedCoins = () => {
        console.log({
            current_account_red_coins: account.red_coins - product.price,
            description: product.description_product,
            id_product: product._id.$oid,
            id_account: account._id.$oid,
            last_account_red_coins: account.red_coins,
            name: product.name_product,
            total_price: product.price,
        })
        PurchasesService.createPurchase({
            current_account_red_coins: account.red_coins - product.price,
            description: product.description_product,
            id_product: product._id.$oid,
            id_account: account._id.$oid,
            last_account_red_coins: account.red_coins,
            name: product.name_product,
            total_price: product.price,
        }).then((response) => {
            setClicked(true);
            setConfirmModalVisible(true);
            dispatch(subtractRedCoins(product.price));
        }).catch((error) => {
            console.error('Error', error)
        })
    };

    const handleToPerfil = () => {
        navigate("/profile-page");
        window.scrollTo(0, 0);
    }

    const handleCloseConfirmModal = () => {
        setConfirmModalVisible(false);
        setModalVisible(false)
        setClicked(false)
    };

    useEffect(() => {
        if (account.red_coins >= product.price && product.stock > 0) {
            setBuyProduct(true);

        }
        else {
            setBuyProduct(false);
        }
    }, [account, buyProduct]);


    return (
        <>
            {buyProduct ? (
                <ButtonWallet buttonAction={handleOpenModal} text={"Escolher Produto"} width={"100%"} />
            ) : (
                <Button disabled style={{ background: colors.GrayscaleSlot, borderRadius: 24, width: "100%", fontSize: 12, color: "white", fontWeight: "bold" }}>
                    Não disponível para resgate
                </Button>
            )}

            <Modal
                visible={modalVisible}
                footer={null}
                onCancel={handleCloseModal}
                width={700}
                centered
            >
                <Flex style={{ minHeight: "300px", minWidth: "100%", backgroundImage: "url(/images/ModalWelcome.png)", backgroundSize: "cover", backgroundPosition: "center" }}>
                    &nbsp;
                </Flex>
                <Flex vertical justify='center' align='center' gap={30}>
                    <Flex vertical justify='center' align='center'>
                        <TextBoldH3 text={"Enviar pedido de resgate"} colorText={"white"} textAlign={"center"} />
                        <TextSemiboldH6 text={"Você selecionou o seguinte produto para resgate:"} colorText={"white"} textAlign={"center"} />
                        {/* Product.name */}
                        <TextSemiboldH6 text={product.name_product} colorText={"red"} textAlign={"center"} />
                    </Flex>
                    <Flex vertical>
                        <TextBoldH6 text={`O Rufus já esta separando seu prêmio que custará ${product.price} red coins, enquanto isso, vamos enviar para seu email cadastrado na plataforma (${account.email}) as instruções para resgate!`} colorText={"white"} textAlign={"center"} />
                    </Flex>
                    <Flex justify='center' align='center'>
                        <TextSemiboldH6 text={"Você confirmar o uso de suas RED Coins?"} colorText={"white"} textAlign={"center"} />
                    </Flex>
                    <Flex justify='center' align='center'>
                        <Alert message="Ao gastar seus red coins, eles serão convertidos no produto selecionado e NÃO poderão ser reembolsados." type="warning" showIcon />
                    </Flex>
                    <Flex vertical gap={10} align='center' justify='center' style={{ width: "100%" }}>
                        {
                            clicked === false ?
                                (
                                    <ButtonWallet
                                        buttonAction={handleConfirmRedCoins} text={"Usar minhas RED Coins e resgatar"} width={"80%"}
                                    />
                                ) : (
                                    <Button disabled style={{ background: colors.Grayscale, borderRadius: 24, width: "80%" }}>
                                        Não disponível para resgate
                                    </Button>
                                )
                        }

                        <Button type="primary" htmlType="submit" style={{ width: "80%", backgroundColor: "#121617", borderRadius: "50em" }} onClick={handleCloseModal}>Talvez depois</Button>
                    </Flex>
                </Flex>
            </Modal>
            <Modal
                visible={modalVirtualVisible}
                footer={null}
                onCancel={() => setModalVirtualVisible(false)}
                width={700}
                centered
            >
                <Flex style={{ minHeight: "300px", minWidth: "100%", backgroundImage: `url(${product.image_product})`, backgroundSize: "cover", backgroundPosition: "center" }}>
                    &nbsp;
                </Flex>
                <Flex vertical justify='center' align='center' gap={30}>
                    <Flex vertical justify='center' align='center'>
                        <TextBoldH3 text={"Parabéns"} colorText={"white"} textAlign={"center"} />
                        <TextSemiboldH6 text={"Você selecionou o seguinte produto para resgate:"} colorText={"white"} textAlign={"center"} />
                        {/* Product.name */}
                        <TextSemiboldH6 text={product.name_product} colorText={"red"} textAlign={"center"} />
                    </Flex>
                    <Flex vertical>
                        <TextBoldH6 text={`Seu presente já está disponível para ser usado; veja no seu perfil.`} colorText={"white"} textAlign={"center"} />
                    </Flex>
                    <Flex vertical gap={10} align='center' justify='center' style={{ width: "100%" }}>
                        {
                            clicked === false ?
                                (
                                    <ButtonWallet
                                        buttonAction={handleToPerfil} text={"Ir para o seu perfil"} width={"80%"}
                                    />
                                ) : (
                                    <Button disabled style={{ background: colors.Grayscale, borderRadius: 24, width: "80%" }}>
                                        Não disponível para resgate
                                    </Button>
                                )
                        }

                        {/* <Button type="primary" htmlType="submit" style={{ width: "80%", backgroundColor: "#121617", borderRadius: "50em" }} onClick={handleCloseModal}>Talvez depois</Button> */}
                    </Flex>
                </Flex>
            </Modal>
            <Modal
                visible={confirmModalVisible}
                footer={null}
                onCancel={handleCloseConfirmModal}
                centered
            >
                <Flex vertical justify='center' align='center' gap={30}>
                    <Image width={200} preview={false} src={"/images/Mailbox.png"} />
                    <TextBoldH3 text={"Resgate confirmado"} colorText={"white"} textAlign={"center"} />
                    <TextSemiboldH6 text={"Confira a sua caixa de email e siga todas as instruções para adquirir seu produto em nossa loja oficial, ok?"} colorText={"white"} textAlign={"center"} />
                    <ButtonWallet buttonAction={handleCloseConfirmModal} text={"Ok, entendi"} width={"80%"} />
                </Flex>
            </Modal>
        </>
    );
}