import React from "react";

export default function ImageArtBoard12({height,width}) {
    return (
        <img
            src="/images/Artboard12.png"
            style={{
                height: height,
                width: width,
                objectFit: "cover",
            }}></img>
    )
}