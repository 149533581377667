// features/coverImage/coverImageSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedCoverImage: "/images/Background.png",
};

const coverImageSlice = createSlice({
    name: 'coverImage',
    initialState,
    reducers: {
        selectCoverImage: (state, action) => {
            state.selectedCoverImage = action.payload;
        },
    },
});

export const { selectCoverImage } = coverImageSlice.actions;
export default coverImageSlice.reducer;
