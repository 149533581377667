// store.js
import { configureStore } from '@reduxjs/toolkit';
import drawerFeatures from '../features/drawer/drawer';
import userFeatures from '../features/user/user';
import missionFeatures from '../features/mission/mission';
import accountFeatures from '../features/account/account';
import coverImageReducer from '../features/coverImage/coverImageSlice';
import missionSelected from '../features/missionSelected/missionSelected';
import accountAdminFeatures from '../features/accountAdmin/accountAdmin';
import profileFeatures from '../features/profile/profile';

const rootReducer = {
    drawer: drawerFeatures,
    user: userFeatures,
    mission: missionFeatures,
    missionSelected: missionSelected,
    account: accountFeatures,
    coverImage: coverImageReducer,
    accountAdmin: accountAdminFeatures,
    profile: profileFeatures,
};

const store = configureStore({
    reducer: rootReducer,
});

export default store;
