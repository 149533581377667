import React from "react";
import "./MissionPage.css";
import HeaderAfterMenu from "../../components/organisms/HeaderAfterMenu/HeaderAfterMenu";
import MenuDefaultClient from "../../components/organisms/Menu/Menu";
import MissionToWinRedCoins from "../../components/organisms/MissionToWinRedCoins/MissionToWinRedCoins";
import Footer from "../../components/organisms/Footer.js/Footer";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

export default function MissionPage() {
    const location = useLocation();

    const user = useSelector((state) => state.user);

    const itemsBreadCrumb = [
        {
            title: "Home",
            path: "/home"
        },
        {
            title: "Missões",
        }
    ];
    return (
        <>
            <MenuDefaultClient page={location} />
            <HeaderAfterMenu title={"Missões"} items={itemsBreadCrumb} />
            <MissionToWinRedCoins showOrder={true}  />
            <Footer />
        </>
    )
}