import React from "react";
import "./HeaderAfterMenu.css";
import TextBoldH2 from "../../atomos/TextBoldH2";
import { Breadcrumb } from "antd";
import { Link, useLocation } from "react-router-dom";
import checkIsMobile from "../../../utils/CheckIsMobile";
import TextBoldH3 from "../../atomos/TextBoldH3";
import TextBoldH5 from "../../atomos/TextBoldH5";

export default function HeaderAfterMenu({ title, items }) {
    const location = useLocation();

    const breadcrumbItems = items.map((item) => {
        const url = item.path;
        return (
            <Breadcrumb.Item key={url}>
                {
                    url ? <Link to={url}>{item.title}</Link> : <span>{item.title}</span>
                }
            </Breadcrumb.Item>
        );
    });

    return (
        <div className="div-header-after-menu">
            {
                !checkIsMobile() ? (
                    <TextBoldH2 text={title} />
                ) : (
                    <TextBoldH3 text={title} />
                )
            }
            <Breadcrumb >
                {breadcrumbItems}
            </Breadcrumb>
        </div>
    )
}