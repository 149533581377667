import React from "react";


export default function TextRegularLabel({ text, colorText, fontSize, textAlign }) {
    return (
        <>
            <span
                style={{
                    fontFamily: "Montserrat",
                    fontWeight: 400,
                    fontSize: fontSize == undefined ? 10 : fontSize,
                    lineHeight: "22px",
                    textAlign: textAlign ? textAlign : "center",
                    color: colorText,
                }}>{text}
            </span>
        </>
    )
}