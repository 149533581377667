import { createSlice } from '@reduxjs/toolkit'

export const missionFeatures = createSlice({
    name: 'mission',
    initialState: [],
    reducers: {
        addMissions: (state, action) => {
            state = action.payload
            return state
        },
    },
})

export const { addMissions } = missionFeatures.actions

export default missionFeatures.reducer