import React from "react";

export default function ImageTimerRed() {
    return (
        <img
            src="/images/Vector (1).png"
            style={{
                height: 20,
                objectFit: "cover",
            }}></img>
    )
}