import React from "react";
import { useDispatch } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { AccountService } from "red-canids-client-module/client-web";
import { addUser } from "../features/account/account";

export default function ProtectedRouteSubPages({ children }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loginSession = localStorage.getItem("@_auth_sessions");
    if (loginSession) {
        AccountService.returnAccountByEmailAndCodeAccess(
            JSON.parse(atob(loginSession)).email,
            JSON.parse(atob(loginSession)).code_access
        )
            .then((response) => {
                dispatch(addUser(response.data));
            })
            .catch((error) => {
                console.log("ERROR GET ACCOUNT API", error);
                console.log("ERROR GET ACCOUNT API", JSON.parse(atob(loginSession)).email,
                JSON.parse(atob(loginSession)).code_access);
                navigate("/login");
            });
        return children;
    } else {
        return <Navigate to="/home" />;
    }
}
