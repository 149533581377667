import React, { useEffect, useRef, useState } from "react";
import TextSemiboldH4 from "../../atomos/TextSemiboldH4";
import "./ChallengeList.css";
import { Progress, Slider, Tour } from "antd";
import ImageUser from "../../atomos/ImageUser";
import TextBoldH5 from "../../atomos/TextBoldH5";
import TextBoldH6 from "../../atomos/TextBoldH6";
import TextRegularLabel from "../../atomos/TextRegularLabel";
import checkIsMobile from "../../../utils/CheckIsMobile";
import TextSemiboldH5 from "../../atomos/TextSemiboldH5";
import { ProductService } from "red-canids-client-module/client-web";
import { useSelector } from "react-redux";

export default function ChallengeList({ refTour }) {

    const [listChallenge, setListChallenge] = useState([]);

    const account = useSelector(state => state.account);

    useEffect(() => {
        ProductService.returnAllVirtualProducts().then((res) => {
            setListChallenge(res.data);
        }).catch((error) => {
            console.error('Error fetching products:', error);
        });
    }, []);

    const colors = require("../../../styles/Colors.json")

    return (
        <>
            {
                !checkIsMobile() ? (
                    <div ref={refTour} id="div-challenge-list" className="div-challenge-list">
                        <div className="div-challenge-text">
                            <TextSemiboldH4 text="Complete desafios e ganhe prêmios digitais" colorText="#FFFFFF" fontSize={28} />
                        </div>
                        <div id="div-challenge-list-two" className="div-challenge-list-two">
                            {
                                listChallenge.map((item, idx) => {
                                    return (
                                        <div className="div-element-list">
                                            <div className="div-element-list-image">
                                                {
                                                    account.red_xp < item.price ?
                                                        (
                                                            <ImageUser src={item.image_product} height={90} width={200} borderRadius={10} darkImage={true} />
                                                        )
                                                        :
                                                        (
                                                            <ImageUser src={item.image_product} height={90} width={200} borderRadius={10} />
                                                        )
                                                }
                                            </div>
                                            <div className="div-element-list-info">
                                                <TextBoldH6 text={item.name_product} colorText={"white"} />
                                                <TextRegularLabel text={`${item.price} de xp `} colorText={"white"} />
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            {/* <Slider marks={marks} step={null} defaultValue={37} value={21} style={{ width: "95%", alignSelf: "center" }} /> */}
                        </div>
                    </div>
                ) : (
                    <>
                        <div ref={refTour} id="div-challenge-list-mobile" className="div-challenge-list-two">
                            <div className="div-challenge-text">
                                <TextSemiboldH5 text="Complete desafios e ganhe prêmios digitais" colorText="#FFFFFF" fontSize={28} />
                            </div>
                            <div id="div-challenge-list-two-mobile" className="div-challenge-list-two-mobile">
                                {
                                    listChallenge.map((item, idx) => {
                                        return (
                                            <div className="div-element-list">
                                                <div className="div-element-list-image">
                                                    {
                                                        account.red_xp < item.price ?
                                                            (
                                                                <ImageUser src={item.image_product} height={90} width={200} borderRadius={10} darkImage={true} />
                                                            )
                                                            :
                                                            (
                                                                <ImageUser src={item.image_product} height={90} width={200} borderRadius={10} />
                                                            )
                                                    }
                                                </div>
                                                <div className="div-element-list-info">
                                                    <TextBoldH6 text={item.name_product} colorText={"white"} />
                                                    <TextRegularLabel text={`${item.price} de xp `} colorText={"white"} />
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                {/* <Slider marks={marks} step={null} defaultValue={37} value={21} style={{ width: "95%", alignSelf: "center" }} /> */}
                            </div>
                            {/* <Progress percent={70} status="active" strokeColor={colors.BaseSlot2} trailColor={colors.GrayscaleSlot6} showInfo={false} /> */}
                        </div>
                    </>
                )
            }
        </>
    )
}