import React from "react";
import "./ModalHomeWelcome.css";
import TextBoldH3 from "../../atomos/TextBoldH3";
import TextSemiboldH6 from "../../atomos/TextSemiboldH6";
import { Button } from "antd";

export default function ModalHomeWelcomeTwo({ confirm }) {
    return (
        <div className="div-modal-home-welcome">
            <img src="https://redcanidsstorage.blob.core.windows.net/redcanids/f6192c06-8796-4408-b10e-a2c482d1c5eb.png" className="image-modal-home-welcome" />
            <TextBoldH3 text={"Hora de receber os primeiros prêmios!"} colorText={"white"} textAlign={"center"} />
            <TextSemiboldH6 text={"Pra comemorar sua entrada, toma de graça 10 red coins e um presentinho pra deixar o perfil bonito!"} colorText={"white"} />
            <Button type="primary" htmlType="submit" className="button-border" style={{ width: "100%" }} onClick={() => confirm()}>1º Passo - Resgatar minhas RED Coins</Button>
        </div>
    )
}