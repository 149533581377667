import { Card, Dropdown, Modal, Space } from "antd";
import Meta from "antd/es/card/Meta";
import React, { useState } from "react";
import {
  EditOutlined,
  EllipsisOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import ConfigProvider, { ConfigContext } from "antd/es/config-provider";
import { Link, useNavigate } from "react-router-dom";
import { MissionService } from "red-canids-client-module/client-web";

export default function CardManagerMissionsOrganisms({
  allValues,
  key,
  name_mission,
  description_mission,
  image_mission,
  expiration_date,
  classification,
  points,
  type_points,
  type_mission,
  time_mission,
  url_mission,
  videos_mission,
  video_source,
  code_mission,
  created_at,
  updated_at,
  callBackClickEditProduct,
  setProductSelected,
  showVisible,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenEnabled, setIsModalOpenEnabled] = useState(false);
  const colors = require("../../../styles/Colors.json");
  const navigate = useNavigate();

  const showModal = () => {
    setIsModalOpen(true);
  };
  const showModalEnabled = () => {
    setIsModalOpenEnabled(true);
  };

  const handleOk = () => {
    MissionService.deleteMissionById(allValues._id.$oid)
      .then(() => {
        setIsModalOpen(false);
        window.location.reload();
      })
      .catch((err) => console.log("ERRO", err));
  };
  const handleOkEnabled = () => {
    MissionService.toggleMissionEnabledById(allValues._id.$oid)
      .then(() => {
        setIsModalOpenEnabled(false);
        window.location.reload();
      })
      .catch((err) => console.log("ERRO", err));
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleEnabled = () => {
    setIsModalOpenEnabled(false);
  };
  const items = [
    {
      label: "Excluir",
      key: "delete",
    },
    {
      label: allValues.is_enabled ? "Arquivar" : "Ativar",
      key: "archive",
    },
  ];
  const onClick = ({ key }) => {
    if (key === "delete") {
      showModal();
    }
    if (key === "archive") {
      showModalEnabled();
    }
  };
  const handleEditButton = (product) => {
    callBackClickEditProduct(product);
  };

  const isMissionExpired = (expiration_date) => {
    if (expiration_date) {
      const expiratedDate = new Date(expiration_date);
      return expiratedDate < new Date();
    }
    return false;
  };

  return (
    <>
      <div
        style={{
          height: 10,
          width: 238,
          background: isMissionExpired(expiration_date) ? "red" : "green",
          position: "absolute",
          bottom: -5,
          zIndex: 0,
          borderEndEndRadius: 20,
          borderEndStartRadius: 20,
          marginLeft: 1,
        }}
      />
      <Card
        hoverable
        style={{
          width: 240,
        }}
        actions={[
          <Link to={"/missions/detail/" + allValues._id.$oid} target="_blank">
            <ExportOutlined key={"goToMission"} />
          </Link>,
          <EditOutlined
            key="edit"
            onClick={() => {
              handleEditButton(allValues);
            }}
          />,
          <Dropdown
            trigger={["click"]}
            menu={{
              items,
              onClick,
            }}
          >
            <EllipsisOutlined key="ellipsis" />
          </Dropdown>,
        ]}
        cover={
          <img
            alt="example"
            src={image_mission}
            height={300}
            style={{ objectFit: "cover" }}
          />
        }
      >
        <Meta title={name_mission} description={expiration_date} />
      </Card>
      <ConfigProvider
        theme={{
          components: {
            Modal: {
              contentBg: "white",
            },
          },
        }}
      >
        <Modal
          title="Excluir"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          okText="Sim"
          cancelText="Cancelar"
        >
          <p>Certeza que deseja excluir essa missão?</p>
        </Modal>
        <Modal
          title="Arquivar"
          open={isModalOpenEnabled}
          onOk={handleOkEnabled}
          onCancel={handleEnabled}
          okText="Sim"
          cancelText="Cancelar"
        >
          <p>
            Certeza que deseja {allValues.is_enabled ? "arquivar " : "ativar "}
            essa missão?
          </p>
        </Modal>
      </ConfigProvider>
    </>
  );
}
