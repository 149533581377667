import React, { useEffect, useState } from "react";
import "./watchVideo.css";
import { MissionService } from "red-canids-client-module/client-web";
import WatchVideoTemplate from "../../components/templates/WatchVideoTemplate/WatchVideoTemplate";
import FinderMissionTemplate from "../../components/templates/FinderMissionTemplate/FinderMissionTemplate";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { selectMission } from "../../features/missionSelected/missionSelected";
import QuestionsMissionTemplate from "../../components/templates/QuestionsMissionTemplate/QuestionsMissionTemplate";

export default function WatchVideo() {

    const dispatch = useDispatch();

    const { id_mission } = useParams();
    const [loading, setLoading] = useState(false)
    const [mission, setMision] = useState(null);

    useEffect(() => {
        MissionService.returnMissionById(id_mission).then((response) => {
            
            // setMision(response.data);
            dispatch(selectMission(response.data))
            setMision(response.data);
            setLoading(true)
        }).catch((error) => {
            console.log("error", error)
        }).finally(() => {
            console.log("finally")
        });
    }, []);
    return (
        //To test the "finder mission" page
        //<FinderMissionTemplate/>
        <>
            {
                !loading ?
                    (
                        <h1>Carregando</h1>
                    ) :
                    (
                        <>
                            {
                                mission?.type_mission == "finder" && (
                                    <FinderMissionTemplate />
                                )
                            }
                            {
                                mission?.type_mission == "video" && mission?.video_source != "tiktok" && (
                                    <WatchVideoTemplate />
                                )
                            }
                            {
                                mission?.type_mission == "video" && mission?.video_source == "tiktok" && (
                                    <WatchVideoTemplate />
                                )
                            }
                            {
                                mission?.type_mission == "question" && (
                                    <QuestionsMissionTemplate />
                                )
                            }
                        </>
                    )
            }
        </>
    )
}