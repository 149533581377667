import React from "react";
import { Carousel, Card } from 'antd';
import './EarnWatchingVideo.css';
import CardEarnVideos from "../CardEarnVideos/CardEarnVideos";
import TextSemiboldH4 from "../../atomos/TextSemiboldH4";
import checkIsMobile from "../../../utils/CheckIsMobile";

const { Meta } = Card;

const contentStyle = {
    margin: 0,
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
};

export default function EarnWatchingVideo({ refTour }) {

    const onChange = (currentSlide) => {
        
    };

    const listEarnWatchingVideo = [{
        id: 1,
        title: 'Nova lineup 2024',
        description: 'Nova lineup 2024',
        image: '/images/watch-one.png',
        platform: 'youtube',
        totalPoints: 100,
    }, {
        id: 2,
        title: 'Earn Watching Video 2',
        description: 'Earn Watching Video 2',
        image: '/images/watch-two.png',
        platform: 'youtube',
        totalPoints: 100,
    }, {
        id: 3,
        title: 'Earn Watching Video 3',
        description: 'Earn Watching Video 3',
        image: '/images/watch-three.png',
        platform: 'youtube',
        totalPoints: 100,
    }, {
        id: 4,
        title: 'Earn Watching Video 4',
        description: 'Earn Watching Video 4',
        image: '/images/watch-one.png',
        platform: 'youtube',
        totalPoints: 100,
    }, {
        id: 5,
        title: 'Earn Watching Video 5',
        description: 'Earn Watching Video 5',
        image: '/images/watch-two.png',
        platform: 'youtube',
        totalPoints: 100,
    }, {
        id: 6,
        title: 'Earn Watching Video 6',
        description: 'Earn Watching Video 6',
        image: '/images/watch-three.png',
        platform: 'youtube',
        totalPoints: 100,
    }];

    const listEarnWatchingVideoGrouped = listEarnWatchingVideo.reduce((r, e, i) => {
        if (i % 3 === 0) r.push([e])
        else r[r.length - 1].push(e)
        return r;
    }, []);

    function titleCard() {
        return (
            <div>
                <h1>hola</h1>
            </div>
        )
    }

    function descriptionCard() { }

    function imageCard() {
        return (
            <div>
                <img style={{ height: 45, width: 35 }} src="https://redcanidsstorage.blob.core.windows.net/redcanids/Logo_RC_Big.png" />
            </div>
        )
    }

    return (
        <div ref={refTour} className="div-earn-watching-video">
            {
                !checkIsMobile() ? (
                    <Carousel
                        draggable={true}
                        afterChange={onChange}
                        style={{ width: "100%", height: 560, position: "absolute", left: "0%" }}
                        centerMode={true}
                        children={listEarnWatchingVideoGrouped.map((item, idx) => {
                            return (
                                <div >
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                                        {item.map((item2, idx2) => {
                                            return (
                                                <>
                                                    {/* <Card
                                                hoverable
                                                style={{ width: 240, height: 350 }}
                                                cover={<img alt="example" src={item2.image} />}
                                            >
                                                <Meta avatar={imageCard()} title={item2.title} description={item2.description} />
                                            </Card> */}
                                                    <CardEarnVideos title={item2.title} platform={item2.platform} totalPoints={item2.totalPoints} image={item2.image} />
                                                </>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        })}
                    >
                    </Carousel>
                ) : (
                    <>
                        <Carousel
                            draggable={true}
                            afterChange={onChange}
                            style={{ width: "100%", height: 570, position: "absolute", left: "0%" }}
                            centerMode={true}
                            children={listEarnWatchingVideo.map((item, idx) => {
                                return (
                                    <div >
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                                            <CardEarnVideos title={item.title} platform={item.platform} totalPoints={item.totalPoints} image={item.image} />
                                        </div>
                                    </div>
                                )
                            })}
                        >
                        </Carousel>
                    </>
                )
            }

        </div>
    )
}