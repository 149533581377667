import React from "react";
import TextBoldH6 from "../../atomos/TextBoldH6";
import { Flex, Button, ConfigProvider } from "antd";
import TextBoldParagraph from "../../atomos/TextBoldParagraph"; /* 
import MissionType from './MissionType' */
import "../ProductsToRescue/ProdutsToRescue.css";
import ImageRare from "../../atomos/ImageRare";
import ImageNormal from "../../atomos/ImageNormal";
import ModalToRescueProduct from "../ModalToRescueProduct/ModalToRescueProduct";
import "./CardProductToRescue.css";
export default function CardProductToRescue({ products, url, params }) {
  const colors = require("../../../styles/Colors.json");

  function returnTotalProducts() {
    if (params.type_product != "physical") {
      return "ထ"
    } else {
      return params.stock == 0 ? "Sem Estoque" : params.stock
    }
  }

  return (
    <Flex className="div-product-card">
      <div className="div-product-card-image">
        <img src={params.image_product} className="image-product-card" alt="Product" />
      </div>
      <div className="div-product-card-content">
        <div className="div-product-card-content-type">
          <TextBoldH6
            text={params.name_product}
            colorText={"white"}
            textAlign={"left"}
          />
        </div>
        <div className="div-product-card-content-type">
          {params.classification === "rare" ? (
            <>
              <ImageRare />
              <TextBoldParagraph
                text={"Produto Raro"}
                colorText={colors.AlertSlotColor}
              />
            </>
          ) : (
            <>
              <ImageNormal />
              <div style={{ display: "flex", marginLeft: 4 }}>
                <TextBoldParagraph
                  text={"Produto Comum"}
                  colorText={"#ff5e75"}
                />
              </div>
            </>
          )}
        </div>
        <div className="div-product-card-line" />
        <div className="div-product-card-infos">
          <div className="div-product-card-available-amount">
            <TextBoldParagraph text={"Disponível"} colorText={"grey"} />
            <TextBoldParagraph
              text={returnTotalProducts()}
              colorText={"white"}
            />
          </div>
          {params.classification === "rare" ? (
            <>
              <div className="div-product-card-rare-coins">
                <TextBoldParagraph
                  text={params.price}
                  colorText={"white"}
                />
              </div>
            </>
          ) : (
            <>
              <div className="div-product-card-normal-coins">
                <TextBoldParagraph
                  text={params.price}
                  colorText={"white"}
                />
              </div>
            </>
          )}
        </div>
        <div className="div-product-card-line" />
        <div
          style={{
            display: "flex",
            width: "90%",
          }}
        >
          <ModalToRescueProduct product={params} />
        </div>
        <div></div>
      </div>

    </Flex>
  );
}
