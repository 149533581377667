import React, { useEffect, useRef, useState } from "react";
import "./WatchVideoTemplate.css";
import { TikTok } from "react-tiktok";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import MenuDefaultClient from "../../organisms/Menu/Menu";
import HeaderAfterMenu from "../../organisms/HeaderAfterMenu/HeaderAfterMenu";
import ProgressPointWatchVideo from "../../organisms/ProgressPointWatchVideo/ProgressPointWatchVideo";
import Footer from "../../organisms/Footer.js/Footer";
import { useDispatch, useSelector } from "react-redux";
import { addRedCoins, setPointsFarmer, setPointsFarmerAccount } from "../../../features/account/account";
import { AccountService, OpenAPI } from "red-canids-client-module/client-web";
import { timeToSeconds } from "../../../utils/ConvertTimeMissionToSecounds";
import { Alert, Button } from "antd";
import secureLocalStorage from "react-secure-storage";
import { encryptAES } from "../../../utils/Crypt";
import TextBoldParagraph from "../../atomos/TextBoldParagraph";

export default function WatchVideoTemplate() {
    const { id_mission } = useParams();

    const missionSelec = useSelector((state) => state.missionSelected);
    const account = useSelector((state) => state.account);

    const navigate = useNavigate();

    const [progress, setProgress] = React.useState(0.01);
    const [sizeScreenWidth, setSizeScreenWidth] = React.useState(window.innerWidth);
    const [mission, setMision] = useState(missionSelec);
    const [indexVideo, setIndexVideo] = React.useState(0);
    const [pointsFarmerState, setPointsFarmerState] = React.useState(account?.points_farmer);

    const videoFirstTime = useRef(false);

    const indexVideoRef = useRef(indexVideo);

    const reactPlayerRef = useRef(null);
    const timeMissoinSelected = useRef(null);
    const isPlayer = useRef(false);
    const timeInitial = useRef(new Date().toISOString());

    const location = useLocation();
    const dispatch = useDispatch();

    const setTimeoutProgress = useRef(null);

    // useEffect(() => {
    //     indexVideoRef.current = indexVideo;
    // }, [indexVideo]);

    useEffect(() => {
        if (missionSelec?.name_mission.includes("FARMAR")) {
            var points = account?.points_farmer_redux;
            if (account?.red_coins) {
                if (account?.red_coins == "NaN" || account?.red_coins == "undefined" || account?.red_coins == "null")
                    return;
                AccountService.addRedCoinsFarmer(account._id.$oid, parseFloat(account.red_coins), encryptAES(new Date().toISOString()), points).then((response) => {
                    // AccountService.returnAccountByEmailAndCodeAccess(account.email, account.code_access).then((response) => {
                    //     dispatch(setPointsFarmerAccount(response.data.points_farmer));
                    // });
                    // dispatch(setPointsFarmerAccount(points));
                }).catch((error) => {
                    console.log("error", error)
                }).finally(() => {
                });
            }
            if (account?.red_xp) {
                if (account?.red_xp == "NaN" || account?.red_xp == "undefined" || account?.red_xp == "null")
                    return;
                AccountService.addRedXpFarmer(account._id.$oid, parseFloat(account.red_xp), encryptAES(new Date().toISOString()), points).then((response) => {

                }).catch((error) => {
                    console.log("error", error)
                }).finally(() => {
                });
            }
        }
    }, [account]);

    useEffect(() => {
        window.scrollTo(0, 0);
        // secureLocalStorage.setItem("707aa744-1ac9-4dcd-8e61-32578c79924a", 0);
        // var value = secureLocalStorage.getItem("707aa744-1ac9-4dcd-8e61-32578c79924a");
        // if (value === null) {
        //     secureLocalStorage.setItem("707aa744-1ac9-4dcd-8e61-32578c79924a", 0);
        // }
        dispatch(setPointsFarmer(0));
        if (missionSelec?.video_source === "tiktok" && missionSelec?.device !== "mobile") {
            if (!setTimeoutProgress.current) {
                const timeMission = timeToSeconds(missionSelec?.time_mission);
                timeMissoinSelected.current = timeMission;
                setTimeoutProgress.current = setInterval(() => {
                    setProgress((oldProgress) => {
                        return oldProgress >= 100 ? timeMissoinSelected.current : oldProgress + ((1 / timeMissoinSelected.current) * 100);
                    });
                }, 1000);
            }
        } else {
            // if (!setTimeoutProgress.current) {
            //     const timeMission = timeToSeconds(missionSelec?.time_mission);
            //     timeMissoinSelected.current = timeMission;
            //     setTimeoutProgress.current = setInterval(() => {
            //         if (isPlayer.current) {
            //             setProgress((oldProgress) => {
            //                 if (missionSelec?.name_mission?.includes("FARMAR")) {
            //                     var pointsToAdded = missionSelec.points / timeMissoinSelected.current;
            //                     dispatch(addRedCoins(pointsToAdded));
            //                 }
            //                 return oldProgress >= 100 ? timeMissoinSelected.current : oldProgress + ((1 / timeMissoinSelected.current) * 100);
            //             });
            //         }
            //     }, 1000);
            // }
            console.log("old handle")
        }
        setInterval(() => {
            AccountService.returnAccountByEmailAndCodeAccess(account.email, account.code_access).then((response) => {
                // dispatch(setPointsFarmerAccount(response.data.points_farmer));
                setPointsFarmerState(response.data.points_farmer);
            });
        }, 20000);
    }, []);

    useEffect(() => {
        if (progress >= 90) {
            clearInterval(setTimeoutProgress.current);
            if (missionSelec?.name_mission.includes("FARMAR")) {
                console.log("FARMAR")
            } else {
                AccountService.updateMissionCompleted({
                    id_account: account._id.$oid,
                    id_mission: missionSelec._id.$oid,
                    points: missionSelec.points,
                    type_mission: missionSelec.type_mission,
                    type_points: missionSelec.type_points,
                }).then((response) => {
                    dispatch(addRedCoins(missionSelec.points));
                }).catch((error) => {
                    console.log("error", error)
                }).finally(() => {
                    console.log("finally")
                });
            }
        }
    }, [progress]);

    const itemsBreadCrumb = [
        {
            title: "Home",
            path: "/home"
        },
        {
            title: "Missões",
            path: "/missions"
        }, {
            title: "Detalhe da missão",
            path: "/missions/detail/" + id_mission
        }, {
            title: "Assistir vídeo",
        }];

    return (
        <>
            <MenuDefaultClient page={location} />
            <HeaderAfterMenu title={missionSelec.name_mission} items={itemsBreadCrumb} />
            <ProgressPointWatchVideo progress={progress} width={sizeScreenWidth > 853 ? 853 : sizeScreenWidth} />
            {
                progress >= 100 && (
                    <Button
                        type="primary"
                        className="button-border"
                        style={{ width: 300, marginBottom: 20, alignSelf: "center" }}
                        onClick={() => navigate("/missions")}
                    >
                        Voltar para missões
                    </Button>
                )
            }

            <div className="frame-watch-video">
                {
                    missionSelec?.video_source === "tiktok" ? (
                        <>
                            {
                                mission?.device == "mobile" ? (
                                    <a
                                        onClick={() => {
                                            if (setTimeoutProgress.current) {
                                                const timeMission = timeToSeconds(missionSelec?.time_mission);
                                                timeMissoinSelected.current = timeMission;
                                                setTimeoutProgress.current = setInterval(() => {
                                                    setProgress((oldProgress) => {
                                                        return oldProgress >= 100 ? timeMissoinSelected.current : oldProgress + ((1 / timeMissoinSelected.current) * 100);
                                                    });
                                                }, 1000);
                                            }
                                        }}
                                        target="_blank"
                                        href={mission?.videos_mission[indexVideo] + "?referer_url=localhost%3A3001%2Fmissions%2Fdetail%2Fwatch-video%2F65bbed3300d37e89b84947fd&amp;refer=embed&amp;embed_source=71929437%2C121374463%2C121351166%2C71838018%2C121331973%2C120811592%2C120810756%3Bnull%3Bembed_like&amp;referer_video_id=7085776694430190853"}
                                        rel="noreferrer"
                                        data-e2e="src-SmartWrapperExtension-a"
                                        style={{ fontStyle: "normal", fontWeight: "normal", fontSize: "16px", lineHeight: "19px", color: "#FFFFFF", textDecoration: "none", display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "100%", marginBottom: 20 }}
                                    >
                                        <div data-e2e="Player-Layer-LayerLikeCommentShareComponent" class="css-1o8oa5s es828bz1">
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                className="button-border"
                                                style={{ width: "100%" }}
                                                onClick={() => {
                                                    setProgress(99);
                                                    AccountService.updateMissionCompleted({
                                                        id_account: account._id.$oid,
                                                        id_mission: missionSelec._id.$oid,
                                                        points: missionSelec.points,
                                                        type_mission: missionSelec.type_mission,
                                                        type_points: missionSelec.type_points,
                                                    }).then((response) => {
                                                        dispatch(addRedCoins(missionSelec.points));
                                                    }).catch((error) => {
                                                        console.log("error", error)
                                                    }).finally(() => {
                                                        console.log("finally")
                                                    });
                                                }}
                                            >
                                                Assistir vídeo
                                            </Button>
                                        </div>
                                    </a>
                                ) : (
                                    <TikTok url={mission?.videos_mission[indexVideo]} />
                                )
                            }
                        </>
                    ) : (
                        <>
                            <ReactPlayer
                                ref={reactPlayerRef}
                                url={mission?.videos_mission[indexVideoRef.current]}
                                playing
                                onError={(e) => {
                                    console.log("e", e);
                                }}
                                controls={true}
                                onEnablePIP={() => {
                                    // console.log("onEnablePIP")
                                }}
                                loop={false}
                                progressInterval={10000}
                                config={{
                                    youtube: {
                                        playerVars: {
                                            showinfo: false,
                                            controls: true,
                                            modestbranding: false,
                                            rel: 0,
                                            autoplay: true,
                                            origin: window.location.origin,
                                            host: window.location.host,
                                        },
                                        embedOptions: {
                                            allowfullscreen: true,
                                            allow: "autoplay",
                                        }
                                    },
                                }}
                                onEnded={() => {
                                    isPlayer.current = false;
                                    setIndexVideo((oldIndex) => {
                                        if (oldIndex + 1 === mission?.videos_mission.length) {
                                            indexVideoRef.current = 0;
                                            return 0;
                                        }
                                        if (videoFirstTime.current) {
                                            videoFirstTime.current = false;
                                            indexVideoRef.current = oldIndex;
                                            return oldIndex;
                                        } else {
                                            indexVideoRef.current = oldIndex + 1;
                                            return oldIndex + 1;
                                        }
                                    })
                                }}
                                onDisablePIP={() => {
                                    // console.log("onDisablePIP")
                                }}
                                onSeek={(newTime) => {
                                    // console.log("newTime", newTime)
                                }}
                                onDuration={(duration) => {
                                    // console.log("duration", duration)
                                }}
                                onPlay={() => {
                                    isPlayer.current = true;
                                }}
                                onPause={() => {
                                    isPlayer.current = false;
                                }}
                                onProgress={(state) => {
                                    const timeMission = timeToSeconds(missionSelec?.time_mission);
                                    timeMissoinSelected.current = timeMission;

                                    if (isPlayer.current) {
                                        setProgress((oldProgress) => {
                                            if (missionSelec?.name_mission?.includes("FARMAR")) {
                                                var pointsToAdded = missionSelec.points / timeMissoinSelected.current;
                                                dispatch(setPointsFarmer(pointsToAdded * 10));
                                                dispatch(addRedCoins(pointsToAdded * 10));
                                            }
                                            return oldProgress >= 100 ? timeMissoinSelected.current : (oldProgress + ((1 / timeMissoinSelected.current) * 100) * 10);
                                        });
                                    }
                                }}
                            />
                        </>
                    )
                }
            </div>
            {
                missionSelec?.video_source != "tiktok" && missionSelec?.video_source != "tracking" && (
                    <div style={{ width: "100%", display: "flex", justifyContent: "center", margin: 10, flexDirection: "column" }}>
                        {
                            missionSelec?.name_mission?.includes("FARMAR") && (
                                <TextBoldParagraph text={"Pontos já farmado: " + parseInt(pointsFarmerState)} colorText={"white"} />
                            )
                        }
                        <Alert message={<span>Ao sair da página <br /> seu tempo de vídeo não será contabilizado</span>} type="error" style={{ backgroundColor: "black", color: "white", borderWidth: 0 }} />
                    </div>
                )
            }
            <Footer />
        </>
    )
}