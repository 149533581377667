import React, { useEffect, useState } from "react";
import { Modal, Flex } from "antd";
import TextBoldH3 from "../../atomos/TextBoldH3";
import TextSemiboldH6 from "../../atomos/TextSemiboldH6";
import TextBoldH6 from "../../atomos/TextBoldH6";
import "./ModalPrivacyPolicy.css";

export default function ModalPrivacyPolicy() {
  const [modalVisible, setModalVisible] = useState(false);
  const handleCloseModal = () => {
    setModalVisible(false);
  };
  const handleModalToggle = () => {
    setModalVisible(!modalVisible);
  };
  const handleModalCancel = () => {
    setModalVisible(false);
  };

  return (
    <div>
      <a href="#" className="button-style" onClick={handleModalToggle}>
        <TextBoldH6 text={"Política de Privacidade"} fontSize={10} />
      </a>
      <Modal visible={modalVisible} onCancel={handleModalCancel} footer={null}>
        <Flex
          style={{
            minHeight: "300px",
            minWidth: "100%",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div style={{ color: "white" }}>
            <h1>Política de Privacidade - RED Canids Esports Club Ltda.</h1>

            <h2>1. INTRODUÇÃO</h2>
            <p>
              A RED Canids Esports Club Ltda. ("RED Canids", "nós", "nosso" ou
              "nos"), com sede na Rua Santa Cruz, 1168 - Vila Mariana, São Paulo
              - SP, 04122-000, inscrita no CNPJ sob o nº 23.789.038/0001-90,
              preza pela privacidade de seus usuários e se compromete a proteger
              seus dados pessoais de acordo com a legislação brasileira, em
              especial a Lei Geral de Proteção de Dados Pessoais (LGPD).
            </p>
            <p>
              Esta Política de Privacidade ("Política") tem como objetivo
              explicar como coletamos, utilizamos, compartilhamos, armazenamos e
              protegemos seus dados pessoais quando você acessa e utiliza nossos
              sites, plataformas digitais, aplicativos e serviços ("Serviços").
              A Política também descreve seus direitos como titular dos dados e
              como você pode contatar-nos para exercer seus direitos.
            </p>
            <p>
              Ao utilizar nossos Serviços, você concorda com os termos desta
              Política. Se você não concordar com os termos desta Política, você
              não deve utilizar nossos Serviços.
            </p>

            <h2>2. COLETA DE DADOS PESSOAIS</h2>
            <p>
              Coletamos diversos tipos de dados pessoais quando você utiliza
              nossos Serviços, incluindo:
            </p>
            <ul>
              <li>
                Dados de Cadastro: nome completo, endereço de e-mail, CPF
                (opcional), data de nascimento, gênero, país e cidade de
                residência.
              </li>
              <li>
                Dados de Contato: endereço de e-mail, número de telefone
                (opcional).
              </li>
              <li>
                Dados de Navegação: endereço IP, data e hora de acesso,
                localização geográfica, tipo de navegador, duração da visita e
                páginas visitadas.
              </li>
              <li>
                Dados de Interação: comentários, avaliações, mensagens e outras
                informações que você nos fornece voluntariamente.
              </li>
              <li>
                Dados de Mídia Social: se você conectar sua conta RED Canids a
                uma conta de rede social, podemos coletar informações públicas
                do seu perfil, como nome, foto, lista de amigos e interesses.
              </li>
              <li>
                Dados de Dispositivo: modelo do dispositivo, sistema
                operacional, operadora de telefonia, tipo de navegador e tipo de
                conexão.
              </li>
            </ul>
            <p>
              Podemos coletar seus dados pessoais de diferentes maneiras,
              incluindo:
            </p>
            <ul>
              <li>
                Quando você se cadastra em nossos Serviços: coletamos seus dados
                de cadastro quando você cria uma conta em nosso site ou
                aplicativo.
              </li>
              <li>
                Quando você utiliza nossos Serviços: coletamos dados de
                navegação, dados de interação e dados de dispositivo enquanto
                você utiliza nossos Serviços.
              </li>
              <li>
                Quando você nos contata: coletamos seus dados de contato quando
                você nos envia um e-mail, faz uma ligação ou entra em contato
                conosco por outro meio.
              </li>
              <li>
                Quando você conecta sua conta RED Canids a uma conta de rede
                social: coletamos informações públicas do seu perfil de rede
                social quando você conecta sua conta RED Canids a uma conta de
                rede social.
              </li>
            </ul>

            <h2>3. USO DE DADOS PESSOAIS</h2>
            <p>Utilizamos seus dados pessoais para os seguintes fins:</p>
            <ul>
              <li>Fornecer e aprimorar nossos Serviços.</li>
              <li>Comunicar-se com você.</li>
              <li>Enviar mensagens promocionais.</li>
              <li>Realizar pesquisas e análises.</li>
              <li>Prevenir fraudes e proteger nossos Serviços.</li>
            </ul>

            <h2>4. COMPARTILHAMENTO DE DADOS PESSOAIS</h2>
            <p>
              Podemos compartilhar seus dados pessoais com as seguintes
              categorias de terceiros:
            </p>
            <ul>
              <li>
                Parceiros de Serviços: podemos compartilhar seus dados pessoais
                com parceiros de serviços que nos auxiliam na operação de nossos
                Serviços, como provedores de hospedagem, provedores de pagamento
                e empresas de análise de dados.
              </li>
              <li>
                Autoridades Governamentais: podemos compartilhar seus dados
                pessoais com autoridades governamentais se formos obrigados a
                fazê-lo por lei ou se acreditarmos de boa fé que tal ação seja
                necessária para proteger nossos direitos ou os direitos de
                terceiros.
              </li>
            </ul>
            <p>Não venderemos seus dados pessoais a terceiros.</p>

            <h2>5. ARMAZENAMENTO DE DADOS PESSOAIS</h2>
            <p>
              Armazenamos seus dados pessoais pelo tempo necessário para cumprir
              os fins para os quais foram coletados, a menos que a legislação
              brasileira exija um período de retenção maior. Após o período de
              retenção, seus dados pessoais serão eliminados de forma segura.
            </p>

            <h2>6. SEGURANÇA DE DADOS PESSOAIS</h2>
            <p>
              Implementamos medidas técnicas e organizacionais adequadas para
              proteger seus dados pessoais contra perda, uso indevido, acesso
              não autorizado, divulgação, alteração ou destruição. No entanto,
              nenhuma transmissão pela Internet ou armazenamento eletrônico é
              100% seguro. Como resultado, não podemos garantir a segurança
              absoluta de seus dados pessoais.
            </p>

            <h2>7. SEUS DIREITOS COMO TITULAR DE DADOS</h2>
            <p>
              De acordo com a LGPD, você possui diversos direitos como titular
              de dados pessoais. Você tem o direito de:
            </p>
            <ul>
              <li>Confirmação de tratamento.</li>
              <li>Retificação de dados.</li>
              <li>Anonimização, bloqueio ou eliminação de dados.</li>
              <li>Portabilidade de dados.</li>
              <li>Oposição ao tratamento.</li>
              <li>Revogação do consentimento.</li>
            </ul>
            <p>
              Para exercer seus direitos como titular de dados, você pode entrar
              em contato conosco através dos canais de contato descritos na
              seção 9 desta Política.
            </p>

            <h2>8. COOKIES</h2>
            <p>
              Utilizamos cookies e tecnologias similares para rastrear a
              atividade em nossos Serviços e armazenar determinadas informações.
              Os cookies são pequenos arquivos de dados que são transferidos
              para o seu dispositivo quando você visita um site. Você pode
              desabilitar os cookies ajustando as configurações do seu
              navegador. No entanto, desabilitar os cookies pode limitar sua
              capacidade de utilizar todos os recursos de nossos Serviços.
            </p>
            <p>
              Para obter mais informações sobre como usamos cookies, consulte
              nossa Política de Cookies.
            </p>

            <h2>9. CONTATO</h2>
            <p>
              Se você tiver alguma dúvida sobre esta política ou sobre o
              tratamento de seus dados pessoais, entre em contato conosco
              através dos seguintes canais:
            </p>
            <p>
              E-mail:{" "}
              <a href="mailto:contato@redcanids.com.br">
                contato@redcanids.com.br
              </a>
            </p>

            <h2>10. ATUALIZAÇÕES PARA ESTA POLÍTICA</h2>
            <p>
              Podemos atualizar esta Política periodicamente para refletir
              alterações em nossas práticas de privacidade. Publicaremos a
              Política revisada em nossos Serviços. Recomendamos que você revise
              esta Política periodicamente para se manter informado sobre nossas
              práticas de privacidade.
            </p>
            <p>Data da última atualização: 12/04/2024</p>
          </div>
        </Flex>
      </Modal>
    </div>
  );
}
