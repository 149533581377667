import { Flex } from "antd";
import "./LoginManagerTemplate.css"
import React, { useState } from "react";
import LogoRedDimension from "../../organisms/3DLogoRed/3DLogoRed";
import LoginManagerForm from "../../organisms/LoginManagerForm/LoginManagerForm";

export default function LoginManagerTemplate() {
  const [loading, setLoading] = useState(true);
  return (
    <div style={{ maxHeight: "100vh" }}>
      <div className={loading ? "loading_screen" : "loading_screen deactivate"}>
        <div class="custom-loader"></div>
      </div>
      <Flex>
        <Flex style={{ flex: 2 }}>
          <LogoRedDimension setLoading={setLoading} />
        </Flex>
        <Flex style={{ flex: 1 }} justify align={"center"}>
          <LoginManagerForm />
        </Flex>
      </Flex>
    </div>
  );
}
