import React, { useEffect, useState } from "react";
import TextBoldH2 from "../../atomos/TextBoldH2";
import { Button, ConfigProvider, Flex, Segmented } from "antd";
import TextBoldH5 from "../../atomos/TextBoldH5";
import TextBoldH3 from "../../atomos/TextBoldH3";
import Orderslist from "../../organisms/ManagerProducts/OrdersList";
import { PurchasesService } from "red-canids-client-module/client-web";

export default function ManagerProductsTemplate() {
  const colors = require("../../../styles/Colors.json");
  const [purchases, setPurchases] = useState([]);
  const [showVisible, setShowVisible] = useState(true);

  useEffect(() => {
    PurchasesService.returnAllPurchase()
      .then((res) => {
        setPurchases(res.data);
      })
      .catch((err) => console.error(err));

    return () => {};
  }, []);

  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Modal: {
              contentBg: "white",
            },
            Segmented: {
              itemActiveBg: "white",
              itemSelectedBg: "#ffffff",

              colorBgLayout: "#f5f5f5",
            },
          },
        }}
      >
        <Flex
          style={{
            flexDirection: "column",
            width: "100%",
            backgroundColor: "white",
          }}
        >
          <Flex justify="center" align="center">
            <TextBoldH5 text={"Compras"} colorText={colors.GrayscaleSlot5} />
          </Flex>
          <Flex
            justify={"end"}
            align={"flex-end"}
            style={{ marginRight: "3%" }}
          >
            <Segmented
            defaultValue={"Compras pendentes"}
              options={[
                { label: "Compras pendentes", value: "Compras pendentes" },

                { label: "Mostrar todos", value: "Mostrar todos" },
              ]}
              onChange={() => {
                setShowVisible(!showVisible);
              }}
            />
          </Flex>
          <Flex style={{ padding: "2%" }}>
            <Orderslist purchases={purchases} showVisible={showVisible} />
          </Flex>
        </Flex>
      </ConfigProvider>
    </>
  );
}
