import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Spin } from "antd";

export default function CoverImage() {
    // Use useSelector para acessar o estado global do Redux
    const selectedCoverImage = useSelector((state) => state.coverImage.selectedCoverImage);
    const account = useSelector((state) => state.account);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        setInterval(() => {
            setLoading(false);
        }, 1000);
    }, []);

    return (
        <>
            {/* {
                loading ?
                    <div style={{ height: 300, justifyContent: "center", alignItems: "center", display: "flex" }}>
                        <Spin size="large"/>
                    </div>
                    :
                    <img
                        loading="lazy"
                        src={account?.background_photo || selectedCoverImage || "/images/cover-image.jpg"}
                        style={{
                            height: 300,
                            objectFit: "cover",
                        }}
                        alt="Selected Cover Image"
                    />

            } */}
            {
                account?.background_photo ? (
                    <img
                        loading="lazy"
                        src={account?.background_photo}
                        style={{
                            height: 300,
                            objectFit: "cover",
                        }}
                        alt="Carregando..."
                    />
                ) : (
                    <div style={{height: 50}}/>
                )
            }
        </>
    );
}
