import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Flex,
  Form,
  Image,
  Input,
  Radio,
  Row,
  Typography,
  Upload,
  message,
  ConfigProvider,
  DatePicker,
  Popconfirm,
  Space,
} from "antd";
import ButtonWallet from "../../atomos/ButtonWallet";
import UploadImages from "../UploadImages/Upload";
import { AccountService, ProductService } from "red-canids-client-module/client-web";
import { account } from "../../../features/account/account";
import { useDispatch, useSelector } from "react-redux";
import "./EditProfile.css";
import checkIsMobile from "../../../utils/CheckIsMobile";
import dayjs from "dayjs";
import TextArea from "antd/es/input/TextArea";
import Alert from "antd/es/alert/Alert";

export default function EditProfile() {
  const account = useSelector((state) => state.account);
  const colors = require("../../../styles/Colors.json");
  const [form] = Form.useForm();
  const [ImageProfile, setImageProfile] = useState("");
  const [dateString, setDateString] = useState("");
  const [backgroundImage, setBackgroundImage] = useState("");
  const [loadingImage, setLoadingImage] = useState(false);
  const [imagePhotoProfile, setImagePhotoProfile] = useState("");
  const [imageBackgroundProfile, setImageBackgroundProfile] = useState("");

  const defaultBackgroundPhoto =
    "https://redcanidsstorage.blob.core.windows.net/redcanids/c727e8ce-7cfc-455a-a26c-5a5d592366d5.png";
  const defaultBackgroundPhoto2 =
    "https://redcanidsstorage.blob.core.windows.net/redcanids/b839ac2d-ab9d-46ed-815f-115f987d1639.png";
  // const backgroundPhotos = [defaultBackgroundPhoto, defaultBackgroundPhoto2];
  const [backgroundPhotos, setBackgroundPhotos] = useState([]);

  const props = {
    name: "file",
    action: "https://red-canids-api.azurewebsites.net/image/add-image",
    onChange(info) {
      if (info.file.status === "uploading") {
        setLoadingImage(true);
      }
      if (info.file.status === "done") {
        AccountService.updateAccountById(account._id.$oid, {
          background_photo: account.background_photo,
          bio: account.bio,
          birthday: account.birthday,
          discord_id: account.discord_id,
          email: account.email,
          genre: account.genre,
          instagram_id: account.instagram_id,
          name: account.name,
          photo_profile: info.file.response.files[0].url
            ? info.file.response.files[0].url
            : account.photo_profile,
          tiktok_id: account.tiktok_id,
          twitch_id: account.twitch_id,
          twitter_id: account.twitter_id,
        })
          .then((res) => {
            message.success(`Imagem guardada com sucesso`);
            setImagePhotoProfile(info.file.response.files[0].url);
          })
          .catch((err) => console.error(err))
          .finally(() => {
            setImageProfile(info.file.response.files[0].url);
            setLoadingImage(false);
          });
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
        setLoadingImage(false);
      }
    },
  };

  const handleSelectBackground = (url) => {
    setBackgroundImage(url);
    var accountUpdated = Object.assign({}, account);
    accountUpdated.background_photo = url;
    AccountService.updateAccountById(account._id.$oid, accountUpdated).then((res) => {
      message.success(`Imagem guardada com sucesso`);
      setImageBackgroundProfile(url);
    });
  };
  const handleBirthday = (date, dateString) => {
    setDateString(dateString);
  };

  const confirm = (e) => {
    localStorage.clear();
    window.location.reload();
  };
  const cancel = (e) => {

  };

  const onFinish = (values) => {
    AccountService.updateAccountById(account._id.$oid, {
      background_photo: imageBackgroundProfile,
      bio: values.bio ? values.bio : account.bio,
      birthday: dateString ? dateString : account.birthday,
      discord_id: values.discord_id ? values.discord_id : account.discord_id,
      email: values.email ? values.email : account.email,
      genre: values.genre ? values.genre : account.genre ? account.genre : "",
      instagram_id: values.instagram_id
        ? values.instagram_id
        : account.instagram_id,
      name: values.name ? values.name : account.name,
      photo_profile: imagePhotoProfile,
      tiktok_id: values.tiktok_id ? values.tiktok_id : account.tiktok_id,
      twitch_id: values.twitch_id ? values.twitch_id : account.twitch_id,
      twitter_id: values.twitter_id ? values.twitter_id : account.twitter_id,
    })
      .then((res) => {
        message.success("Profile editado com sucesso");

        localStorage.setItem(
          "@_auth_sessions",
          btoa(
            JSON.stringify({
              email: values.email ? values.email : account.email,
              code_access: account.code_access,
            })
          )
        );
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => console.error(err));
  };
  const onFinishFailed = (values) => { };

  useEffect(() => {
    setBackgroundImage(account.background_photo);
  }, [account.background_photo]);

  useEffect(() => {
    ProductService.returnAllVirtualProducts().then((res) => {
      // console.table(res.data);
      const enabledProducts = res.data.filter(
        (product) => product.price <= account?.red_xp
      );
      setBackgroundPhotos(enabledProducts.map((product) => product.image_product));
      // console.table(enabledProducts);
    });
    form.setFieldsValue({
      bio: account.bio,
      discord_id: account.discord_id,
      birthday: dayjs(account.birthday),
      email: account.email,
      genre: account.genre,
      instagram_id: account.instagram_id,
      name: account.name,
      tiktok_id: account.tiktok_id,
      twitch_id: account.twitch_id,
      twitter_id: account.twitter_id,
    });
    setImageBackgroundProfile(account.background_photo);
    setImagePhotoProfile(account.photo_profile);
  }, [account])

  useEffect(() => {
  }, []);

  return (
    <>
      {!checkIsMobile() ? (
        <Flex
          vertical
          align="center"
          justify="center"
          style={{
            width: "90vw",
            justifySelf: "center",
            alignSelf: "center",
          }}
        >
          <Typography.Title
            style={{
              fontFamily: "Montserrat",
              fontWeight: 700,
              fontSize: "16px",
              lineHeight: "28px",
              textAlign: "center",
              color: "rgb(255, 255, 255)",
            }}
          >
            Escolha sua capa de perfil
          </Typography.Title>
          <Flex justify="center" gap={20} style={{ marginBottom: "3%" }}>
            {backgroundPhotos.map((photo, index) => (
              <Image
                key={index}
                preview={false}
                src={photo}
                onClick={() => handleSelectBackground(photo)}
                style={{
                  width: 400,
                  height: 120,
                  borderRadius: 12,
                  border: backgroundImage === photo ? "2px solid red" : "none",
                }}
              />
            ))}
          </Flex>
          <Flex gap={50} style={{ width: "100%" }}>
            <Flex vertical gap={30} flex={1}>
              <div
                style={{
                  background: "#0a0d0d",
                  display: "flex",
                  flexDirection: "column",
                  gap: 20,
                  borderRadius: "12px",
                }}
              >
                <Image
                  src={ImageProfile ? ImageProfile : (account.photo_profile?.length == 0 ? "https://redcanidsstorage.blob.core.windows.net/redcanids/3d8ad8e4-257b-4225-85c1-a145994ceb66.png" : account.photo_profile)}
                  style={{
                    height: 300,
                    objectFit: "cover",
                    borderRadius: "12px",
                  }}
                ></Image>
                <Upload accept=".jpg , .png , .jpge" {...props} maxCount={1} showUploadList={false}>
                  <Button
                    type="primary"
                    loading={loadingImage}
                    className="button-border"
                    style={{ width: "100%", height: 48, marginBottom: "1rem" }}
                  >
                    Atualizar foto
                  </Button>
                </Upload>
              </div>
              <Alert message="O tamanho máximo indicado para a imagem é de 512 x 512." type="warning" />
            </Flex>
            <ConfigProvider
              theme={{
                components: {
                  Input: {
                    colorTextPlaceholder: colors.GrayscaleSlot1,
                    colorBorder: "#37363B",
                    hoverBorderColor: "#37363B",
                    activeBorderColor: "red",
                    colorText: colors.GrayscaleSlot3,
                  },
                  Form: {
                    labelColor: colors.GrayscaleSlot3,
                  },
                  Radio: {
                    buttonSolidCheckedActiveBg: "red",
                    buttonSolidCheckedBg: "red",
                    buttonSolidCheckedHoverBg: "red",
                    buttonCheckedBg: "red",
                    buttonBg: "red",
                  },
                  DatePicker: {
                    activeBg: "#37363B",
                    activeBorderColor: "red",
                    hoverBg: "#37363B",
                  },
                },
                token: {
                  colorPrimary: "red",
                  colorText: "white",
                  colorBorder: "#37363B",
                },
              }}
            >
              <Flex
                gap={30}
                flex={4}
                justify={"center"}
                vertical
                style={{ width: "100%" }}
              >
                <Form
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  form={form}
                  validateMessages={{
                    types: {
                      email: "${label} is not a valid email!",
                    },
                  }}
                  layout={"vertical"}
                  style={{
                    display: "flex",
                    gap: 50,
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Flex vertical style={{ width: "50%" }}>
                    <h3 style={{ color: "white" }}>Detalhes da sua conta</h3>
                    <Form.Item label="Nome de Perfil" name={"name"}>
                      <Input />
                    </Form.Item>

                    <Form.Item label="Seleciona o teu genero" name={"genre"}>
                      <Radio.Group>
                        <Radio value={"male"}>Homem</Radio>
                        <Radio value={"female"}>Mulher</Radio>
                        <Radio value={"non-binary"}>Não binário</Radio>
                        <Radio value={"other"}>Outro</Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item
                      label={"Data de Nascimento"}
                      name="birthday"
                      style={{ alignSelf: "flex-start" }}
                    >
                      <ConfigProvider
                        theme={{
                          token: {
                            colorBgContainer: "black",
                            colorBgElevated: "#37363B",
                            colorIcon: "white",
                            colorLink: "red",
                            colorTextDescription: "white",
                            colorTextHeading: "white",
                            colorTextDisabled: "white",
                            colorTextPlaceholder: "white",
                          },
                        }}
                      >
                        <DatePicker
                          placeholder={
                            dateString ? dateString : account.birthday
                          }
                          format={"DD/MM/YYYY"}
                          onChange={handleBirthday}
                        />
                      </ConfigProvider>
                    </Form.Item>
                    <Form.Item
                      label={"Email"}
                      name={"email"}
                      rules={[{ type: "email" }]}
                    >
                      <Input type="email" />
                    </Form.Item>
                    {/* <Form.Item label={"Bio"} name={"bio"}>
                      <Input.TextArea style={{ background: "black" }} />
                    </Form.Item> */}

                    <Flex>
                      <Button
                        type="primary"
                        className="button-border"
                        style={{ marginLeft: 40, width: 173, height: 48 }}
                        htmlType={"submit"}
                      >
                        Atualizar Perfil
                      </Button>
                      <Popconfirm
                        title="Desconectar?"
                        description="Certeza que deseja desconectar?"
                        onConfirm={confirm}
                        onCancel={cancel}
                        cancelButtonProps={{ style: { backgroundColor: "white", color: "black" } }}
                        okText="Sim"
                        cancelText="Não"
                      >
                        <Button
                          id="button-desconectar"
                          type="primary"
                          className="button-border"
                          style={{ marginLeft: 40, width: 173, height: 48 }}
                          onClick={() => {
                          }}
                        >
                          Desconectar
                        </Button>
                      </Popconfirm>

                    </Flex>
                  </Flex>
                  <Flex vertical style={{ width: "50%" }}>
                    <h3 style={{ color: "white" }}>As suas redes sociais</h3>
                    <Form.Item label="Instagram" name={"instagram_id"}>
                      <Input />
                    </Form.Item>
                    <Form.Item label={"TikTok"} name={"tiktok_id"}>
                      <Input />
                    </Form.Item>
                    <Form.Item label={"Twitch"} name={"twitch_id"}>
                      <Input />
                    </Form.Item>

                    <Form.Item label={"Discord"} name={"discord_id"}>
                      <Input />
                    </Form.Item>

                    <Form.Item label={"Twiter"} name={"twitter_id"}>
                      <Input />
                    </Form.Item>
                    {/* <Button
                      type="primary"
                      htmlType="submit"
                      style={{
                        width: 240,
                        backgroundColor: "#B3122A",
                        borderRadius: 30,
                        marginTop: 16,
                        height: 40,
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      <img
                        src="/images/twitter.png"
                        style={{
                          maxWidth: 24,
                          maxHeight: 24,
                          marginRight: 8,
                        }}
                      />
                      Conectar Twitter
                    </Button> */}
                  </Flex>
                </Form>
              </Flex>
            </ConfigProvider>
          </Flex>
        </Flex>
      ) : (
        <Flex
          vertical
          align="center"
          justify="center"
          style={{
            width: "90vw",
            justifySelf: "center",
            alignSelf: "center",
          }}
        >
          <Typography.Title
            style={{
              fontFamily: "Montserrat",
              fontWeight: 700,
              fontSize: "16px",
              lineHeight: "28px",
              textAlign: "center",
              color: "rgb(255, 255, 255)",
            }}
          >
            Escolha sua capa de perfil
          </Typography.Title>
          <Flex
            vertical
            gap={20}
            style={{ marginBottom: "15%", width: "80vw" }}
          >
            {backgroundPhotos.map((photo, index) => (
              <Image
                key={index}
                preview={false}
                src={photo}
                onClick={() => handleSelectBackground(photo)}
                style={{
                  width: "100%",
                  borderRadius: 12,
                  border: account?.background_photo === photo ? "2px solid red" : "none",
                }}
              />
            ))}
          </Flex>
          <Flex gap={50} vertical style={{ width: "100%" }}>
            <Flex vertical gap={30} flex={1} style={{}}>
              <Image
                src={ImageProfile ? ImageProfile : account.photo_profile}
                style={{
                  height: 300,
                  objectFit: "cover",
                  borderRadius: "12px",
                }}
              ></Image>
              <Upload accept=".jpg , .png , .jpge" {...props} maxCount={1} showUploadList={false}>
                <Button
                  type="primary"
                  loading={loadingImage}
                  className="button-border"
                  style={{ width: "100%", height: 48 }}
                >
                  Atualizar foto
                </Button>
              </Upload>
            </Flex>
            <ConfigProvider
              theme={{
                components: {
                  Input: {
                    colorTextPlaceholder: colors.GrayscaleSlot1,
                    colorBorder: "#37363B",
                    hoverBorderColor: "#37363B",
                    activeBorderColor: "red",
                    colorText: colors.GrayscaleSlot3,
                  },
                  Form: {
                    labelColor: colors.GrayscaleSlot3,
                  },
                  Radio: {
                    buttonSolidCheckedActiveBg: "red",
                    buttonSolidCheckedBg: "red",
                    buttonSolidCheckedHoverBg: "red",
                    buttonCheckedBg: "red",
                    buttonBg: "red",
                  },
                },
                token: {
                  colorPrimary: "red",
                  colorText: "white",
                },
              }}
            >
              <Flex
                gap={30}
                flex={4}
                justify={"center"}
                vertical
                style={{ width: "100%" }}
              >
                <Form
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  form={form}
                  validateMessages={{
                    types: {
                      email: "${label} is not a valid email!",
                    },
                  }}
                  layout={"vertical"}
                  style={{
                    width: "100%",
                  }}
                >
                  <Flex
                    justify="center"
                    vertical
                    gap={20}
                    style={{ width: "100%" }}
                  >
                    <Form.Item label="Nome de Perfil" name={"name"}>
                      <Input />
                    </Form.Item>

                    <Form.Item label="Seleciona o teu genero" name={"genre"}>
                      <Radio.Group>
                        <Radio value={"male"}>Homem</Radio>
                        <Radio value={"female"}>Mulher</Radio>
                        <Radio value={"non-binary"}>Não binário</Radio>
                        <Radio value={"other"}>Outro</Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item
                      label={"Data de Nascimento"}
                      name="birthday"
                      style={{ alignSelf: "flex-start" }}
                    >
                      <ConfigProvider
                        theme={{
                          token: {
                            colorBgContainer: "black",
                            colorBgElevated: "#37363B",
                            colorIcon: "white",
                            colorLink: "red",
                            colorTextDescription: "white",
                            colorTextHeading: "white",
                            colorTextDisabled: "white",
                            colorTextPlaceholder: "white",
                          },
                        }}
                      >
                        <DatePicker
                          placeholder={
                            dateString ? dateString : account.birthday
                          }
                          format={"DD/MM/YYYY"}
                          onChange={handleBirthday}
                        />
                      </ConfigProvider>
                    </Form.Item>
                    <Form.Item
                      label={"Email"}
                      name={"email"}
                      rules={[{ type: "email" }]}
                    >
                      <Input type="email" />
                    </Form.Item>
                    {/* <Form.Item label={"Bio"} name={"bio"}>
                      <Input.TextArea style={{ background: "black" }} />
                    </Form.Item> */}
                  </Flex>
                  <Flex vertical style={{ width: "100%" }}>
                    <Form.Item label="Instagram" name={"instagram_id"}>
                      <Input />
                    </Form.Item>
                    <Form.Item label={"TikTok"} name={"tiktok_id"}>
                      <Input />
                    </Form.Item>
                    <Form.Item label={"Twitch"} name={"twitch_id"}>
                      <Input />
                    </Form.Item>

                    <Form.Item label={"Discord"} name={"discord_id"}>
                      <Input />
                    </Form.Item>

                    <Form.Item label={"Twiter"} name={"twitter_id"}>
                      <Input />
                    </Form.Item>
                    {/* <Button
                      type="primary"
                      htmlType="submit"
                      style={{
                        width: "100%",
                        backgroundColor: "#B3122A",
                        borderRadius: 30,
                        marginTop: 16,
                        height: 40,
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                      }}
                    >
                      <img
                        src="/images/twitter.png"
                        style={{
                          maxWidth: 24,
                          maxHeight: 24,
                          marginRight: 8,
                        }}
                      />
                      Conectar Twitter
                    </Button> */}
                    <br></br>
                    <br></br>
                    <Flex vertical style={{ justifyContent: "center", alignItems: "center", gap: 10 }}>
                      <Button
                        type="primary"
                        className="button-border"
                        style={{ width: 173, height: 48 }}
                        htmlType={"submit"}
                      >
                        Atualizar Perfil
                      </Button>
                      <Popconfirm
                        title="Desconectar?"
                        description="Certeza que deseja desconectar?"
                        onConfirm={confirm}
                        onCancel={cancel}
                        cancelButtonProps={{ style: { backgroundColor: "white", color: "black" } }}
                        okText="Sim"
                        cancelText="Não"
                      >
                        <Button
                          id="button-desconectar"
                          type="primary"
                          className="button-border"
                          style={{ width: 173, height: 48 }}
                          onClick={() => {
                          }}
                        >
                          Desconectar
                        </Button>
                      </Popconfirm>
                    </Flex>
                  </Flex>
                </Form>
              </Flex>
            </ConfigProvider>
          </Flex>
        </Flex>
      )}
    </>
  );
}
