import React from "react";

export default function ImageRedCoinSvg({ width, height, style }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="37" height="36" viewBox="0 0 37 36" fill="none"
            style={{
                width: width ? width : 30,
                height: height ? height : 30,
                ...style,
            }}>
            <path d="M15.0383 32.826C22.8788 34.8897 30.9801 30.1165 33.1439 22.184C35.3077 14.2515 30.7215 6.13892 22.8809 4.07524C15.0403 2.01157 6.93904 6.78475 4.77522 14.7172C2.6114 22.6497 7.19768 30.7623 15.0383 32.826Z" fill="#B3122A" stroke="#B3122A" stroke-width="0.917761" />
            <path d="M14.1203 31.9246C21.9609 33.9883 30.0621 29.2151 32.2259 21.2826C34.3898 13.3502 29.8035 5.23755 21.9629 3.17388C14.1223 1.1102 6.02107 5.88338 3.85725 13.8159C1.69344 21.7483 6.27972 29.8609 14.1203 31.9246Z" fill="#FE304E" stroke="#B3122A" stroke-width="0.917761" />
            <path d="M14.8313 29.3124C21.2062 30.9903 27.7955 27.0693 29.5649 20.5828C31.3343 14.0962 27.6236 7.46429 21.2487 5.78639C14.8739 4.10849 8.28456 8.02943 6.51517 14.516C4.74577 21.0025 8.45645 27.6345 14.8313 29.3124Z" fill="#CA1B35" stroke="#B3122A" stroke-width="0.917761" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M26.5567 9.52131C25.2962 8.41832 23.7719 7.58476 22.0454 7.13032C15.9254 5.51953 9.58173 9.28199 7.8763 15.534C6.7756 19.5692 7.87604 23.6552 10.4402 26.4785C7.23291 23.6719 5.73405 19.1208 6.95834 14.6326C8.66376 8.38062 15.0075 4.61816 21.1274 6.22896C23.2974 6.80012 25.1479 7.97017 26.5567 9.52131Z" fill="#FF5E75" />
            <path d="M23.0001 14.1926C22.2505 13.0556 21.399 12.3869 19.4919 11.9861C17.5848 11.5853 15.5247 11.8871 15.0657 13.9945C14.6067 16.102 16.3608 17.2053 18.2679 17.6061C20.175 18.0068 21.9291 19.1101 21.4701 21.2176C21.0111 23.3251 18.951 23.6268 17.0439 23.226C15.1368 22.8252 14.0185 21.8556 13.5358 21.0195" stroke="#FE304E" stroke-width="3.67104" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M17.0449 23.2256L16.4689 25.8703" stroke="#FE304E" stroke-width="3.67104" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M17.0449 23.2256L16.4689 25.8703" stroke="white" stroke-width="1.37664" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M20.0293 9.2334L19.4533 11.8781" stroke="#FE304E" stroke-width="3.67104" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M20.0293 9.2334L19.4533 11.8781" stroke="white" stroke-width="1.37664" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M23.0001 14.1926C22.2505 13.0556 21.399 12.3869 19.4919 11.9861C17.5848 11.5853 15.5247 11.8871 15.0657 13.9945C14.6067 16.102 16.3608 17.2053 18.2679 17.6061C20.175 18.0068 21.9291 19.1101 21.4701 21.2176C21.0111 23.3251 18.951 23.6268 17.0439 23.226C15.1368 22.8252 14.0185 21.8556 13.5358 21.0195" stroke="white" stroke-width="1.37664" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M14.8281 4.51281C10.5657 5.66842 7.13231 8.78709 5.5995 12.8164" stroke="#FF5E75" stroke-width="0.917761" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M25.1953 28.8175C26.8136 27.7606 28.1912 26.3762 29.2301 24.7607" stroke="#FF5E75" stroke-width="0.917761" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}
