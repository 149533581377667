import React, { useEffect } from 'react'
import HeaderAfterMenu from "../../components/organisms/HeaderAfterMenu/HeaderAfterMenu";
import MenuDefaultClient from "../../components/organisms/Menu/Menu";
import Footer from "../../components/organisms/Footer.js/Footer";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Flex, Space } from 'antd';
import TextBoldH4 from '../../components/atomos/TextBoldH4';
import StepByStepUseCoins from '../../components/organisms/StepByStepUseCoins/StepByStepUseCoins';
import TextSemiboldH4 from '../../components/atomos/TextSemiboldH4';
import ProductsToRescue from '../../components/organisms/ProductsToRescue/ProductsToRescue';
import TextBoldH2 from '../../components/atomos/TextBoldH2';
import ImageStepByStep from '../../components/atomos/ImageStepByStep';
import TextRegularH6 from '../../components/atomos/TextRegularH6';

export default function UseRedCoins() {
    const location = useLocation();
    const account = useSelector(state => state.account);
    const RedCoinsDisponible = new Intl.NumberFormat('pt-br').format(account.red_coins?.toFixed(0));

    const { animation } = useParams();

    useEffect(() => {
        if (animation) {
            // setTimeout(() => {
            //     var element = document.getElementById("flex-title-product-to-rescue");
            //     element?.scrollIntoView({ behavior: "auto", block: "center", inline: "center" });
            // }, 1500);
        }
    }, []);

    const itemsBreadCrumb = [
        {
            title: "Home",
            path: "/home"
        },
        {
            title: "Use RED Coins",
        }];
    return (
        <Flex vertical>
            <MenuDefaultClient page={location} />
            <HeaderAfterMenu title={"Usar RED Coins"} items={itemsBreadCrumb} />
            {/* <Flex vertical align="center" justify="center" style={{ width: "90%", alignSelf: "center", gap: "20px", }}>
                <TextBoldH4 text={"Como funciona passo a passo?"} />
                <Flex>
                    <div>
                        Vamos te explicar como você pode usar suas <span style={{ color: "#FF0000" }}>RED Coins </span>
                        para resgatar produtos da nossa loja oficial
                    </div>
                </Flex>
            </Flex> */}
            {/* <StepByStepUseCoins /> */}
            <Flex justify='center' align='center' className='Box-Step-By-Step-one-text' gap={35} >
                {/* <Space style={{ maxWidth: "20%" }}>
                    <TextBoldH2 text={"01"} />
                </Space> */}
                <Space style={{ display: "flex", flexDirection: "column", maxWidth: "80%" }}>
                    <TextBoldH4 text={`Você tem ${RedCoinsDisponible} RED Coins disponíveis pra usar`} textAlign={"left"} />
                    <TextRegularH6 text={"Com essa quantidade você já pode resgatar produtos em nossa loja oficial"} />
                </Space>
            </Flex>
            <Flex vertical align="center" justify="center" style={{ maxWidth: "50vw", alignSelf: "center", gap: "20px", marginTop: "40px" }}>
                <TextSemiboldH4 text={"Produtos que você pode resgatar agora"} textAlign={"center"} />
            </Flex>
            <ProductsToRescue />
            <Flex className='Box-Step-By-Step-two' justify='center' align='center' style={{ maxWidth: "80vw", alignSelf: "center", gap: "20px", marginTop: "40px" }}>
                {/* <Flex className='Box-Step-By-Step-two-image' justify='center' align='center'>
                    <ImageStepByStep imagePath="/images/gamer.png" />
                </Flex> */}
                <Flex justify='center' align='center' className='Box-Step-By-Step-two-text' gap={35}>
                    {/* <Space style={{ maxWidth: "20%" }}>
                        <TextBoldH2 text={"03"} />
                    </Space> */}
                    <Space style={{ display: "flex", flexDirection: "column", maxWidth: "80%", marginBottom: 10 }}>
                        <TextBoldH4 text={`Por fim é só enviar o pedido de resgate dos seus produtos`} textAlign={"left"} />
                        <TextRegularH6 text={"Você receberá um email com as instruções para adquirir seus produtos escolhidos em nossa loja"} />
                    </Space>
                </Flex>
            </Flex>
            <Footer />
        </Flex >
    )
}
