import React, { useEffect, useRef, useState } from "react";
import CardManagerMissionsOrganisms from "../../organisms/CardManagerMissionsOrganisms/CardManagerMissionsOrganisms";
import {
  Button,
  ConfigProvider,
  DatePicker,
  Flex,
  FloatButton,
  Form,
  Input,
  InputNumber,
  List,
  Modal,
  Popconfirm,
  Segmented,
  Select,
  TimePicker,
  message,
  notification,
} from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { MissionService, OpenAPI } from "red-canids-client-module/client-web";
import UploadImages from "../../organisms/UploadImages/Upload";
import TextBoldH6 from "../../atomos/TextBoldH6";
import TextBoldParagraph from "../../atomos/TextBoldParagraph";
import TextBoldH2 from "../../atomos/TextBoldH2";
import TextBoldH4 from "../../atomos/TextBoldH4";
import dayjs from "dayjs";

export default function ManagerMissionsTemplate() {
  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  const [productSelected, setProductSelected] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showVisible, setShowVisible] = useState(true);
  const [typeMission, setTypeMIssion] = useState(
    form.getFieldValue("type_mission")
  );
  const [images, setImages] = useState("");
  const [fileList, setFileList] = useState([]);
  const [imageResponse, setImageResponse] = useState(null);
  const [responseForm, setResponseForm] = useState(null);
  const [loadingForm, setLoadingForm] = useState(false);
  const [listMissions, setListMissions] = useState([]);
  const [errorImage, setErrorImage] = useState(false);
  const [timePickerVisible, setTimePickerVisible] = useState(false);
  const [dateString, setDateString] = useState("");
  const [timeString, setTimeString] = useState(dayjs("00:00:00", "HH:mm:ss"));
  const [isEdit, setIsEdit] = useState(false);
  const [submittable, setSubmittable] = useState(false);
  const [videoUplaoded, setVideoUplaoded] = useState(false);
  const [selectedTime, setSelectedTime] = useState(null);
  const values = Form.useWatch([], form);

  const colors = require("../../../styles/Colors.json");

  // Ordena as missões em ordem decrescente em quantidades de ponto
  var sortedData = listMissions.slice().sort((a, b) => b.points - a.points);
  var filteredData = showVisible
    ? sortedData.filter((mission) => mission.is_enabled)
    : sortedData.filter((mission) => !mission.is_enabled);

  // Atualiza a posição delas com base na "sortedData"
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newData = [...listMissions];
    const [removed] = newData.splice(oldIndex, 1);
    newData.splice(newIndex, 0, removed);
    setListMissions(newData);
  };

  useEffect(() => {
    form.setFieldsValue({ time_mission: dayjs("00:00:00", "HH:mm:ss") });
    MissionService.returnAllMission().then((res) => {
      var listMissionByDate = res.data.sort(function (a, b) {
        return (
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
      });
      setListMissions(listMissionByDate);
    });

    return () => {
      setListMissions([]);
    };
  }, []);
  useEffect(() => {
    form
      .validateFields({
        basic: true,
      })
      .then(
        () => {
          if (videoUplaoded || isEdit) {
            setSubmittable(true);
          }
        },
        () => {
          setSubmittable(false);
        }
      );

    return () => {
      setSubmittable(false);
    };
  }, [values, videoUplaoded, isEdit]);
  useEffect(() => {
    if (responseForm === false) {
      api.error({
        message: "Erro ao criar missão",
        description: "Por favor verifique os dados inseridos",
      });
    } else if (responseForm === true) {
      api.success({
        message: "Missão criada com sucesso",
        description: "Missão criada com sucesso pode conferir",
      });
    }
  }, [responseForm]);
  useEffect(() => {
    if (fileList.length <= 0) {
      setErrorImage(true);
    } else {
      setErrorImage(false);
    }
  }, [fileList]);
  useEffect(() => {
    if (images.length > 0) {
      setImageResponse(null);
    }
  }, [images]);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  };

  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 20, offset: 4 },
    },
  };

  const showEditProduct = () => {
    setIsEdit(!isEdit);
    setIsModalOpen(true);
  };

  const handleDataReceivedImages = (values) => {
    setImages(values);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setIsEdit(false);
  };

  const handleCancel = () => {
    if (isEdit) {
      window.location.reload();
    } else {
      setIsModalOpen(false);
      setTypeMIssion("");
      setFileList([]);
      setResponseForm(null);
      setImageResponse(null);
      form.resetFields();
    }
  };
  const onChangeDateExp = (value, dateString) => {
    setDateString(dateString);
  };
  const onChangeTime = (value, dateString) => {
    setTimeString(dateString);
    setSelectedTime(dateString);
  };
  const onChangeTypeMission = (value) => {
    setTypeMIssion(value);
  };
  function callBackClickEditProduct(value) {
    setProductSelected(value);
    showEditProduct();
  }
  useEffect(() => {
    const setInitialFormValues = () => {
      setDateString(productSelected.expiration_date);
      setTimeString(productSelected.time_mission);
      form.setFieldsValue({
        name_mission: productSelected.name_mission,
        description_mission: productSelected.description_mission,
        expiration_date: dayjs(productSelected.expiration_date),
        classification: productSelected.classification,
        points: productSelected.points,
        type_points: productSelected.type_points,
        type_mission: productSelected.type_mission,
        time_mission:
          dayjs(productSelected.time_mission, "HH:mm:ss") ||
          dayjs("00:00:00", "HH:mm:ss"),
        video_source: productSelected.video_source,
        device: productSelected.device,
      });
      if (productSelected.type_mission === "video") {
        form.setFieldsValue({
          videos_mission: productSelected.videos_mission,
        });
        setTypeMIssion("video");
      } else if (productSelected.type_mission === "finder") {
        form.setFieldsValue({
          url_mission: productSelected.url_mission,
          code_mission: productSelected.code_mission,
        });
        setTypeMIssion("finder");
      } else if (productSelected.type_mission === "question") {
        console.log(productSelected.questions);
        form.setFieldsValue({
          questions: productSelected.questions,
        });
        setTypeMIssion("question");
      }
    };
    if (Object.keys(productSelected).length > 0) {
      setInitialFormValues();
    }
  }, [productSelected, form]);

  const onFinish = (values) => {
    //setLoadingForm(true);
    // OpenAPI.BASE = "http://localhost:8000";
    if (fileList.length > 0) {
      if (isEdit) {
        console.log(values.questions);
        MissionService.updateMissionById(productSelected._id.$oid, {
          classification: values.classification,
          code_mission: values.code_mission ? String(values.code_mission) : "",
          description_mission: values.description_mission,
          expiration_date: dateString,
          image_mission: fileList[0].url ? fileList[0].url : images,
          name_mission: values.name_mission,
          points: values.points,
          time_mission: timeString,
          type_mission: values.type_mission,
          type_points: values.type_points,
          url_mission: values.url_mission ? values.url_mission : "",
          video_source: values.video_source,
          videos_mission: values.videos_mission ? values.videos_mission : [],
          device: values.device,
          questions: values.questions ? values.questions : [],
        })
          .then((res) => {
            setLoadingForm(false);
            window.location.reload();
          })
          .catch((err) => {
            setLoadingForm(false);
            console.error(err);
          });
      } else {
        console.log(values);
        MissionService.crateMission({
          classification: values.classification,
          code_mission: values.code_mission ? String(values.code_mission) : "",
          description_mission: values.description_mission,
          expiration_date: dateString,
          image_mission: images ? images : "",
          name_mission: values.name_mission,
          points: values.points,
          type_mission: values.type_mission,
          url_mission: values.url_mission ? values.url_mission : "",
          type_points: "red_coins",
          video_source: values.video_source,
          videos_mission: values.videos_mission ? values.videos_mission : [],
          time_mission: timeString,
          device: values.device,
          questions: values.questions ? values.questions : [],
        })
          .then((res) => {
            setResponseForm(true);
            setIsModalOpen(false);
            setLoadingForm(false);
            setTypeMIssion("");
            setFileList([]);
            form.resetFields();
            MissionService.returnAllMission().then((res) => {
              var listMissionByDate = res.data.sort(function (a, b) {
                return (
                  new Date(b.created_at).getTime() -
                  new Date(a.created_at).getTime()
                );
              });
              setListMissions(listMissionByDate);
              setResponseForm(null);
            });
          })
          .catch((err) => {
            console.error(err);
            setResponseForm(false);
            setLoadingForm(false);
          });
      }
    } else {
      setErrorImage(true);
      onFinishFailed();
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setResponseForm(false);
    if (fileList.length === 0) {
      setResponseForm(false);
      setLoadingForm(false);
      setImageResponse(true);
    }
  };

  const filteredPurchases = showVisible
    ? listMissions.filter((mission) => mission.is_enabled)
    : listMissions;

  return (
    <>
      {contextHolder}
      <ConfigProvider
        theme={{
          components: {
            Modal: {
              contentBg: "white",
            },
            Segmented: {
              itemActiveBg: "white",
              itemSelectedBg: "#ffffff",

              colorBgLayout: "#f5f5f5",
            },
          },
        }}
      >
        <Flex
          justify={"end"}
          align={"flex-end"}
          style={{ marginRight: "3%", marginBottom: "2%" }}
        >
          <Segmented
            defaultValue={"Missões Ativas"}
            options={[
              { label: "Missões Ativas", value: "Missões Ativas" },
              { label: "Missões Arquivadas", value: "Missões Arquivadas" },
            ]}
            onChange={() =>
              setShowVisible((prevShowVisible) => !prevShowVisible)
            }
          />
        </Flex>
      </ConfigProvider>

      <List
        itemLayout="horizontal"
        grid={{
          gutter: 1,
          xs: 1,
          sm: 1,
          md: 2,
          lg: 3,
          xl: 4,
          xxl: 6,
        }}
        pagination={{
          pageSize: 12,
        }}
        dataSource={filteredData}
        renderItem={(mission) => (
          <List.Item>
            <CardManagerMissionsOrganisms
              allValues={mission}
              key={mission._id.$oid}
              name_mission={mission.name_mission}
              description_mission={mission.description_mission}
              image_mission={mission.image_mission}
              expiration_date={mission.expiration_date}
              classification={mission.classification}
              points={mission.points}
              type_points={mission.type_points}
              type_mission={mission.type_mission}
              time_mission={mission.time_mission}
              url_mission={mission.url_mission}
              video_source={mission.video_source}
              videos_mission={mission.videos_mission}
              code_mission={mission.code_mission}
              created_at={mission.created_at}
              updated_at={mission.updated_at}
              callBackClickEditProduct={callBackClickEditProduct}
              setProductSelected={setProductSelected}
              showVisible={showVisible}
            />
          </List.Item>
        )}
        sortable={{ onChange: onSortEnd }}
      />
      <ConfigProvider
        theme={{
          components: {
            Modal: {
              contentBg: "white",
            },
            Segmented: {
              itemActiveBg: "white",
              itemSelectedBg: "#ffffff",

              colorBgLayout: "#f5f5f5",
            },
          },
        }}
      >
        <Modal
          maskClosable={false}
          mask
          footer={false}
          title={isEdit ? "Editar missão" : "Criar nova missão"}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          width={"80%"}
        >
          <div style={{ minHeight: "60vh" }}>
            <ConfigProvider
              theme={{
                components: {
                  Input: {
                    colorTextPlaceholder: colors.GrayscaleSlot1,
                    hoverBorderColor: colors.BaseSlot4,
                    activeBorderColor: colors.BaseSlot4,
                    colorText: colors.GrayscaleSlot5,
                  },
                },
              }}
            >
              <UploadImages
                onDataReceived={handleDataReceivedImages}
                setImagesList={setImages}
                fileList={fileList}
                setFileList={setFileList}
                isEdit={isEdit}
                urlOnEdit={productSelected.image_mission}
                setVideoUplaoded={setVideoUplaoded}
              />
              {imageResponse ? (
                <p style={{ color: "red" }}>
                  <strong>Falta inserir imagem</strong>
                </p>
              ) : (
                ""
              )}

              <Form
                name="basic"
                labelCol={{
                  span: 5,
                }}
                wrapperCol={{
                  span: 26,
                }}
                labelAlign="left"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                initialValues={{ time_mission: dayjs("00:00:00") }}
                form={form}
              >
                {form.getFieldValue("name_mission")?.includes("FARMAR") && (
                  <Form.Item
                    label="Atualizar ID"
                    tooltip="Você pode atualizar od ID da missão sempre que preciso, pois se o usuario já tiver completado a missão, ele não poderá completar novamente."
                  >
                    <Popconfirm
                      onConfirm={() => {
                        MissionService.replicateMissionFarmerById(
                          productSelected._id.$oid
                        )
                          .then((res) => {
                            window.location.reload();
                          })
                          .catch((err) => {
                            console.error(err);
                          });
                      }}
                      onCancel={() => {
                        console.log("Cancelar");
                      }}
                      okText="Sim"
                      cancelText="Não"
                      title="Deseja atualizar o ID da missão?"
                      description="Essa ação não poderá ser desfeita."
                    >
                      <Button style={{ width: 300 }} type="primary">
                        Resetar missão
                      </Button>
                    </Popconfirm>
                  </Form.Item>
                )}
                <Form.Item
                  label="Nome da missão"
                  name="name_mission"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, preencha o nome da missão",
                    },
                  ]}
                >
                  <Input placeholder="Assista nosso vídeo no youtube" />
                </Form.Item>

                <Form.Item
                  label="Descrição"
                  name="description_mission"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, preencha a descrição da missão",
                    },
                  ]}
                >
                  <TextArea
                    maxLength={3000}
                    placeholder="Descreva a missão..."
                  />
                </Form.Item>
                <Form.Item
                  label="Data de expiração da Missão"
                  name="expiration_date"
                  rules={[
                    {
                      required: true,
                      message:
                        "Por favor, escolha uma data de expiração da missão",
                    },
                  ]}
                >
                  <DatePicker
                    style={{ width: 200 }}
                    showTime
                    onChange={onChangeDateExp}
                    placeholder="Data"
                  />
                </Form.Item>
                <Form.Item
                  label="Tempo da Missão"
                  name="time_mission"
                  rules={[
                    {
                      required: true,
                      message:
                        "Por favor, escolha uma data de expiração da missão",
                    },
                  ]}
                >
                  <TimePicker
                    placeholder="Tempo"
                    //onSelect={()=>{setTimePickerVisible(!timePickerVisible)}}
                    onChange={onChangeTime}
                    //value={timeString}
                    style={{ width: 200 }}
                    showNow={false}
                    //onOpenChange={(open) => setTimePickerVisible(open)}
                    //open={timePickerVisible}
                  />
                </Form.Item>
                <Form.Item
                  label="Classificação"
                  name="classification"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, classifique a missão",
                    },
                  ]}
                >
                  <Segmented
                    width={200}
                    defaultValue={0}
                    options={[
                      { label: "Normal", value: "normal" },
                      { label: "Raro", value: "rare" },
                    ]}
                  />
                </Form.Item>
                <Form.Item
                  label="Dispositivo"
                  name="device"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, escolha o dispositivo da missão",
                    },
                  ]}
                >
                  <Segmented
                    width={200}
                    defaultValue={0}
                    options={[
                      { label: "Todos", value: "all" },
                      { label: "Mobile", value: "mobile" },
                      { label: "Desktop", value: "desktop" },
                    ]}
                  />
                </Form.Item>
                <Form.Item
                  label="Pontos da Missão"
                  name="points"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, adicione pontos para a missão",
                    },
                  ]}
                >
                  <Input placeholder="Pontos" style={{ width: 200 }} />
                </Form.Item>

                <Form.Item
                  label="Tipo de Missão"
                  name="type_mission"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, escolha o tipo de missão",
                    },
                  ]}
                >
                  <Segmented
                    defaultValue={0}
                    options={[
                      { label: "Video", value: "video" },
                      { label: "Tracking", value: "finder" },
                      { label: "Question", value: "question" },
                    ]}
                    onChange={onChangeTypeMission}
                  />
                </Form.Item>
                {typeMission === "question" && (
                  <Form.List
                    name="questions"
                    rules={[
                      {
                        required: true,
                        message: "Por favor, preencha uma pergunta",
                      },
                    ]}
                  >
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field, index) => (
                          <>
                            <Form.Item
                              {...(index === 0
                                ? formItemLayout
                                : formItemLayoutWithOutLabel)}
                              label={index === 0 ? "Question" : ""}
                              required={true}
                              key={field.key}
                            >
                              <Form.Item
                                {...field}
                                validateTrigger={["onChange", "onBlur"]}
                                rules={[
                                  {
                                    required: true,
                                    whitespace: true,
                                    message:
                                      "Por favor preencha ou apague a pergunta",
                                  },
                                ]}
                                noStyle
                                name={[field.name, "question"]}
                              >
                                <Input
                                  placeholder={"Pergunta " + (index + 1)}
                                  style={{ width: "60%" }}
                                />
                              </Form.Item>
                              <Form.List
                                {...field}
                                validateTrigger={["onChange", "onBlur"]}
                                name={[field.name, "answer"]}
                              >
                                {(fields, { add, remove }) => (
                                  <>
                                    {fields.map((field, index) => (
                                      <>
                                        <Form.Item
                                          {...field}
                                          validateTrigger={[
                                            "onChange",
                                            "onBlur",
                                          ]}
                                          rules={[
                                            {
                                              required: true,
                                              whitespace: true,
                                              message:
                                                "Por favor preencha a resposta",
                                            },
                                          ]}
                                          noStyle
                                          
                                        >
                                          <Input
                                            placeholder={
                                              "Resposta " + (index + 1)
                                            }
                                            style={{
                                              width: "60%",
                                              marginTop: "1rem",
                                            }}
                                          />
                                        </Form.Item>

                                        <MinusCircleOutlined
                                          className="dynamic-delete-button"
                                          onClick={() => remove(field.name)}
                                        />
                                      </>
                                    ))}
                                    <Form.Item>
                                      <Button
                                        type="dashed"
                                        onClick={() => add()}
                                        style={{
                                          width: "30%",
                                          marginTop: "1rem",
                                        }}
                                        icon={<PlusOutlined />}
                                      >
                                        Adicionar Resposta
                                      </Button>
                                    </Form.Item>
                                  </>
                                )}
                              </Form.List>
                              <Button
                                className="dynamic-delete-button"
                                type="primary"
                                danger
                                onClick={() => remove(field.name)}
                              >
                                {"Apagar Pergunta " + (index + 1)}
                              </Button>
                            </Form.Item>
                          </>
                        ))}
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            style={{ width: "60%" }}
                            icon={<PlusOutlined />}
                          >
                            Adicionar Pergunta
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                )}
                {typeMission === "video" && (
                  <Form.List
                    name="videos_mission"
                    rules={[
                      {
                        required: true,
                        message: "Por favor, escolha um vídeo da missão",
                      },
                    ]}
                  >
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field, index) => (
                          <Form.Item
                            {...(index === 0
                              ? formItemLayout
                              : formItemLayoutWithOutLabel)}
                            label={index === 0 ? "Vídeos" : ""}
                            required={true}
                            key={field.key}
                          >
                            <Form.Item
                              {...field}
                              validateTrigger={["onChange", "onBlur"]}
                              rules={[
                                {
                                  required: true,
                                  whitespace: true,
                                  message:
                                    "Por favor preencha ou apague o URL do vídeo.",
                                },
                              ]}
                              noStyle
                            >
                              <Input
                                placeholder="URL do Vídeo"
                                style={{ width: "60%" }}
                              />
                            </Form.Item>
                            <MinusCircleOutlined
                              className="dynamic-delete-button"
                              onClick={() => remove(field.name)}
                            />
                          </Form.Item>
                        ))}
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            style={{ width: "60%" }}
                            icon={<PlusOutlined />}
                          >
                            Adicionar Vídeo
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                )}

                {typeMission === "finder" && (
                  <>
                    <Form.Item
                      label="URL do Tracking"
                      name="url_mission"
                      rules={[
                        {
                          required: true,
                          message: "Por favor, preencha o URL da missão",
                        },
                      ]}
                    >
                      <Input placeholder="URL da missão" />
                    </Form.Item>
                    <Form.Item
                      label="Código"
                      name="code_mission"
                      rules={[
                        {
                          required: true,
                          message: "Por favor, preencha o Código da missão",
                        },
                      ]}
                    >
                      <Input placeholder="Código da missão" />
                    </Form.Item>
                  </>
                )}
                <Form.Item
                  label="Fonte do Video"
                  name="video_source"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Por favor, escolha a fonte do video da missão",
                    },
                  ]}
                >
                  <Select
                    placeholder="Fonte do video da missão"
                    options={[
                      {
                        value: "youtube",
                        label: "Youtube",
                      },
                      {
                        value: "twich",
                        label: "Twich",
                      },
                      {
                        value: "tiktok",
                        label: "Tiktok",
                      },
                      {
                        value: "instagram",
                        label: "Instagram",
                      },
                      {
                        value: "facebook",
                        label: "Facebook",
                      },
                      {
                        value: "x",
                        label: "X",
                      },
                    ]}
                  />
                </Form.Item>

                <Form.Item
                  wrapperCol={{
                    offset: 5,
                    span: 16,
                  }}
                  style={{ justifyContent: "center", display: "flex" }}
                >
                  <Button
                    style={{ width: 300 }}
                    type="primary"
                    htmlType="submit"
                    loading={loadingForm}
                    disabled={!submittable}
                  >
                    {isEdit ? (
                      <TextBoldH6 text={"EDITAR MISSÃO"} />
                    ) : (
                      <TextBoldH6 text={"CRIAR MISSÃO"} />
                    )}
                  </Button>
                </Form.Item>
              </Form>
            </ConfigProvider>
          </div>
        </Modal>
      </ConfigProvider>
      <FloatButton
        onClick={() => showModal()}
        type="primary"
        shape="square"
        style={{ right: 200 }}
        icon={<PlusCircleOutlined />}
        tooltip="Adicionar uma nova missão"
      />
    </>
  );
}
