import React from "react";

export default function GoldIcon({ width, height }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : 30}
      height={height ? height : 30}
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M13.7995 2.29262C15 1.04203 17 1.04203 18.2005 2.29263L18.4371 2.5391C19.1846 3.31777 20.2879 3.64343 21.3384 3.39546L21.665 3.31836C23.3452 2.92175 25.0194 3.99733 25.3571 5.69034L25.4358 6.08457C25.6451 7.13384 26.3888 7.99673 27.3958 8.35849L27.7635 8.4906C29.3758 9.06987 30.195 10.8631 29.5773 12.4611L29.4165 12.8768C29.0336 13.8675 29.1935 14.9862 29.8389 15.8299L30.1077 16.1813C31.1464 17.5391 30.8665 19.4853 29.4873 20.4954L29.1468 20.7447C28.2876 21.3741 27.8188 22.406 27.9101 23.4672L27.947 23.8962C28.0945 25.6094 26.7974 27.1058 25.0807 27.2032L24.7227 27.2235C23.6492 27.2843 22.687 27.906 22.1902 28.8596L22.0198 29.1867C21.2198 30.7224 19.3033 31.2849 17.8002 30.4252L17.5144 30.2617C16.5761 29.7251 15.4239 29.7251 14.4856 30.2617L14.1998 30.4252C12.6967 31.2849 10.7802 30.7224 9.98021 29.1867L9.80982 28.8596C9.31303 27.906 8.35084 27.2843 7.27729 27.2235L6.91933 27.2032C5.20256 27.1058 3.90553 25.6094 4.05297 23.8962L4.0899 23.4672C4.18123 22.406 3.71244 21.3741 2.85317 20.7447L2.51272 20.4954C1.13354 19.4853 0.853648 17.5391 1.89228 16.1813L2.16111 15.8299C2.80646 14.9862 2.96645 13.8675 2.58345 12.8768L2.42274 12.4611C1.805 10.8631 2.62416 9.06987 4.2365 8.4906L4.60423 8.35849C5.61116 7.99673 6.35493 7.13384 6.56424 6.08457L6.64288 5.69034C6.9806 3.99733 8.6548 2.92175 10.335 3.31836L10.6616 3.39546C11.7121 3.64343 12.8154 3.31777 13.5629 2.5391L13.7995 2.29262Z"
        fill="url(#paint0_linear_930_15444)"
      />
      <path
        d="M13.7995 2.29262C15 1.04203 17 1.04203 18.2005 2.29263L18.4371 2.5391C19.1846 3.31777 20.2879 3.64343 21.3384 3.39546L21.665 3.31836C23.3452 2.92175 25.0194 3.99733 25.3571 5.69034L25.4358 6.08457C25.6451 7.13384 26.3888 7.99673 27.3958 8.35849L27.7635 8.4906C29.3758 9.06987 30.195 10.8631 29.5773 12.4611L29.4165 12.8768C29.0336 13.8675 29.1935 14.9862 29.8389 15.8299L30.1077 16.1813C31.1464 17.5391 30.8665 19.4853 29.4873 20.4954L29.1468 20.7447C28.2876 21.3741 27.8188 22.406 27.9101 23.4672L27.947 23.8962C28.0945 25.6094 26.7974 27.1058 25.0807 27.2032L24.7227 27.2235C23.6492 27.2843 22.687 27.906 22.1902 28.8596L22.0198 29.1867C21.2198 30.7224 19.3033 31.2849 17.8002 30.4252L17.5144 30.2617C16.5761 29.7251 15.4239 29.7251 14.4856 30.2617L14.1998 30.4252C12.6967 31.2849 10.7802 30.7224 9.98021 29.1867L9.80982 28.8596C9.31303 27.906 8.35084 27.2843 7.27729 27.2235L6.91933 27.2032C5.20256 27.1058 3.90553 25.6094 4.05297 23.8962L4.0899 23.4672C4.18123 22.406 3.71244 21.3741 2.85317 20.7447L2.51272 20.4954C1.13354 19.4853 0.853648 17.5391 1.89228 16.1813L2.16111 15.8299C2.80646 14.9862 2.96645 13.8675 2.58345 12.8768L2.42274 12.4611C1.805 10.8631 2.62416 9.06987 4.2365 8.4906L4.60423 8.35849C5.61116 7.99673 6.35493 7.13384 6.56424 6.08457L6.64288 5.69034C6.9806 3.99733 8.6548 2.92175 10.335 3.31836L10.6616 3.39546C11.7121 3.64343 12.8154 3.31777 13.5629 2.5391L13.7995 2.29262Z"
        fill="url(#paint1_linear_930_15444)"
        fill-opacity="0.1"
      />
      <path
        d="M14.4183 18.4105L12.2882 16.2585C12.1735 16.1424 12.0178 16.0771 11.8555 16.0771C11.6931 16.0771 11.5374 16.1424 11.4227 16.2585C11.1833 16.5004 11.1833 16.8911 11.4227 17.133L13.9886 19.7252C14.228 19.9671 14.6147 19.9671 14.8541 19.7252L21.3485 13.164C21.5879 12.9221 21.5879 12.5314 21.3485 12.2895C21.2339 12.1734 21.0782 12.1082 20.9158 12.1082C20.7534 12.1082 20.5977 12.1734 20.483 12.2895L14.4183 18.4105Z"
        fill="white"
        stroke="#FDFDFD"
        stroke-width="2"
      />
      <defs>
        <linearGradient
          id="paint0_linear_930_15444"
          x1="16"
          y1="0.000244141"
          x2="16"
          y2="32.0002"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#EC9717" />
          <stop offset="1" stop-color="#E3A445" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_930_15444"
          x1="-5.4955"
          y1="-2.49976"
          x2="17.0297"
          y2="29.6462"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0.68" />
        </linearGradient>
      </defs>
    </svg>
  );
}
