import { createSlice } from '@reduxjs/toolkit';

export const profileFeatures = createSlice({
    name: 'profile',
    initialState: {
        nameProfile: "",
        emailProfile: "",
        genreProfile: "",
        backgroundProfile: "",
        twitterAcessTokenProfile: "",
        photoProfile: "",
        birthdayProfile: "",
        bioProfile: "",
        instagramProfile: "",
        tiktokProfile: "",
        twitchProfile: "",
        discordProfile: "",
        twitterProfile: ""
    },
    reducers: {
        updateProfile: (state, action) => {
            // Retorna um novo objeto de estado mesclando os valores atuais e os novos do payload
            return { ...state, ...action.payload };
        },
        updateProfileField: (state, action) => {
            // Extrai os campos 'field' e 'value' do payload da ação
            const { field, value } = action.payload;
            // Retorna um novo objeto de estado com o campo específico atualizado
            return { ...state, [field]: value };
        },
    },
});

export const { updateProfile, updateProfileField } = profileFeatures.actions;

export default profileFeatures.reducer;
