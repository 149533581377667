import React from "react";


export default function TextSemiboldH4({ text, colorText, fontSize , textColor, textAlign}) {
    return (
        <>
            <span
                style={{
                    fontFamily: "Montserrat",
                    fontWeight: 600,
                    fontSize: fontSize == undefined ? 28 : fontSize,
                    lineHeight: "40px",
                    textAlign: textAlign ? textAlign : "center",
                    color: colorText,
                }}>{text}
            </span>
        </>
    )
}