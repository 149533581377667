import React, { useEffect, useState } from "react";
import "./QuestionsMissionTemplate.css";
import MenuDefaultClient from "../../organisms/Menu/Menu";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../organisms/Footer.js/Footer";
import QuestionsForm from "../../organisms/QuestionsForm/QuestionsForm";
import { AccountService } from "red-canids-client-module/client-web";
import { addRedCoins } from "../../../features/account/account";
import { message } from "antd";

export default function QuestionsMissionTemplate() {
  const { id_mission } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const missionSelec = useSelector((state) => state.missionSelected);
  const account = useSelector((state) => state.account);
  const [answers, setAnswers] = useState([]);
  const navigate = useNavigate();

  function onSendAnswers(answers) {
    console.log({
      id_account: account._id.$oid,
      id_mission: missionSelec._id.$oid,
      points: missionSelec.points,
      type_mission: missionSelec.type_mission,
      type_points: missionSelec.type_points,
      questions: answers.questions,
    });
    AccountService.updateMissionCompleted({
      id_account: account._id.$oid,
      id_mission: missionSelec._id.$oid,
      points: missionSelec.points,
      type_mission: missionSelec.type_mission,
      type_points: missionSelec.type_points,
      questions: answers.questions,
    })
      .then((response) => {
        dispatch(addRedCoins(missionSelec.points));
        message.success("Missão completa", 2, () => {
          navigate("/");
        });
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        console.log("finally");
      });
  }

  return (
    <>
      <MenuDefaultClient page={location} />
      <QuestionsForm
        questions={missionSelec.questions}
        onSendAnswers={onSendAnswers}
      />
      <Footer />
    </>
  );
}
