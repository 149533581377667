import { createSlice } from '@reduxjs/toolkit'

export const userFeatures = createSlice({
    name: 'user',
    initialState: "",
    reducers: {
        changeName: (state, action) => {
            state = action.payload
            return state
        },
    },
})

export const { changeName } = userFeatures.actions

export default userFeatures.reducer