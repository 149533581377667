import { Button, ConfigProvider, Flex, Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import TextBoldH2 from "../../atomos/TextBoldH2";

export default function QuestionsForm({ questions, onSendAnswers }) {
  const [questionsForm, setQuestionsForm] = useState(questions);
  const colors = require("../../../styles/Colors.json");
  useEffect(() => {
    console.log(questionsForm);

    return () => {};
  }, []);

  function onFinish(values) {
    onSendAnswers(values);
  }

  return (
    <Flex vertical justify={"center"} align={"center"}>
      <TextBoldH2 text={"Perguntas da Matilha"} />
      <ConfigProvider
        theme={{
          components: {
            Input: {
              colorTextPlaceholder: colors.GrayscaleSlot1,
              colorBorder: "#37363B",
              hoverBorderColor: "#37363B",
              activeBorderColor: "red",
              colorText: colors.GrayscaleSlot3,
            },
            Form: {
              labelColor: colors.GrayscaleSlot3,
            },
            Radio: {
              buttonSolidCheckedActiveBg: "red",
              buttonSolidCheckedBg: "red",
              buttonSolidCheckedHoverBg: "red",
              buttonCheckedBg: "red",
              buttonBg: "red",
            },
            DatePicker: {
              activeBg: "#37363B",
              activeBorderColor: "red",
              hoverBg: "#37363B",
            },
            Select: {
              clearBg: "#37363B",
              selectorBg: "#37363B",
              optionSelectedBg: "red",
              optionActiveBg:"#a4112d"
            },
          },
          token: {
            colorPrimary: "red",
            colorText: "white",
            colorBorder: "#37363B",
            colorBgContainer: "#37363B",
            colorBgElevated:"#37363B",
            colorTextPlaceholder:"white"
          },
        }}
      >
        <Form
          initialValues={{
            questions: questionsForm.map((question, index) => ({
              question: question.question,
              answer: null, // Initially set answer to an empty string
            })),
          }}
          layout="vertical"
          style={{ width: "60%" }}
          onFinish={onFinish}
        >
          <Form.List
            name="questions"
            rules={[
              {
                required: true,
                message: "Por favor, preencha uma pergunta",
              },
            ]}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <>
                    <Form.Item
                      label={index === 0 ? "Question" : ""}
                      required={true}
                      key={field.key}
                      hidden
                    >
                      <Form.Item
                        {...field}
                        validateTrigger={["onChange", "onBlur"]}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: "Por favor preencha ou apague a pergunta",
                          },
                        ]}
                        noStyle
                        name={[field.name, "question"]}
                      >
                        <Input
                          placeholder="Pergunta"
                          style={{ width: "60%" }}
                        />
                      </Form.Item>
                    </Form.Item>
                    <Form.Item
                      required={true}
                      key={field.key}
                      label={questionsForm[field.name]?.question}
                    >
                      <Form.Item
                        validateTrigger={["onChange", "onBlur"]}
                        rules={[
                          {
                            required: true,
                            message: "Por favor preencha ou apague a pergunta",
                          },
                        ]}
                        name={[field.name, "answer"]}
                      >
                        <Select
                          options={questionsForm[field.name]?.answer?.map(
                            (value, index) => ({
                              value: value,
                              label: <span key={index}>{value}</span>,
                            })
                          )}
                          placeholder="Seleciona a tua resposta"
                        />

                        {/* <Select options={[{ value: 'sample', label: <span>sample</span> }]} /> */}
                      </Form.Item>
                    </Form.Item>
                  </>
                ))}
              </>
            )}
          </Form.List>
          <Form.Item>
            <Button
              type="primary"
              className="button-border"
              style={{ width: 173, height: 48 }}
              htmlType={"submit"}
            >
              Completar desafio
            </Button>
          </Form.Item>
        </Form>
      </ConfigProvider>
    </Flex>
  );
}
