import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  ConfigProvider,
  Divider,
  Flex,
  Grid,
  Input,
  List,
  Spin,
  Typography,
} from "antd";
import { ShoppingCartOutlined } from "@ant-design/icons"
import { Parallax } from "rc-scroll-anim";
import { AccountService, PurchasesService } from "red-canids-client-module/client-web";
import ImageRedCoins from "../../atomos/ImageRedCoins";
import ImageRedExp from "../../atomos/ImageRedExp";
import "./ManagerusersStatisticsTemplate.css";
import ModalAddRedCoinsXp from "../../organisms/ModalAddRedCoinsXp/ModalAddRedCoinsXp";
import { useSelector } from "react-redux";

const { Meta } = Card;
const Colors = require("../../../styles/Colors.json");

export default function ManagerUsersStatistics() {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [redCoinsModal, setRedCoinsModal] = useState(false);
  const [redCoinsValue, setRedCoinsValue] = useState(0);
  const [redXpValue, setRedXpValue] = useState(0);
  const [redXpModal, setRedXpModal] = useState(false);
  const [purchases, setPurchases] = useState([])

  const [purchaseCounts, setPurchaseCounts] = useState({});

  const userAdmin = useSelector((state) => state.accountAdmin);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await AccountService.returnAllAccountManager();
        setUsers(res.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    PurchasesService.returnAllPurchase().then((res) => {
      // Processar as compras para contar o número de compras para cada conta
      const counts = res.data.reduce((acc, purchase) => {
        const accountId = purchase.account._id.$oid;
        acc[accountId] = (acc[accountId] || 0) + 1;
        return acc;
      }, {});

      setPurchaseCounts(counts);
      setPurchases(res.data);
    }).catch(err => {
      console.log("error", err);
    });
  }, []);


  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);

  };

  const showRedCoinsModal = (red_coins, id) => {
    setRedCoinsModal(true);
    setRedCoinsValue(red_coins?.toFixed(0));
    setSelectedUser(id);
  };

  const onCancelRedCoinsModal = () => {
    setRedCoinsModal(false);
  };

  const showRedXpModal = (red_xp, id) => {
    setRedXpModal(true);
    setRedXpValue(red_xp);
    setSelectedUser(id);
  };
  const onCancelRedXpModal = () => {
    setRedXpModal(false);
  };

  const filteredUsers = users.filter((user) =>
    user.email.includes(searchValue)
  );

  return (
    <>
      <Flex vertical align="center" justify="center">
        <Typography.Title
          level={2}
          style={{
            marginTop: 0,
            marginBottom: 0,
            color: Colors.GrayscaleSlot5,
          }}
        >
          Total de usuários - {users.length}
        </Typography.Title>
        <Input
          placeholder="Filtrar por e-mail"
          value={searchValue}
          onChange={handleInputChange}
          style={{
            marginBottom: 20,
            width: "50%",
            marginTop: 10,
            background: Colors.GrayscaleSlot2,
            color: Colors.GrayscaleSlot5,
          }}
        />
        <Spin spinning={loading} tip="Loading...">
          <List
            itemLayout="horizontal"
            grid={{ gutter: 1, xs: 1, sm: 1, md: 2, lg: 3, xl: 4, xxl: 5 }}
            pagination={{ pageSize: 10 }}
            dataSource={filteredUsers.sort((a, b) => b.red_coins - a.red_coins)}
            renderItem={(user) => (
              <Parallax
                blur={10}
                bgImage={user.photo_profile || "/images/ModalWelcome.png"}
                bgImageAlt="Card Background"
                strength={200}
              >
                <List.Item>
                  <Card
                    key={user.id}
                    hoverable
                    className="hoverable-card"
                    style={{
                      minWidth: "200px",
                      width: "90%",
                      marginLeft: 10,
                      marginRight: 100,
                      marginBottom: 10,
                    }}
                    actions={[
                      <Flex vertical align="center" justify="space-evenly">
                        <Flex align="center" justify="center" gap={5}>
                          <Button
                            danger
                            onClick={() =>
                              showRedCoinsModal(user.red_coins, user._id.$oid)
                            }
                          >
                            +
                          </Button>
                          <ImageRedCoins></ImageRedCoins>
                          <Typography>{user.red_coins?.toFixed(0)}</Typography>
                        </Flex>
                        <Divider />
                        <Flex align="center" justify="center" gap={5}>
                          <Button
                            danger
                            onClick={() =>
                              showRedXpModal(user.red_xp, user._id.$oid)
                            }
                          >
                            +
                          </Button>
                          <ImageRedExp></ImageRedExp>
                          <Typography>{user.red_xp?.toFixed(0)}</Typography>
                        </Flex>
                        <Divider />
                        <Flex align="center" justify="center" gap={5}>

                          <Typography>
                            <ShoppingCartOutlined />{purchaseCounts[user._id.$oid] || 0}
                          </Typography>

                        </Flex>
                      </Flex>,
                    ]}
                    cover={
                      <img
                        alt="example"
                        src={user.photo_profile || "/images/ModalWelcome.png"}
                        height={300}
                        style={{ objectFit: "cover" }}
                      />
                    }
                  >
                    <Meta title={user.name} description={user.email} />
                  </Card>
                </List.Item>
              </Parallax>
            )}
          />
        </Spin>

        <ConfigProvider
          theme={{ components: { Modal: { contentBg: "white" } } }}
        />
      </Flex>
      <ModalAddRedCoinsXp
        redCoins={redCoinsModal}
        redXp={redXpModal}
        onCancelRedCoinsModal={onCancelRedCoinsModal}
        onCancelRedXpModal={onCancelRedXpModal}
        redCoinsValue={redCoinsValue}
        redXpValue={redXpValue}
        selectedUser={selectedUser}
      />
    </>
  );
}
