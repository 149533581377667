import React from "react";


export default function TextRegularh2({ text, colorText }) {
    return (
        <>
            <span
                style={{
                    fontFamily: "Montserrat",
                    fontWeight: 400,
                    fontSize: 40,
                    lineHeight: "56px",
                    textAlign: "center",
                    color: colorText,
                }}>{text}
            </span>
        </>
    )
}