import React, { useEffect } from "react";
import BaseLayout from "../../components/templates/BaseLayout/BaseLayout";
import PendingUsers from "../../components/templates/PendingUsers/PendingUsers";

export default function ManagerHome() {
  return (
    <>
      <BaseLayout currentKey="UserToApprove" content={<PendingUsers />} />
    </>
  );
}
