import React from "react";

export default function ImageRedExp({width, height, style}) {
    return (
        <img
            src="/images/RedExp.png"
            style={{
                width: width ? width : 30,
                height: height ? height : 30,
                ...style,
            }}></img>
    )
}