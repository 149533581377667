import CryptoJS from 'crypto-js';

const key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_KEY);

export function encryptAES(message) {
    const iv = CryptoJS.lib.WordArray.random(16); // generate a new random IV for each message
    var ciphertext = CryptoJS.AES.encrypt(message, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    // Concatenate IV and ciphertext and encode them in base64
    const ivAndCiphertext = CryptoJS.enc.Base64.stringify(iv.concat(ciphertext.ciphertext));
    return ivAndCiphertext;
}