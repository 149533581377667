import React from "react";
import { Button, Flex, Layout, Menu, theme } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    DollarCircleOutlined,
    UserAddOutlined,
    ShopOutlined,
    ShoppingCartOutlined,
    LineChartOutlined,
    AimOutlined,
    LogoutOutlined,
    QuestionOutlined
} from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import TextBoldH3 from "../../atomos/TextBoldH3";
import { closeDrawer, openDrawer } from "../../../features/drawer/drawer";
import "./BaseLayout.css";

export default function BaseLayout({ currentKey, content }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const admin = useSelector((state) => state.accountAdmin);

    const drawer = useSelector((state) => state.drawer);
    const userAdmin = useSelector((state) => state.accountAdmin);
    const colors = require("../../../styles/Colors.json");

    const { Header, Sider, Content } = Layout;
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    function chooseNavigateTo(key) {
        switch (key) {
            case "UserToApprove":
                return "/manager/user-to-approve"
                break;
            case "ManagerMissions":
                return "/manager/missions"
                break;
            case "ManagerProducts":
                return "/manager/products"
                break;
            case "ManagerStore":
                return "/manager/store"
            case "Questions":
                return "/manager/questions"
            case "ManagerUsersStatistics":
                return "/manager/users-statistics"
                break;

            default:
        }
    }


    function navigateTo(key) {
        const path = chooseNavigateTo(key)
        navigate(path)
    }

    return (
        <>
            <Layout>
                <Sider trigger={null} collapsible collapsed={drawer} width={300} style={{ position: "relative" }}>
                    <div className="logo-vertical-menu" onClick={() => drawer ? dispatch(closeDrawer()) : dispatch(openDrawer())}>
                        <img src="https://redcanidsstorage.blob.core.windows.net/redcanids/Logo_RC_Big.png" alt="logo" className="home-logo" />
                    </div>
                    <Menu
                        theme="dark"
                        mode="inline"
                        defaultSelectedKeys={['UserToApprove']}
                        selectedKeys={currentKey}
                        onSelect={({ key }) => navigateTo(key)}
                        items={[
                            {
                                key: 'UserToApprove',
                                icon: <UserAddOutlined />,
                                label: 'Usuário para aprovar',
                            },
                            {
                                key: 'ManagerMissions',
                                icon: <AimOutlined />,
                                label: 'Missões',
                            },
                            {
                                key: 'ManagerProducts',
                                icon: <ShoppingCartOutlined />,
                                label: 'Compras',
                            },
                            {
                                key: 'ManagerStore',
                                icon: <ShopOutlined />,
                                label: 'Loja',
                            },
                            {
                                key: 'Questions',
                                icon: <QuestionOutlined />,
                                label: 'Respostas do Fomulario',
                            },
                            {
                                key: 'ManagerUsersStatistics',
                                icon: <LineChartOutlined />,
                                label: 'Estatísticas',
                                disabled: userAdmin.level_access == 0 ? true : false,
                            },

                        ]}
                    />
                    <div style={{ cursor: "pointer", position: "fixed", width: drawer ? 80 : 300, bottom: 20, height: 50, justifyContent: "center", display: "flex", alignItems: "center" }} onClick={() => {
                        localStorage.removeItem("@adminData");
                        navigate("/login/manager")
                    }}>
                        <LogoutOutlined />
                    </div>
                </Sider>
                <Layout>
                    <Header
                        style={{
                            padding: 0,
                            background: colorBgContainer,
                            display: 'flex',
                        }}
                    >

                        <Flex style={{ width: "100%", marginLeft: 20, marginRight: 20 }} >
                            <Button
                                type="text"
                                icon={drawer ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                                onClick={() => { drawer ? dispatch(closeDrawer()) : dispatch(openDrawer()) }}
                                style={{
                                    fontSize: '16px',
                                    width: 64,
                                    height: 64,
                                }}
                            />
                            <Flex flex={1} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <TextBoldH3 text={"Painel de gestão"} colorText={colors.GrayscaleSlot6} />
                            </Flex>
                            <Flex style={{ width: 64, height: 64, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                {/* <img src="https://redcanidsstorage.blob.core.windows.net/redcanids/Logo_RC_Big.png" alt="logo" className="home-logo" /> */}
                            </Flex>
                        </Flex>

                    </Header>
                    <Content
                        style={{
                            margin: '24px 16px',
                            padding: 24,
                            minHeight: 280,
                            borderRadius: borderRadiusLG,
                        }}
                    >
                        {content}
                    </Content>
                </Layout>
            </Layout>
        </>
    );
}