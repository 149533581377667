import React, { useEffect, useState } from "react";
import { Carousel } from "antd";
import CardProductToRescue from "../CardProductToRescue/CardProductToRescue";
import "./ProdutsToRescue.css";
import { OpenAPI, ProductService } from "red-canids-client-module/client-web";
import {
  MinusCircleOutlined,
  PlusOutlined,
  PlusCircleOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined
} from "@ant-design/icons";

const responsiveSettings = [
  {
    breakpoint: 1025,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  },
];
export default function ProductsToRescue() {
  const [products, setProducts] = useState([])

  const sliderRef = React.useRef(null);

  useEffect(() => {
    ProductService.returnAllProductsClientStore()
      .then((res) => {
        // console.table(res.data)
        const enabledProducts = res.data.filter(product => product.is_enabled === true);
        setProducts(enabledProducts);
        // console.table(enabledProducts)
      })
      .catch((error) => {
        console.error('Error fetching products:', error);
      });
  }, []);

  /* const productsList = [
    {
      id: products._id,
      title: products.name_product,
      image: products.image_product,
      product_type: products.type_product,
      available_amount: products.stock,
      product_value: products.price,
    }
  ] */
  function getSizeToCarrousel() {
    switch (products.length) {
      case 1:
        return 1
      case 2:
        return 2
      case 3:
        return 3
      case 4:
        return 3
      case 5:
        return 4
      default:
        return 5

    }
  }
  return (
    <div style={{ position: "relative" }}>
      <Carousel
        ref={sliderRef}
        id="flex-title-product-to-rescue"
        slidesToScroll={1}
        slidesToShow={getSizeToCarrousel()}
        responsive={responsiveSettings}
        className="carousel-styles"
        infinite={true}
        draggable
      >
        {
          products.map((item) => (
            <>
              <CardProductToRescue key={item._id.$oid} params={item} />
            </>
          ))
        }
      </Carousel>
      <div
        onClick={sliderRef.current?.prev}
        style={{ position: "absolute", backgroundColor: "rgba(255, 255, 255, 0)", left: 5, top: 150, height: 150, borderRadius: 10, display: "flex", alignItems: "center", justifyContent: "center", width: 50, cursor: "pointer" }}>
        <ArrowLeftOutlined />
      </div>
      <div
        onClick={sliderRef.current?.next}
        style={{ position: "absolute", backgroundColor: "rgba(255, 255, 255, 0)", right: 5, top: 150, height: 150, borderRadius: 10, display: "flex", alignItems: "center", justifyContent: "center", width: 50, cursor: "pointer" }}>
        <ArrowRightOutlined />
      </div>
    </div>
  );
}
