import React from "react";
import "./HomeInfoUser.css";
import TextBoldH3 from "../../atomos/TextBoldH3";
import TextSemiboldH6 from "../../atomos/TextSemiboldH6";
import checkIsMobile from "../../../utils/CheckIsMobile";
import TextBoldH5 from "../../atomos/TextBoldH5";
import TextSemiboldTip from "../../atomos/TextSemiboldTip";
import { ConfigProvider, Statistic } from "antd";
import CountUp from 'react-countup';


export default function HomeInfoUser({ icon, total, text }) {

    const colors = require("../../../styles/Colors.json");
    const formatter = (value) => <CountUp duration={2} end={value} separator="," />;

    return (
        <>
            {
                checkIsMobile() ? (
                    <div className="info-user-home-mobile">
                        <div className="info-user-home-one">
                            <img src={icon} className="image-info-user-home-mobile"></img>
                        </div>
                        <div className="info-user-home-two">
                            <TextBoldH5 text={total} />
                            <TextSemiboldTip text={text} colorText={colors.BaseSlot2} />
                        </div>
                    </div>
                ) :
                    (
                        <div className="info-user-home">
                            <div className="info-user-home-one">
                                <img src={icon} className="image-info-user-home"></img>
                            </div>
                            <div className="info-user-home-two">
                                {/* <TextBoldH3 text={total} /> */}
                                <ConfigProvider
                                    theme={{
                                        components: {
                                            Statistic: {
                                                colorPrimary: "white",
                                                colorText: "white",
                                            },
                                        },
                                    }}>
                                    <Statistic valueStyle={{color: "white"}} value={total} title="Teste" formatter={formatter} />
                                </ConfigProvider>
                                <TextSemiboldH6 text={text} colorText={colors.BaseSlot2} />
                            </div>
                        </div>
                    )
            }
        </>
    )
}