import React from "react";
import BaseLayout from "../../components/templates/BaseLayout/BaseLayout";
import LoginManagerTemplate from "../../components/templates/LoginManagerTemplate/LoginManagerTemplate";


export default function LoginManager() {
  return (
    <>
      <LoginManagerTemplate />
    </>
  );
}
