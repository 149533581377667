import React from "react";
import PendingUsersList from "../../organisms/PendingUsersList/PendingUsersList";
import { Flex, Divider } from "antd";
import TextBoldH3 from "../../atomos/TextBoldH3";
import TextBoldH6 from "../../atomos/TextBoldH6";
import TextBoldH5 from "../../atomos/TextBoldH5";
import PendingUsersListAccepted from "../../organisms/PendingUsersListAccepted/PendingUsersListAccepted";
import TextBoldH4 from "../../atomos/TextBoldH4";

export default function PendingUsers() {

  const colors = require("../../../styles/Colors.json");

  return (
    <>
      <Flex
        style={{
          flexDirection: "column",
          width: "100%",
          backgroundColor: "white",
        }}
      >
        <Flex justify="center" align="center">
          <TextBoldH4 text={"Solicitações de acessos"} colorText={colors.GrayscaleSlot5} />
        </Flex>
        <Flex style={{ padding: "2%" }} >
          <PendingUsersList />
        </Flex>
        <Divider  />
        <Flex style={{ padding: "2%" }} >
          <PendingUsersListAccepted />
        </Flex>
      </Flex>
    </>
  );
}
