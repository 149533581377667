import React, { useEffect, useState } from "react";
import { Modal, Flex } from "antd";
import TextBoldH3 from "../../atomos/TextBoldH3";
import TextSemiboldH6 from "../../atomos/TextSemiboldH6";
import TextBoldH6 from "../../atomos/TextBoldH6";
import "./ModalTermsOfUse.css";

export default function ModalTermsOfUse() {
  const [modalVisible, setModalVisible] = useState(false);
  const handleCloseModal = () => {
    setModalVisible(false);
  };
  const handleModalToggle = () => {
    setModalVisible(!modalVisible);
  };
  const handleModalCancel = () => {
    setModalVisible(false);
  };

  return (
    <div>
      <a href="#" className="button-style" onClick={handleModalToggle}>
        <TextBoldH6 text={"Termos de Uso"} fontSize={10} />
      </a>
      <Modal visible={modalVisible} onCancel={handleModalCancel} footer={null}>
        <Flex
          style={{
            minHeight: "300px",
            minWidth: "100%",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div style={{ color: "white" }}>
            <h1>Termos de Uso da RED CANIDS ESPORT CLUB LTDA</h1>

            <h2>1. Introdução</h2>
            <p>
              A RED CANIDS ESPORT CLUB LTDA ("RED CANIDS", "nós", "nosso" ou
              "nossa") é uma empresa brasileira com sede na Rua Santa Cruz, 1168
              - Vila Mariana, São Paulo - SP, 04122-000, inscrita no CNPJ sob o
              nº 23.789.038.0001-90 ("CNPJ"). Estes Termos de Uso ("Termos")
              estabelecem os termos e condições que regem o uso do nosso website
              matilha.red, serviços e plataforma ("Plataforma"). Ao acessar ou
              usar a Plataforma, você concorda em estar vinculado a estes
              Termos. Se você não concordar com estes Termos, você não deve
              acessar ou usar a Plataforma.
            </p>

            <h2>2. Definições</h2>
            <p>
              Para os fins destes Termos, os seguintes termos terão os seguintes
              significados:
            </p>
            <ul>
              <li>
                "Usuário" significa qualquer pessoa física ou jurídica que
                acesse ou utilize a Plataforma.
              </li>
              <li>
                "Conteúdo" significa qualquer texto, imagem, áudio, vídeo,
                software ou outro material que seja carregado, publicado ou
                exibido na Plataforma.
              </li>
              <li>
                "Propriedade Intelectual" significa qualquer patente, marca
                registrada, direito autoral, segredo comercial ou outro direito
                de propriedade intelectual.
              </li>
            </ul>

            <h2>3. Acesso à Plataforma</h2>
            <p>
              O acesso à Plataforma é gratuito e está disponível a todos os
              Usuários. No entanto, a RED CANIDS reserva-se o direito de
              restringir o acesso à Plataforma, total ou parcialmente, a
              qualquer Usuário, a seu exclusivo critério e sem aviso prévio.
            </p>

            <h2>4. Uso da Plataforma</h2>
            <p>
              O Usuário concorda em usar a Plataforma de acordo com estes Termos
              e de acordo com todas as leis, regras e regulamentos aplicáveis. O
              Usuário concorda em não usar a Plataforma para qualquer finalidade
              ilegal ou prejudicial, incluindo, mas não se limitando a:
            </p>
            <ul>
              <li>
                Carregar, publicar ou transmitir qualquer Conteúdo que seja
                ilegal, prejudicial, difamatório, calunioso, ameaçador,
                assediador, obsceno, vulgar, ofensivo, racial ou sexualmente
                discriminatório, ou que de outra forma viole os direitos de
                terceiros;
              </li>
              <li>
                Carregar, publicar ou transmitir qualquer Conteúdo que infrinja
                a Propriedade Intelectual de terceiros;
              </li>
              <li>Fazer passagens por outra pessoa ou entidade;</li>
              <li>
                Coletar ou armazenar informações pessoais sobre outros Usuários
                sem o seu consentimento;
              </li>
              <li>Interferir ou interromper o funcionamento da Plataforma;</li>
              <li>
                Usar a Plataforma para enviar spam ou outras comunicações não
                solicitadas;
              </li>
              <li>
                Usar a Plataforma para qualquer finalidade comercial sem o
                consentimento prévio por escrito da RED CANIDS.
              </li>
            </ul>
            <p>
              A RED CANIDS reserva-se o direito de remover qualquer Conteúdo que
              viole estes Termos ou que acredite ser ilegal ou prejudicial. A
              RED CANIDS também reserva-se o direito de suspender ou encerrar a
              conta de qualquer Usuário que viole estes Termos.
            </p>

            <h2>5. Propriedade Intelectual</h2>
            <p>
              Todo o Conteúdo da Plataforma, incluindo, mas não se limitando a,
              texto, imagens, áudio, vídeo e software, é de propriedade da RED
              CANIDS ou de seus licenciantes e é protegido por leis de direitos
              autorais e outras leis de propriedade intelectual. O Usuário não
              pode copiar, reproduzir, modificar, traduzir, publicar,
              distribuir, executar, exibir ou transmitir qualquer Conteúdo sem o
              consentimento prévio por escrito da RED CANIDS.
            </p>

            <h2>6. Links para Terceiros</h2>
            <p>
              A Plataforma pode conter links para websites de terceiros. A RED
              CANIDS não é responsável pelo conteúdo ou práticas de websites de
              terceiros. O Usuário concorda em acessar e usar websites de
              terceiros por sua conta e risco.
            </p>

            <h2>7. Isenção de Responsabilidade</h2>
            <p>
              A RED CANIDS NÃO FAZ NENHUMA GARANTIA, EXPRESSA OU IMPLÍCITA,
              SOBRE A PLATAFORMA OU O CONTEÚDO, INCLUINDO, MAS NÃO SE LIMITANDO
              A, GARANTIAS DE COMERCIALIZAÇÃO, ADEQUAÇÃO A UM PROPÓSITO
              ESPECÍFICO E NÃO VIOLAÇÃO. A RED CANIDS NÃO SERÁ RESPONSÁVEL POR
              QUAISQUER DANOS DIRETOS, INDIRETOS, INCIDENTAIS, ESPECIAIS,
              CONSEQUENTES OU EXEMPLARES, INCLUINDO, MAS NÃO SE LIMITANDO A,
              LUCROS CESSANTES, PERDA DE USO, PERDA DE DADOS OU INTERRUPÇÃO DE
              NEGÓCIOS, RESULTANTES DO USO OU DA INCAPACIDADE DE USAR A
              PLATAFORMA OU DO CONTEÚDO, MESMO QUE A RED CANIDS TENHA SIDO
              AVISADA DA POSSIBILIDADE DE TAIS DANOS.
            </p>

            <h2>8. Limitação de Responsabilidade</h2>
            <p>
              A responsabilidade total da RED CANIDS por quaisquer danos
              decorrentes ou relacionados ao seu uso da Plataforma ou destes
              Termos será limitada, na medida permitida por lei, ao valor de R$
              [insira o valor máximo de indenização que sua empresa deseja
              estabelecer].
            </p>

            <h2>10. Rescisão</h2>
            <p>
              A RED CANIDS pode rescindir estes Termos a qualquer momento, por
              qualquer motivo, sem aviso prévio. A RED CANIDS também pode
              suspender ou encerrar sua conta a qualquer momento, por qualquer
              motivo, sem aviso prévio. Após a rescisão, seu direito de acessar
              a Plataforma cessará imediatamente. As disposições dos Termos que
              por sua natureza devam sobreviver à rescisão permanecerão em vigor
              após a rescisão.
            </p>

            <h2>11. Lei Aplicável e Foro</h2>
            <p>
              Estes Termos serão regidos e interpretados de acordo com as leis
              da República Federativa do Brasil. Qualquer disputa decorrente
              destes Termos será submetida ao foro da Comarca de São Paulo,
              Estado de São Paulo.
            </p>

            <h2>12. Divisibilidade</h2>
            <p>
              Se qualquer disposição destes Termos for considerada inválida ou
              inexequível, tal disposição será considerada separável e não
              afetará a validade e aplicabilidade das demais disposições.
            </p>

            <h2>13. Acordo Integral</h2>
            <p>
              Estes Termos constituem o acordo integral entre você e a RED
              CANIDS com relação ao uso da Plataforma e substituem todos os
              acordos anteriores ou contemporâneos, escritos ou verbais.
            </p>

            <h2>14. Alterações aos Termos</h2>
            <p>
              A RED CANIDS reserva-se o direito de alterar estes Termos a
              qualquer momento. As alterações entrarão em vigor no momento da
              publicação. Ao continuar a acessar ou usar a Plataforma após a
              publicação das alterações, você concorda em estar vinculado aos
              Termos revisados. É recomendável que você revise estes Termos
              periodicamente para verificar quaisquer alterações.
            </p>

            <h2>15. Contato</h2>
            <p>
              Se você tiver alguma dúvida sobre estes Termos, entre em contato
              conosco pelo e-mail{" "}
              <a href="mailto:contato@redcanids.com.br">
                contato@redcanids.com.br
              </a>
              .
            </p>

            <p>Data da última atualização: 12/04/2024</p>
          </div>
        </Flex>
      </Modal>
    </div>
  );
}
