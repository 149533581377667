import { DollarOutlined, CheckCircleOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { Avatar, List, Space, Flex, Button, message } from "antd";
import TextRegularLabel from "../../atomos/TextRegularLabel";
import { PurchasesService } from "red-canids-client-module/client-web";

export default function Orderslist({ purchases, showVisible }) {
  const [listLoading, setListLoading] = useState(false);

  function transformDateString(inputString) {
    const inputDate = new Date(inputString);
    const day = inputDate.getDate().toString().padStart(2, "0");
    const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
    const year = inputDate.getFullYear().toString();
    const hours = inputDate.getHours();
    const minutes = inputDate.getMinutes().toString().padStart(2, "0");
    const period = hours < 12 ? "AM" : "PM";
    const formattedHours = (hours % 12 || 12).toString().padStart(2, "0");
    const outputString = `${formattedHours}:${minutes}${period} - ${day}/${month}/${year} `;
    return outputString;
  }
  const handleBuyFinish = (id) => {
    setListLoading(true);
    PurchasesService.updatePurchaseDoneVisibleById(id)
      .then((res) => {
        
        message.success("Compra Concluida!").then(() => {
          setListLoading(false);
          window.location.reload();
        });
      })
      .catch((err) => console.error("ERROR", err)).finally(() => {
      });
  };

  const IconText = ({ icon, text }) => (
    <Space>
      {React.createElement(icon)}
      {text}
    </Space>
  );
  const filteredPurchases = showVisible
    ? purchases.filter((purchase) => purchase.purchase.is_visible)
    : purchases;
  const sortedPurchases = filteredPurchases.sort((a, b) =>
    a.purchase.is_visible ? -1 : 1
  );
  return (
    <List
      loading={listLoading}
      size="small"
      style={{
        width: "100%",
      }}
      pagination={{
        onChange: (page) => {
          
        },
        pageSize: 3,
      }}
      dataSource={sortedPurchases}
      renderItem={(item) => (
        <>
          <List.Item
            style={{
              justifyContent: "space-between",
              display: "flex",
            }}
            key={item.account._id.$oid}
          >
            <List
              style={{
                padding: "2%",
                width: "80",
                textAlign: "start",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <List.Item.Meta
                /* avatar={<Avatar src={item.avatar} />} */
                title={item.account.name}
                description={item.account.email}
              />
              {item.product.description_product}
              <Flex vertical gap={5}>
                <Flex gap={10} align={"start"} justify={"flex-start"} vertical>
                  <Flex>
                    <TextRegularLabel
                      text={transformDateString(item.purchase.created_at)}
                    />
                  </Flex>
                  <Flex vertical>
                    <Flex>
                      <p>Total da Compra: </p>
                      <IconText
                        icon={DollarOutlined}
                        text={item.purchase.total_price}
                        key="list-vertical-like-o"
                      />
                    </Flex>
                    <Flex>
                      <p>Quantos RED Coins o utilizador tinha: </p>
                      <IconText
                        icon={DollarOutlined}
                        text={item.purchase.last_account_red_coins}
                        key="list-vertical-like-o"
                      />
                    </Flex>
                    <Flex>
                      <p>Quantos RED Coins o utilizador ficou: </p>
                      <IconText
                        icon={DollarOutlined}
                        text={item.purchase.current_account_red_coins}
                        key="list-vertical-like-o"
                      />
                    </Flex>
                  </Flex>
                </Flex>
                <Flex>
                  <Button
                    type="default"
                    onClick={() => handleBuyFinish(item.purchase._id.$oid)}
                    style={{
                      color: "green",
                      border: "1px solid green",
                    }}
                    disabled={!item.purchase.is_visible}
                  >
                    {item.purchase.is_visible ? "" : <CheckCircleOutlined />}
                    {item.purchase.is_visible
                      ? "Concluir Compra"
                      : "Compra concluida"}
                  </Button>
                </Flex>
              </Flex>
            </List>
            <Flex style={{ alignContent: "flex-end", objectFit: "cover" }}>
              <img
                alt="logo"
                src={item.product.image_product}
                style={{ objectFit: "cover", width: 272, height: 272 }}
              />
            </Flex>
          </List.Item>
        </>
      )}
    />
  );
}
