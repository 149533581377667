import React from "react";

export default function ImageNormal() {
    return (
        <img
            src="/images/Rune.png"
            style={{
                height: 25,
                objectFit: "cover",
            }}></img>
    )
}