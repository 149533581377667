import React, { useEffect } from "react";
import { Descriptions } from "antd";

const AccountsAnswers = ({ account }) => {
  useEffect(() => {
    console.log(account);
  }, [account]);

  // Check if account or account.missions_completed is undefined
  if (!account || !account.missions_completed) {
    return <div>No data available</div>;
  }

  const descriptionsItems = [
    {
      key: "name",
      label: "Nome de Utilizador",
      children: account.name || "N/A",
    },
    ...account.missions_completed.flatMap((mission) =>
      mission?.questions.map((question, index) => ({
        key: `${mission.id_mission}-${question.question}-${index}`,
        label: `Pergunta - ${question.question}`,
        children: question.answer || "N/A",
      }))
    ),
  ];

  return (
    <Descriptions
      title={`Respostas de ${account?.name || ""}`}
      items={descriptionsItems}
      layout="vertical"
      bordered
    />
  );
};

export default AccountsAnswers;
