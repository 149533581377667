import React from "react";


export default function TextBoldH6({ text, colorText, textAlign, fontSize }) {
    return (
        <>
            <span
                style={{
                    fontFamily: "Montserrat",
                    fontWeight: 700,
                    fontSize: fontSize ? fontSize : 16,
                    lineHeight: "28px",
                    textAlign: textAlign ? textAlign : "center",
                    color: colorText,
                }}>{text}
            </span>
        </>
    )
}