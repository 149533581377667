import React, { useEffect } from "react";
import "./LoginPage.css";
import { ConfigProvider, Form, Input, message } from "antd";
import { Button } from "antd";
import TextBoldH3 from "../../components/atomos/TextBoldH3";
import TextRegularH6 from "../../components/atomos/TextRegularH6";
import { AccountService, OpenAPI } from "red-canids-client-module/client-web";
import { useParams } from "react-router-dom";

export default function LoginPage() {
  const colors = require("../../styles/Colors.json");
  const { id_account } = useParams();
  const [messageApi, contextHolder] = message.useMessage();
  const [sendInvite, setSendInvite] = React.useState(false);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");

  useEffect(() => {
    
    
  }, []);

  function finishInvite(params) {
    
    AccountService.createAccount({
        name: params.name,
        email: params.email,
        apple_access_token: id_account ? id_account : null,
    })
      .then((response) => {
        
        setSendInvite(true);
      })
      .catch(({ status }) => {
        console.log(status)
        if (status == 401) {
          console.log("401");
          messageApi.open({
            type: "warning",
            content: "Email já cadastrado",
          });
        }
      })
      .finally(() => {
        console.log("finally");
      });
  }

  function finishInviteError(params) {
    console.log("finish error" + params);
  }

  return (
    <div className="background-login">
      {contextHolder}
      <div
        className="image-login"
        style={{
          position: "absolute",
          height: "100vh",
          width: "100vw",
          zIndex: 0,
        }}
      />
      <div className="root-login">
        {!sendInvite ? (
          <>
            <div className="div-logo-login">
              <img
                src="https://redcanidsstorage.blob.core.windows.net/redcanids/Logo_RC_Big.png"
                alt="logo"
                className="logo-login"
              />
            </div>
            <div>
              <TextBoldH3 text="Preencha os dados!" />
              <div>
                <TextRegularH6 text="e aguarde o chamado da MATILHA!" />
              </div>
            </div>
            <div
              style={{
                width: "80%",
                maxWidth: 600,
                justifyContent: "center",
                alignContent: "center",
                display: "flex",
                marginTop: 50,
              }}
            >
              <Form
                name="login-invite"
                style={{ width: "100%" }}
                initialValues={{ remember: true }}
                onFinish={(value) => finishInvite(value)}
                onFinishFailed={(value) => finishInviteError(value)}
                autoComplete="off"
              >
                <Form.Item
                  name={"name"}
                  rules={[
                    {
                      required: true,
                      message: "Insira seu nome",
                    },
                    {
                      validator: (rule, value) => {
                        const nomeRegex = /^[\p{L}\s'-]+$/u;
                        if (!nomeRegex.test(value)) {
                          return Promise.reject("Insira um nome válido");
                        } else {
                          return Promise.resolve();
                        }
                      },
                    },
                  ]}
                >
                  <Input
                    id="input-name"
                    onBlur={(e) => {
                      window.document.getElementById(
                        "input-name"
                      ).style.border = "1px solid " + colors.Grayscale;
                    }}
                    onFocus={(e) => {
                      window.document.getElementById(
                        "input-name"
                      ).style.border = "1px solid " + colors.BaseSlot4;
                    }}
                    style={{ width: "100%" }}
                    placeholder="Nome"
                  />
                </Form.Item>
                <Form.Item
                  name={"email"}
                  rules={[
                    {
                      required: true,
                      message: "Insira seu email",
                    },
                    {
                      validator: (rule, value) => {
                        const emailRegex =
                          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
                        if (!emailRegex.test(value)) {
                          return Promise.reject("Insira um email válido");
                        } else {
                          return Promise.resolve();
                        }
                      },
                    },
                  ]}
                >
                  <Input
                    id="input-email"
                    onBlur={(e) => {
                      window.document.getElementById(
                        "input-email"
                      ).style.border = "1px solid " + colors.Grayscale;
                    }}
                    onFocus={(e) => {
                      window.document.getElementById(
                        "input-email"
                      ).style.border = "1px solid " + colors.BaseSlot4;
                    }}
                    autoComplete="true"
                    autoCorrect="true"
                    style={{ width: "100%" }}
                    placeholder="Email"
                  />
                </Form.Item>
                <Form.Item label=" " style={{ marginRight: 10 }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="button-border"
                    style={{ width: "100%" }}
                  >
                    Receber convite
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </>
        ) : (
          <>
            <div
              style={{
                height: 150,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
              }}
            >
              <TextBoldH3 text="O primeiro uivo foi dado " />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <TextRegularH6 text="acompanhe sua caixa de email e o chamado definitivo chegará em breve." />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
