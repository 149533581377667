import React from "react";


export default function TextBoldH3({ text, colorText, textAlign }) {
    return (
        <>
            <span
                style={{
                    fontFamily: "Montserrat",
                    fontWeight: 700,
                    fontSize: 36,
                    lineHeight: "48px",
                    textAlign: "center",
                    color: colorText,
                }}>{text}
            </span>
        </>
    )
}