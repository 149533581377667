import React from "react";
import "./AboutTheRedCanidsPlatform.css";
import TextSemiboldH4 from "../../atomos/TextSemiboldH4";
import { Flex } from "antd";
import checkIsMobile from "../../../utils/CheckIsMobile";
import TextSemiboldH5 from "../../atomos/TextSemiboldH5";
export default function AboutTheRedCanidsPlatform() {

const colors = require("../../../styles/Colors.json");

    return (
        <Flex className="div-title-about-red-canids-platform">
            {
                !checkIsMobile() ? (
                    <Flex gap={10}> <TextSemiboldH4 text={"Sobre a plataforma"} /><TextSemiboldH4 text={"RED Canids"} colorText={colors.BaseSlot2} /> </Flex>
                ) : (
                    <Flex vertical> <TextSemiboldH5 text={"Sobre a plataforma"} /> <TextSemiboldH5 text={"RED Canids"} colorText={colors.BaseSlot2} /> </Flex>
                )
            }
        </Flex>
    )
}