import React from "react";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import {
  AccountService,
  AdminService,
} from "red-canids-client-module/client-web";
import { addUserAdmin } from "../features/accountAdmin/accountAdmin";

export default function ProtectRouterAdmin({ children }) {
  const dispatch = useDispatch();
  const idAdmin = localStorage.getItem("@adminData");
  if (idAdmin) {
    AccountService.returnAllAccountManager(idAdmin)
      .then((response) => {
        dispatch(addUserAdmin(response.data));
      })
      .catch((error) => {
        localStorage.removeItem("@adminData");
        window.location.reload()
        console.log("ERROR GET ACCOUNT API", error);
      });
    return children;
  } else {
    return <Navigate to="/login/manager" />;
  }
}
