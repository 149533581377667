import React from "react";
import "./HomeInfoUserCard.css";
import HomeInfoUser from "../HomeInfoUser/HomeInfoUser";
import ImageUser from "../../atomos/ImageUser";
import TextRegularh2 from "../../atomos/TextRegularH2";
import TextSemiboldH6 from "../../atomos/TextSemiboldH6";
import { Button, Flex } from "antd";
import checkIsMobile from "../../../utils/CheckIsMobile";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import RedStaffIcon from "../../atomos/RedStaffIcon";
import SilverIcon from "../../atomos/SilverIcon";
import GoldIcon from "../../atomos/GoldIcon";

export default function HomeInfoUserCard({ text, colorText }) {
  const colors = require("../../../styles/Colors.json");
  const navigate = useNavigate();

  const account = useSelector((state) => state.account);
  return (
    <>
      {checkIsMobile() ? (
        <div className="div-home-info-user-card-mobile">
          <ImageUser
            src={
              account?.photo_profile
                ? account?.photo_profile
                : "https://redcanidsstorage.blob.core.windows.net/redcanids/7fffe9fd-2386-41d4-b2b3-dda09720accf.png"
            }
            height={170}
            width={170}
            alt={"user image"}
            borderRadius={10}
          />
          <Flex align="center" justify="center">
            <TextRegularh2 text={account?.name} />
            {account?.is_staff ? (
              <RedStaffIcon height={16} width={16} />
            ) : account?.rank == 1 ? (
              <SilverIcon height={16} width={16} />
            ) : account?.rank == 2 ? (
              <GoldIcon height={16} width={16} />
            ) : (
              <></>
            )}
          </Flex>

          <div
            onClick={() => navigate("/profile-page")}
            style={{ cursor: "pointer" }}
          >
            <TextSemiboldH6
              text={"Acessar meu perfil"}
              colorText={colors.BaseSlot2}
            />
          </div>
          <div className="div-icons-mobile">
            <HomeInfoUser
              text={"Amigos"}
              total={account?.friends?.length}
              icon={"/images/Friends.png"}
            />
            <div className="line-vertical" />
            <HomeInfoUser
              text={"RED XP"}
              total={account?.red_xp?.toFixed(0)}
              icon={"/images/RedExp.png"}
            />
            <div className="line-vertical" />
            <HomeInfoUser
              text={"RED Coins"}
              total={account?.red_coins?.toFixed(0)}
              icon={"/images/Red_Coins.png"}
            />
          </div>
        </div>
      ) : (
        <div className="div-home-info-user-card">
          <ImageUser
            src={
              account?.photo_profile
                ? account?.photo_profile
                : "https://redcanidsstorage.blob.core.windows.net/redcanids/7fffe9fd-2386-41d4-b2b3-dda09720accf.png"
            }
            height={170}
            alt={"user image"}
            width={170}
            borderRadius={10}
          />
          <div className="div-name-my-profile">
            <Flex align="center" justify="center">
              <TextRegularh2 text={account?.name} />
              {account?.is_staff ? (
                <RedStaffIcon height={16} width={16} />
              ) : account?.rank == 1 ? (
                <SilverIcon height={16} width={16} />
              ) : account?.rank == 2 ? (
                <GoldIcon height={16} width={16} />
              ) : (
                <></>
              )}
            </Flex>
            <div
              onClick={() => navigate("/profile-page")}
              style={{ cursor: "pointer" }}
            >
              <TextSemiboldH6
                text={"Acessar meu perfil"}
                colorText={colors.BaseSlot2}
              />
            </div>
          </div>
          <HomeInfoUser
            text={"Amigos"}
            total={account?.friends?.length}
            icon={"/images/Friends.png"}
          />
          <div className="line-vertical" />
          <HomeInfoUser
            text={"RED XP"}
            total={account?.red_xp?.toFixed(0)}
            icon={"/images/RedExp.png"}
          />
          <div className="line-vertical" />
          <HomeInfoUser
            text={"RED Coins"}
            total={account?.red_coins?.toFixed(0)}
            icon={"/images/Red_Coins.png"}
          />
          <Button
            onClick={() => navigate("/use-red-coins")}
            type="primary"
            htmlType="submit"
            className="button-border"
            style={{}}
          >
            Resgatar RED Coins
          </Button>
        </div>
      )}
    </>
  );
}
