import React from "react";


export default function TextBoldH4({ text, colorText, textAlign }) {
    return (
        <>
            <span
                style={{
                    fontFamily: "Montserrat",
                    fontWeight: 700,
                    fontSize: "32px",
                    lineHeight: "40px",
                    textAlign: "center",
                    color: colorText,
                }}>{text}
            </span>
        </>
    )
}