import React from "react";


export default function TextBoldLabel({ text, colorText }) {
    return (
        <>
            <span
                style={{
                    fontFamily: "Montserrat",
                    fontWeight: 700,
                    fontSize: 10,
                    lineHeight: "22px",
                    textAlign: "center",
                    color: colorText,
                }}>{text}
            </span>
        </>
    )
}