import React from 'react'

export default function ImageRedPocket({ width, height, style }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none"
            style={{
                width: width ? width : 30,
                height: height ? height : 30,
                ...style,
            }}>
            <g filter="url(#filter0_d_496_15466)">
                <path d="M8.38867 8.62239C8.38867 6.78144 9.88106 5.28906 11.722 5.28906H28.2785C30.1194 5.28906 31.6118 6.78145 31.6118 8.6224V31.3775C31.6118 33.2184 30.1194 34.7108 28.2785 34.7108H11.722C9.88106 34.7108 8.38867 33.2184 8.38867 31.3775V8.62239Z" fill="#FED141" />
                <path d="M8.38867 8.62239C8.38867 6.78144 9.88106 5.28906 11.722 5.28906H28.2785C30.1194 5.28906 31.6118 6.78145 31.6118 8.6224V30.3476C31.6118 32.1886 30.1194 33.681 28.2785 33.681H11.722C9.88106 33.681 8.38867 32.1886 8.38867 30.3476V8.62239Z" fill="url(#paint0_radial_496_15466)" />
                <mask id="mask0_496_15466" maskUnits="userSpaceOnUse" x="8" y="5" width="24" height="29">
                    <path d="M8.38867 8.62239C8.38867 6.78144 9.88106 5.28906 11.722 5.28906H28.2785C30.1194 5.28906 31.6118 6.78145 31.6118 8.6224V30.4047C31.6118 32.2457 30.1194 33.7381 28.2785 33.7381H11.722C9.88106 33.7381 8.38867 32.2457 8.38867 30.4047V8.62239Z" fill="url(#paint1_linear_496_15466)" />
                </mask>
                <g mask="url(#mask0_496_15466)">
                    <path d="M31.6118 7.242C31.6118 13.0199 26.4131 17.7037 20.0002 17.7037C13.5873 17.7037 8.38867 13.0199 8.38867 7.242C8.38867 1.46415 13.5873 -3.21973 20.0002 -3.21973C26.4131 -3.21973 31.6118 1.46415 31.6118 7.242Z" fill="#FF444F" />
                    <g filter="url(#filter1_f_496_15466)">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.0002 16.6168C26.3317 16.6168 31.4644 12.1762 31.4644 6.69854C31.4644 5.67268 31.2844 4.68319 30.9502 3.75244C31.3786 4.84384 31.6118 6.01841 31.6118 7.24204C31.6118 13.0199 26.4131 18.2117 20.0002 18.2117C13.5873 18.2117 8.38867 13.0199 8.38867 7.24204C8.38867 6.01874 8.62171 4.84449 9.04996 3.75334C8.71597 4.68383 8.53606 5.67301 8.53606 6.69854C8.53606 12.1762 13.6687 16.6168 20.0002 16.6168Z" fill="#F22D39" />
                    </g>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.0002 15.9169C26.3317 15.9169 31.4644 11.633 31.4644 6.34852C31.4644 5.35885 31.2844 4.40427 30.9502 3.50635C31.3786 4.55925 31.6118 5.69238 31.6118 6.87284C31.6118 12.4469 26.4131 16.9655 20.0002 16.9655C13.5873 16.9655 8.38867 12.4469 8.38867 6.87284C8.38867 5.6927 8.62171 4.55987 9.04996 3.50722C8.71597 4.40488 8.53606 5.35916 8.53606 6.34852C8.53606 11.633 13.6687 15.9169 20.0002 15.9169Z" fill="#FED546" />
                    <g filter="url(#filter2_f_496_15466)">
                        <path d="M24.9299 16.5352C24.9299 19.4907 22.7232 22.2075 20.0011 22.2075C17.279 22.2075 15.0723 19.4907 15.0723 16.5352C18.673 16.5352 17.279 16.5352 20.0011 16.5352C22.7232 16.5352 22.4537 16.5351 24.9299 16.5352Z" fill="#F22D39" />
                    </g>
                    <path d="M24.8141 16.5238C24.8141 19.1815 22.6596 21.3361 20.0018 21.3361C17.344 21.3361 15.1895 19.1815 15.1895 16.5238C15.1895 13.866 17.344 11.7114 20.0018 11.7114C22.6596 11.7114 24.8141 13.866 24.8141 16.5238Z" fill="url(#paint2_linear_496_15466)" />
                </g>
            </g>
            <defs>
                <filter id="filter0_d_496_15466" x="0.0553389" y="-3.04427" width="39.8893" height="46.0885" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset />
                    <feGaussianBlur stdDeviation="4.16667" />
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.528167 0 0 0 0 0.379167 0 0 0 0.29 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_496_15466" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_496_15466" result="shape" />
                </filter>
                <filter id="filter1_f_496_15466" x="1.72201" y="-2.91423" width="36.556" height="27.7928" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="3.33333" result="effect1_foregroundBlur_496_15466" />
                </filter>
                <filter id="filter2_f_496_15466" x="8.4056" y="9.86849" width="23.1908" height="19.0057" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="3.33333" result="effect1_foregroundBlur_496_15466" />
                </filter>
                <radialGradient id="paint0_radial_496_15466" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(13.194 11.1086) rotate(62.792) scale(36.6313 41.0375)">
                    <stop stop-color="#FF5B6F" />
                    <stop offset="0.46875" stop-color="#FF444F" />
                </radialGradient>
                <linearGradient id="paint1_linear_496_15466" x1="13.3477" y1="15.4249" x2="22.2949" y2="32.3092" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FF1E1E" />
                    <stop offset="1" stop-color="#FF2E6C" />
                </linearGradient>
                <linearGradient id="paint2_linear_496_15466" x1="20.0018" y1="11.7114" x2="20.0018" y2="17.6861" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFB81E" />
                    <stop offset="1" stop-color="#FED546" />
                </linearGradient>
            </defs>
        </svg>
    )
}
