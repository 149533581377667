import React from "react";


export default function TextRegularH6({ text, colorText, textAlign }) {
    return (
        <>
            <span
                style={{
                    fontFamily: "Montserrat",
                    fontWeight: 400,
                    fontSize: 16,
                    display: "flex",
                    lineHeight: "28px",
                    textAlign: textAlign ? textAlign : "center",
                    color: colorText,
                    inlineSize: "100%",
                }}>{text}
            </span>
        </>
    )
}