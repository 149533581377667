import React, { useEffect, useRef, useState } from "react";
import "./ProfilePage.css";
import MenuDefaultClient from "../../components/organisms/Menu/Menu";
import Footer from "../../components/organisms/Footer.js/Footer";
import { useDispatch, useSelector } from "react-redux";
import HeaderAfterMenu from "../../components/organisms/HeaderAfterMenu/HeaderAfterMenu";
import { useLocation } from "react-router-dom";
import EditProfile from "../../components/organisms/EditProfile/EditProfile";
import YourFriends from "../../components/organisms/YourFriends/YourFriends";
import ScoreboardFriends from "../../components/organisms/ScoreboardFriends/ScoreboardFriends";

export default function ProfilePage(props) {
    const dispatch = useDispatch();
    const location = useLocation();
    const itemsBreadCrumb = [
        {
            title: "Home",
            path: "/home"
        },
        {
            title: "Meu perfil",
            path: "/missions"
        }];


    return (
        <div className="home-base">
            <MenuDefaultClient page={location} />
            <HeaderAfterMenu title={"Meu perfil"} items={itemsBreadCrumb} />
            <EditProfile />
            <ScoreboardFriends />
            <Footer />
        </div>
    )
}