import React, { useState, useEffect } from "react";
import "./ScoreboardFriends.css";
import TextSemiboldH5 from "../../atomos/TextSemiboldH5";
import TextBoldParagraph from "../../atomos/TextBoldParagraph";
import checkIsMobile from "../../../utils/CheckIsMobile";
import { Button, Flex } from "antd";
import ModalToInviteFriends from "../ModalToInviteFriends/ModalToInviteFriends";
import { AccountService } from "red-canids-client-module/client-web";
import RedStaffIcon from "../../atomos/RedStaffIcon";
import SilverIcon from "../../atomos/SilverIcon";
import GoldIcon from "../../atomos/GoldIcon";

function Friends({ name, xp, image }) {
  const colors = require("../../../styles/Colors.json");
  const [accountList, setAccountList] = useState([]);

  const sortedList = accountList.slice().sort((a, b) => b.red_xp - a.red_xp);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await AccountService.returnAllAccount();
        setAccountList(res?.data);
      } catch (error) {
        console.error("Error fetching account data:", error);
      }
    };

    fetchData();

    return () => {
      setAccountList([]);
    };
  }, []);

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  return (
    <ul className="div-scoreboard-cards-2">
      {sortedList.map((item) => (
        <li key={item._id.$oid}>
          <div className="card-friends">
            <div className="card-friends-image">
              {item.photo_profile === "" ||
              item.photo_profile === "2" ||
              item.photo_profile === "https://www.image.com.br" ? (
                <img
                  src={
                    "https://redcanidsstorage.blob.core.windows.net/redcanids/7fffe9fd-2386-41d4-b2b3-dda09720accf.png"
                  }
                  className="image-friends"
                  alt="Default Avatar"
                ></img>
              ) : (
                <img
                  src={item.photo_profile}
                  className="image-friends"
                  alt="Edited Avatar"
                ></img>
              )}
            </div>
            <div className="card-friends-info">
              <Flex align="center" justify="center">
                <TextBoldParagraph text={truncateText(String(item?.name), 15)} />
                {item?.is_staff ? (
                  <RedStaffIcon height={13} width={13} />
                ) : item?.rank == 1 ? (
                  <SilverIcon height={13} width={13} />
                ) : item?.rank == 2 ? (
                  <GoldIcon height={13} width={13} />
                ) : (
                  <></>
                )}
              </Flex>
              <TextBoldParagraph
                text={item.red_xp?.toFixed(0)}
                textRed={"RED XP"}
                colorTextRed={colors.ErrorSlotColor}
              />
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
}

export default function ScoreboardFriends() {
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <>
      {!checkIsMobile() ? (
        <div className="div-scoreboard">
          <div className="div-scoreboard-title">
            <TextSemiboldH5 text="Top Ranking Matilha" />
          </div>
          <div className="div-scoreboard-list">
            <Friends />
          </div>
          <ModalToInviteFriends />
        </div>
      ) : (
        <div className="div-scoreboard-mobile">
          <div className="div-scoreboard-title">
            <TextSemiboldH5 text="Top Ranking Matilha" />
          </div>
          <div className="div-scoreboard-cards-2">
            <Friends />
          </div>
          <ModalToInviteFriends />
        </div>
      )}
    </>
  );
}
