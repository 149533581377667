import React from "react";

export default function ImageRedCoins({ width, height, style }) {
    return (
        <img
            src="/images/Red_Coins.png"
            style={{
                width: width ? width : 25,
                height: height ? height : 25,
                ...style,
            }}></img>
    )
}