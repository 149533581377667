import { createSlice } from '@reduxjs/toolkit'

export const missionSelectedFeatures = createSlice({
    name: 'missionSelected',
    initialState: {},
    reducers: {
        selectMission: (state, action) => {
            state = action.payload
            return state
        },
    },
})

export const { selectMission } = missionSelectedFeatures.actions

export default missionSelectedFeatures.reducer