import React, { useEffect, useState } from "react";
import "./ProgressPointWatchVideo.css";
import MenuInfoUser from "../MenuInfoUser/MenuInfoUser";
import { Progress } from "antd";
import ImageRedCoins from "../../atomos/ImageRedCoins";
import ImageRedExp from "../../atomos/ImageRedExp";
import TextBoldParagraph from "../../atomos/TextBoldParagraph";
import checkIsMobile from "../../../utils/CheckIsMobile";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Confetti from 'react-confetti';
import TextRegularH6 from "../../atomos/TextRegularH6";

export default function ProgressPointWatchVideo({ progress, width }) {
    const { id_mission } = useParams();

    const missionSelec = useSelector((state) => state.missionSelected);
    const account = useSelector((state) => state.account);

    const colors = require("../../../styles/Colors.json");

    const [showConfetti, setShowConfetti] = useState(false);

    useEffect(() => {
        if (progress === 100) {
            setShowConfetti(true);
        }
    }, [progress]);

    const coinsEarned = missionSelec.points + " " + (missionSelec.type_points === "red_coins" ? "RED Coins" : "RED XP");

    return (
        <>
            {!checkIsMobile() ? (
                <div className="root-progress-point-watch-video" style={{ width: width }}>
                    <MenuInfoUser />
                    <ImageRedExp height={50} width={50} style={{ marginLeft: 30 }} />
                    <div className="group-line-progress-point-watch-video">
                        <div className="group-line-text--progress-point-watch-video">
                            <TextBoldParagraph text={"Farmando RED Coins e XP"} colorText={"white"} />
                            {
                                showConfetti === true ? <TextBoldParagraph text={"Você ganhou " + coinsEarned} colorText={"white"} /> : <TextBoldParagraph text={"Essa missão vai te dar " + missionSelec.points + " " + (missionSelec.type_points === "red_coins" ? "RED Coins" : "RED XP")} colorText={"white"} />} {
                            }
                        </div>
                        <Progress percent={progress} status="active" strokeColor={colors.BaseSlot2} trailColor={colors.GrayscaleSlot6} showInfo={false} />
                        {showConfetti && (
                            <>
                                <Confetti
                                    width={window.innerWidth}
                                    height={window.innerHeight}
                                    recycle={false}
                                    numberOfPieces={300}
                                    colors={["#ff0000", "#ff7300", "#fffb00", "#48ff00", "#00ffd5", "#002bff", "#7a00ff", "#ff00c8", "#ff0000"]}
                                />
                            </>
                        )}
                    </div>
                </div>
            ) : (
                <div className="root-progress-point-watch-video-mobile">
                    <ImageRedExp height={50} width={50} style={{ marginLeft: 5 }} />
                    <div className="group-line-progress-point-watch-video">
                        <div className="group-line-text--progress-point-watch-video">
                            <TextBoldParagraph text={"Farmando RED Coins e XP"} colorText={"white"} />
                            {showConfetti === true ? <TextBoldParagraph text={"Você ganhou " + coinsEarned} colorText={"white"} /> : <TextBoldParagraph text={"Essa missão vai te dar " + missionSelec.points + " " + (missionSelec.type_points === "red_coins" ? "RED Coins" : "RED XP")} colorText={"white"} />}
                        </div>
                        <Progress percent={progress} status="active" strokeColor={colors.BaseSlot2} trailColor={colors.GrayscaleSlot6} showInfo={false} />
                        {showConfetti && (
                            <>
                                <Confetti
                                    width={window.innerWidth}
                                    height={window.innerHeight}
                                    recycle={false}
                                    numberOfPieces={300}
                                    colors={["#ff0000", "#ff7300", "#fffb00", "#48ff00", "#00ffd5", "#002bff", "#7a00ff", "#ff00c8", "#ff0000"]}
                                />
                            </>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}