import React, { useEffect } from "react";
import MenuDefaultClient from "../../components/organisms/Menu/Menu";
import Footer from "../../components/organisms/Footer.js/Footer";
import { useLocation } from "react-router-dom";
import HeaderAfterMenu from "../../components/organisms/HeaderAfterMenu/HeaderAfterMenu";
import { Flex } from 'antd';
import FAQComponent from '../../components/organisms/FAQComponent/ FAQComponent';
import TextBoldH4 from "../../components/atomos/TextBoldH4";
import './FAQ.css';
import TextRegularH6 from "../../components/atomos/TextRegularH6";
export default function FAQ() {
    const { pathname } = useLocation();
    const location = useLocation();
    const itemsBreadCrumb = [
        {
            title: "Home",
            path: "/home",
        },
        {
            title: "Principais dúvidas",
        },
    ];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <Flex vertical> 
            <MenuDefaultClient page={location}/>
            <HeaderAfterMenu
                title={"Principais dúvidas"}
                items={itemsBreadCrumb}
            />
            <Flex vertical align="center" justify="center" style={{ padding: "20px" , maxWidth: "40vw", alignSelf: "center", gap: "20px"}} className="div-faq-container">
                <TextBoldH4 text={"Perguntas mais frequentes"} />
                <TextRegularH6 text={"Se ainda tem dúvidas à respeito da plataforma ou de como ganhar pontos, moedas ou resgatar benefícios, confira abaixo:"} />
            </Flex>
            <FAQComponent />
            <Footer />
        </Flex>
    )
}
