import React, { useEffect, useState } from "react";
import "./MissionsCard.css";
import TextBoldH6 from "../../atomos/TextBoldH6";
import TextBoldParagraph from "../../atomos/TextBoldParagraph";
import ImageRare from "../../atomos/ImageRare";
import ImageNormal from "../../atomos/ImageNormal";
import ImageTimerYellow from "../../atomos/ImageTimerYellow";
import ImageTimerRed from "../../atomos/ImageTimerRed";
import { Alert, Button, ConfigProvider, Flex, Typography, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { selectMission } from "../../../features/missionSelected/missionSelected";
import Colors from "../../../styles/Colors.json";
import { MobileOutlined, DesktopOutlined } from '@ant-design/icons';
import checkIsMobile from "../../../utils/CheckIsMobile";

/**
 * Componente que representa um cartão de missão.
 *
 * @param {Object} props - Propriedades do componente.
 * @param {Object} props.params - Parâmetros da missão.
 * @author Bruno Paulon
 */
export default function MissionsCard({ params }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());
    const [api, contextHolder] = notification.useNotification();

    /**
     * Calcula o tempo restante para a expiração da missão.
     *
     * @returns {Object} Objeto contendo horas, minutos e segundos.
     */
    function calculateTimeRemaining() {
        const { expiration_date } = params;
        const expirationDate = new Date(expiration_date);
        const now = new Date();
        const difference = expirationDate - now;
        const hours = Math.floor(difference / 3600000);
        const minutes = Math.floor((difference % 3600000) / 60000);
        const seconds = Math.floor((difference % 60000) / 1000);
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

        return { hours, minutes: formattedMinutes, seconds: formattedSeconds };
    }
    /**
     * Atualiza o tempo restante a cada segundo.
     */
    useEffect(() => {
        const interval = setInterval(() => {
            setTimeRemaining(calculateTimeRemaining());
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const { classification, name_mission, image_mission, _id, points, type_points, device } = params;
    const rareMission = classification === "rare";
    const cardClass = rareMission ? "div-missions-card" : "div-missions-card-normal";

    const cardTypeContent = rareMission ? (
        <Flex vertical style={{ width: "100%" }} justify="flex-start" align="flex-start">
            <Flex vertical align="flex-start">
                <Typography.Paragraph style={{ color: "white", fontSize: 10, fontWeight: "bold" }}>
                    BÔNUS EM DOBRO:
                </Typography.Paragraph>
                <Typography.Paragraph style={{ color: "white", fontSize: 10, fontWeight: "bold", textAlign: "left" }} >
                    GANHE RED XP E RED COINS NESSA MISSÃO
                </Typography.Paragraph>
            </Flex>
            <Flex gap={20} align="center" justify="center">
                <ImageRare />
                <TextBoldParagraph text={"Missão rara"} colorText={Colors.AlertSlotColor} textAlign={"left"} />
            </Flex>
        </Flex>
    ) : (
        <Flex vertical style={{ width: "100%" }} justify="flex-start" align="flex-start">
            <Flex vertical align="flex-start">
                <Typography.Paragraph style={{ color: "white", fontSize: 10, fontWeight: "bold" }}>
                    BÔNUS EM DOBRO:
                </Typography.Paragraph>
                <Typography.Paragraph style={{ color: "white", fontSize: 10, fontWeight: "bold", textAlign: "left" }} >
                    GANHE RED XP E RED COINS NESSA MISSÃO
                </Typography.Paragraph>
            </Flex>
            <Flex gap={20} align="center" justify="center">
                <ImageNormal />
                <TextBoldParagraph text={"Missão regular"} colorText={Colors.BaseSlot2} textAlign={"left"} />
            </Flex>
        </Flex>
    );

    const buttonTheme = {
        components: {
            Button: {
                primaryColor: "white",
                colorPrimaryHover: rareMission ? Colors.AlertSlotColor : Colors.BaseSlot2,
                colorPrimary: rareMission ? Colors.AlertSlotColor : Colors.BaseSlot2,
                colorPrimaryActive: rareMission ? Colors.AlertSlotColor : Colors.BaseSlot2,
            },
        },
    };

    function iconDeviceMission() {

        if (device == "mobile") {
            return <MobileOutlined style={{ color: "white" }} />
        } else if (device == "desktop") {
            return <DesktopOutlined style={{ color: "white" }} />
        } else {
            return <><MobileOutlined style={{ color: "white" }} /> <DesktopOutlined style={{ color: "white" }} /></>
        }
    }

    function navigateToDetailMission() {


        if (device == "mobile" && checkIsMobile() === false) {
            openNotificationWithIcon('error');
        } else if (device == "desktop" && checkIsMobile() === true) {
            openNotificationWithIcon('error');
        } else {
            dispatch(selectMission(params));
            navigate(`/missions/detail/${_id.$oid}`);
        }
    }

    const openNotificationWithIcon = (type) => {
        api[type]({
            message: 'Essa missão é destinada ao mobile, acesse em seu celular e ganhe pontos!',
            description: '',
            closeIcon: <></>,
            icon:
                <>
                    <MobileOutlined style={{ color: "red", marginTop: 0 }} />
                </>,
        });
    };

    return (
        <div className={cardClass} onClick={() => {
            navigateToDetailMission();
        }}>
            {contextHolder}
            <div className="div-missions-card-one">
                <img src={image_mission} className="image-missions-card" alt="Mission" />
            </div>
            <div className="div-missions-card-two">
                <div className="div-missions-card-two-type">
                    <TextBoldH6 text={name_mission} colorText={"white"} textAlign={"left"} />
                </div>
                <div className="div-missions-card-two-type">{cardTypeContent}</div>
                <div className="div-missions-card-line" />
                <div className="div-missions-card-infos">
                    <div className="div-missions-card-info-time">
                        {rareMission ? <ImageTimerYellow /> : <ImageTimerRed />}
                        <TextBoldParagraph text={`${timeRemaining.hours}:${timeRemaining.minutes}:${timeRemaining.seconds}`} colorText={"white"} />
                    </div>
                    <div className="div-missions-card-info">
                        <ConfigProvider theme={buttonTheme}>
                            <Button
                                style={{ width: 100, alignItems: "center", justifyContent: "center" }}
                                type="primary"
                                htmlType="submit"
                                onClick={() => navigateToDetailMission()}
                            >
                                <TextBoldParagraph text={`${points} Pontos `} colorText={"white"} fontSize={"18px"} />
                            </Button>
                        </ConfigProvider>
                    </div>
                    <div style={{ flexDirection: "row", display: "flex" }}>
                        {
                            iconDeviceMission()
                        }
                        {/* <MobileOutlined />
                        <DesktopOutlined /> */}
                    </div>
                </div>
            </div>
        </div>
    );
}
