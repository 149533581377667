import React from "react";


export default function TextBoldH5({ text, colorText }) {
    return (
        <>
            <span
                style={{
                    fontFamily: "Montserrat",
                    fontWeight: 700,
                    fontSize: 20,
                    lineHeight: "32px",
                    textAlign: "center",
                    color: colorText,
                }}>{text}
            </span>
        </>
    )
}