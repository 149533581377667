import React from "react";

export default function ImageUser({ src, height, width, borderRadius, alt, darkImage }) {
    return (
        <img
            src={src}
            style={{
                height: height,
                width: width,
                borderRadius: borderRadius,
                objectFit: "cover",
                filter: darkImage ? "grayscale(100%)" : "none"
            }}
            alt={alt} />
    )
}