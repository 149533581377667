import { Flex } from 'antd'
import React from 'react'
import TextSemiboldH6 from './TextSemiboldH6';
import TextRegularLabel from './TextRegularLabel';
export default function CardHowItWorks({ icon, title, titleRed, description, onClick }) {
    const colors = require("../../styles/Colors.json");
    return (
        <Flex
            onClick={onClick}
            vertical className='div-card-how-it-works'
            style={{
                flexDirection: "column", alignItems: "center", justifyContent: "space-evenly",
                backgroundColor: colors.GrayscaleSlot5, borderRadius: 12, minHeight: "230px",
                cursor: "pointer"
            }}>
            <Flex style={{
                border: "solid",
                borderImage: "linear-gradient(to right, #B3122A, #CA1BA3) 1",
                borderWidth: "1.167px"
            }}>
                {icon}
            </Flex>
            <Flex horizontal gap={5}>
                <TextSemiboldH6 text={title} colorText={colors.GrayscaleSlot1} />
                <TextSemiboldH6 text={titleRed} colorText={colors.BaseSlot2} />
            </Flex>
            <Flex style={{ alignSelf: "center", textAlign: "center", width: "80%" }}>
                <TextRegularLabel text={description} colorText={colors.GrayscaleSlot1} fontSize={14} />
            </Flex>
        </Flex>
    )
}