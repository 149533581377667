import { createSlice } from '@reduxjs/toolkit'

export const accountFeatures = createSlice({
    name: 'account',
    initialState: "",
    reducers: {
        addUser: (state, action) => {
            state = action.payload
            return state
        },

        changePhotoProfile: (state, action) => {
            state.photo_profile = action.payload
            return state
        },

        addRedCoins: (state, action) => {
            state.red_coins = action.payload + state.red_coins
            state.red_xp = action.payload + state.red_xp
            return state
        },

        subtractRedCoins: (state, action) => {
            state.red_coins -= action.payload
            return state
        },

        setPointsFarmer: (state, action) => {
            state.points_farmer_redux = action.payload
            return state
        },

        setPointsFarmerAccount: (state, action) => {
            state.points_farmer = action.payload
            return state
        }
    },
})

export const { addUser, addRedCoins, subtractRedCoins, changePhotoProfile, setPointsFarmer, setPointsFarmerAccount } = accountFeatures.actions

export default accountFeatures.reducer