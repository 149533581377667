import React, { useEffect, useState } from "react";
import TextBoldH2 from "../../atomos/TextBoldH2";
import {
  Button,
  ConfigProvider,
  DatePicker,
  Flex,
  FloatButton,
  Form,
  Input,
  InputNumber,
  List,
  Modal,
  Segmented,
  Switch,
  TimePicker,
} from "antd";
import TextBoldH5 from "../../atomos/TextBoldH5";
import TextBoldH3 from "../../atomos/TextBoldH3";
import {
  MinusCircleOutlined,
  PlusOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import Orderslist from "../../organisms/ManagerProducts/OrdersList";
import CardManagerProductsOrganisms from "../../organisms/CardManagerProductsOrganisms/CardManagerProductsOrganisms";
import UploadImages from "../../organisms/UploadImages/Upload";
import TextBoldH6 from "../../atomos/TextBoldH6";
import { ProductService } from "red-canids-client-module/client-web";
import { formatCountdown } from "antd/es/statistic/utils";
import dayjs from "dayjs";

export default function ManagerStoreTemplate() {
  const colors = require("../../../styles/Colors.json");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [images, setImages] = useState("");
  const [errorImage, setErrorImage] = useState(false);
  const [productSelected, setProductSelected] = useState({});
  const [dateString, setDateString] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showTip, setShowTip] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [listProducts, setListProducts] = useState([]);
  const [is_enabled, setIsEnabled] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [videoUplaoded, setVideoUplaoded] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    ProductService.returnAllProducts()
      .then((res) => {
        
        setListProducts(res.data);
      })
      .catch((err) => {
        console.error("ERROR", err);
      });

    return () => {
      setListProducts([]);
    };
  }, []);
  useEffect(() => {
    if (fileList.length <= 0) {
      setErrorImage(true);
    } else {
      setErrorImage(false);
    }
  }, [fileList]);
  useEffect(() => {
    const setInitialFormValues = () => {
      form.setFieldsValue({
        classification: productSelected.classification,
        code_product: productSelected.code_product,
        date_expiration: dayjs(productSelected.date_expiration),
        description_product: productSelected.description_product,
        name_product: productSelected.name_product,
        price: productSelected.price,
        stock: productSelected.stock,
        type_product: productSelected.type_product,
      });
      setIsEnabled(productSelected.is_enabled);
    };

    if (Object.keys(productSelected).length > 0) {
      setInitialFormValues();
    }

    return () => {};
  }, [productSelected]);

  const showEditProduct = () => {
    setIsEdit(!isEdit);

    setIsModalOpen(true);
  };

  const onFinish = (values) => {
    setIsLoading(true);
    
    
    if (fileList.length > 0) {
      if (isEdit) {
        ProductService.updateProductById(productSelected._id.$oid, {
          classification: values.classification,
          code_product: values.code_product,
          date_expiration: productSelected.date_expiration ? productSelected.date_expiration : dateString,
          description_product: values.description_product,
          image_product: fileList[0].url ? fileList[0].url : images,
          is_enabled: is_enabled,
          name_product: values.name_product,
          price: values.price,
          stock: values.stock,
          type_product: values.type_product,
        })
          .then((res) => {
            
            window.location.reload();
          })
          .catch((err) => {
            
          });
      } else {
        ProductService.createProduct({
          classification: values.classification,
          code_product: values.code_product,
          date_expiration: dateString,
          description_product: values.description_product,
          image_product: images,
          is_enabled: is_enabled,
          name_product: values.name_product,
          price: values.price,
          stock: values.stock,
          type_product: values.type_product,
        })
          .then((res) => {
            
            form.resetFields();
            handleCancel();
            setFileList([]);
            setIsLoading(false);
            ProductService.returnAllProducts()
              .then((res) => {
                
                setListProducts(res.data);
              })
              .catch((err) => {
                console.error("ERROR", err);
              });
          })
          .catch((err) => {
            
          });
      }
    } else {
      setErrorImage(true);
      onFinishFailed();
    }
  };
  function callBackClickEditProduct(value) {
    setProductSelected(value);
    showEditProduct();
  }
  const onFinishFailed = (errorInfo) => {
    console.log("ERROR", errorInfo);
  };
  const onChangeTime = (value, dateString) => {
    setDateString(dateString);
  };
  const onChangeEnabled = (value) => {
    
    setShowTip(true);
    setIsEnabled(value);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    if (isEdit) {
      window.location.reload();
      setIsEdit(false);
    } else {
      setIsModalOpen(false);
      form.resetFields();
    }
  };

  const handleDataReceivedImages = (values) => {
    setImages(values);
  };
  const submitForm = () => {
    form.submit();
  };

  return (
    <>
      <Flex
        style={{
          flexDirection: "column",
          width: "100%",
          backgroundColor: "white",
        }}
      >
        <Flex justify="center" align="center">
          <TextBoldH5 text={"Produtos"} colorText={colors.GrayscaleSlot5} />
        </Flex>
        <Flex style={{ padding: "2%" }} vertical>
          <List
            itemLayout="horizontal"
            grid={{
              gutter: 1,
              xs: 1,
              sm: 1,
              md: 2,
              lg: 3,
              xl: 4,
              xxl: 6,
            }}
            pagination={{
              pageSize: 8,
            }}
            dataSource={listProducts}
            renderItem={(product) => (
              <List.Item>
                <CardManagerProductsOrganisms
                  allValues={product}
                  callBackClickEditProduct={callBackClickEditProduct}
                />
              </List.Item>
            )}
          />
        </Flex>
      </Flex>
      <FloatButton
        onClick={() => showModal()}
        type="primary"
        shape="square"
        icon={<PlusCircleOutlined />}
        tooltip="Adicionar um novo produto"
      />
      <ConfigProvider
        theme={{
          components: {
            Modal: {
              contentBg: "white",
            },
            Segmented: {
              itemActiveBg: "white",
              itemSelectedBg: "#ffffff",

              colorBgLayout: "#f5f5f5",
            },
            Input: {
              colorText: "black",
              colorTextPlaceholder: "grey",
            },
          },
        }}
      >
        <Modal
          footer={false}
          title={isEdit ? "Editar produto" : "Criar novo produto"}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          width={"80%"}
        >
          {isEdit ? (
            <>
              <Flex align={"center"} gap={12}>
                <p>
                  {productSelected.is_enabled ? "Bloquear" : "Ativar"} Produto
                </p>
                <Switch onChange={onChangeEnabled} checked={is_enabled} />
                {showTip ? (
                  <p style={{ textDecoration: "underline" }}>
                    <strong>
                      Importante: Caso fizer mudanças no bloqueio do produto por
                      favor guardar as alterações antes de sair
                    </strong>
                  </p>
                ) : (
                  <></>
                )}
              </Flex>
              <br></br>
            </>
          ) : (
            <></>
          )}

          <UploadImages
            onDataReceived={handleDataReceivedImages}
            setImagesList={setImages}
            fileList={fileList}
            setFileList={setFileList}
            isEdit={isEdit}
            urlOnEdit={productSelected.image_product}
            is_enabled={productSelected.is_enabled}
            setVideoUplaoded={setVideoUplaoded}
          />
          {errorImage ? (
            <p style={{ color: "red" }}>*Por favor inserir Imagem</p>
          ) : (
            <p></p>
          )}

          <Form
            name="basic"
            labelCol={{
              span: 5,
            }}
            wrapperCol={{
              span: 26,
            }}
            labelAlign="left"
            onFinish={onFinish}
            disabled={Object.keys(productSelected).length > 0 ? !productSelected.is_enabled : false}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            form={form}
          >
            <Form.Item
              label="Nome do produto"
              name="name_product"
              rules={[
                {
                  required: true,
                  message: "Por favor por nome do produto!",
                },
              ]}
            >
              <Input placeholder="Nome do produto" />
            </Form.Item>
            <Form.Item
              label="Descrição do produto"
              name="description_product"
              rules={[
                {
                  required: true,
                  message: "Por favor por descrição do produto!",
                },
              ]}
            >
              <Input placeholder="Descrição" />
            </Form.Item>
            <Form.Item
              label="Preço do produto"
              name="price"
              rules={[
                {
                  required: true,
                  message: "Por favor por preço do produto!",
                },
              ]}
            >
              <InputNumber placeholder="Preço" />
            </Form.Item>
            <Form.Item
              label="Data de expiração do produto"
              name="date_expiration"
              rules={[
                {
                  required: true,
                  message: "Por favor por data de expiração!",
                },
              ]}
            >
              <DatePicker showTime onChange={onChangeTime} placeholder="Data" />
            </Form.Item>
            <Form.Item
              label="Stock do produto"
              name="stock"
              rules={[
                {
                  required: true,
                  message: "Por favor colocar numero de stock!",
                },
              ]}
            >
              <InputNumber placeholder="Stock" />
            </Form.Item>
            <Form.Item
              label="Classificação do produto"
              name="classification"
              rules={[
                {
                  required: true,
                  message: "Por favor escolher classificação do produto!",
                },
              ]}
            >
              <Segmented
                defaultValue={0}
                disabled={Object.keys(productSelected).length > 0 ? !productSelected.is_enabled : false}
                options={[
                  { label: "Raro", value: "rare" },
                  { label: "Normal", value: "normal" },
                ]}
              />
            </Form.Item>
            <Form.Item
              label="Tipo do produto"
              name="type_product"
              rules={[
                {
                  required: true,
                  message: "Por favor escolher tipo de protudo!",
                },
              ]}
            >
              <Segmented
                defaultValue={0}
                disabled={Object.keys(productSelected).length > 0 ? !productSelected.is_enabled : false}
                options={[
                  { label: "Virtual", value: "virtual" },
                  { label: "Fisico", value: "physical" },
                ]}
              />
            </Form.Item>
            <Form.Item
              label="Código do produto"
              name="code_product"
              rules={[
                {
                  required: true,
                  message: "Por favor por código do produto!",
                },
              ]}
            >
              <Input placeholder="Código" />
            </Form.Item>
          </Form>
          <Flex justify={"center"}>
            <Button
              style={{ width: 300 }}
              type="primary"
              onClick={() => submitForm()}
            >
              <TextBoldH6 text={isEdit ? "EDITAR PRODUTO" : "CRIAR PRODUTO"} />
            </Button>
          </Flex>
        </Modal>
      </ConfigProvider>
    </>
  );
}
