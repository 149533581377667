import React, { useEffect, useState } from "react";
import { Form, Input, Select, Button, Card, Flex, Typography } from "antd";
import { AccountService } from "red-canids-client-module/client-web";
import { useSelector } from "react-redux";

const colors = require("../../../styles/Colors.json");

export default function ManagerInsertCoinsTemplate() {
  const [form] = Form.useForm();
  const [option, setOption] = useState("red coins");
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const admin = useSelector((state) => state.accountAdmin);

  const handleOptionChange = (value) => {
    setOption(value);
  };

  const fetchData = () => {
    setLoading(true);
    AccountService.returnAllAccountManager().then((res) => {
      setUsers(res.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEmailChange = (value) => {
    const filtered = users.filter((user) =>
      user.email.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredUsers(filtered);
  };

  if (admin.level_access === 0) {
    return <div style={{color:"black"}}>Não tem acesso a esta pagina</div>;
  }
  return (
    <Flex vertical justify="space-between" align="center" gap={30}>
      <Typography.Title level={2}>Insira RED Coins ou RED XP</Typography.Title>
      <Flex justify="center" align="center" gap={10} style={{ width: "100%" }}>
        <Form form={form} initialValues={{ amount: 0 }} style={{ width: 600 }}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Please enter your email",
              },
            ]}
          >
            <Input
              onChange={(e) => handleEmailChange(e.target.value)}
              style={{ color: "black" }}
            />
          </Form.Item>
          <Form.Item
            label="Amount"
            name="amount"
            rules={[
              {
                required: true,
                message: "Please enter the amount",
              },
            ]}
          >
            <Input type="number" min={0} style={{ color: "black" }} />
          </Form.Item>
          <Form.Item
            label="Option"
            name="option"
            initialValue={option}
            rules={[
              {
                required: true,
                message: "Please select an option",
              },
            ]}
          >
            <Select onChange={handleOptionChange}>
              <Select.Option
                value="red coins"
                style={{ background: colors.BaseSlot3 }}
              >
                RED Coins
              </Select.Option>
              <Select.Option
                value="red xp"
                style={{ background: colors.BaseSlot5 }}
              >
                RED XP
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ background: colors.ErrorSlotColor }}
            >
              Add Coins/XP
            </Button>
          </Form.Item>
        </Form>
      </Flex>
      <Flex gap={20} wrap="wrap" justify="space-between">
        {filteredUsers.map((user) => (
          <Card
            key={user.id}
            style={{ width: 400, marginBottom: 16 }}
            cover={
              <img
                alt="example"
                src={user.photo_profile || "/images/ModalWelcome.png"}
                height={300}
                style={{ objectFit: "cover" }}
              />
            }
          >
            <Typography.Title level={5}>ID: {user._id.$oid}</Typography.Title>
            <Typography.Title>{user.name}</Typography.Title>
            <Typography.Text>Email: {user.email}</Typography.Text>
            <br></br>
            <Typography.Text> RED Coins: {user.red_coins}</Typography.Text>

            {/* Add more user details as needed */}
          </Card>
        ))}
      </Flex>
    </Flex>
  );
}
