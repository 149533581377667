import { Button, ConfigProvider, Form, Input, notification } from "antd";
import React, { useEffect, useState } from "react";
import TextBoldH6 from "../../atomos/TextBoldH6";
import {
  AccountService,
  AdminService,
} from "red-canids-client-module/client-web";
import { useNavigate } from "react-router-dom";

export default function LoginManagerForm() {
  const colors = require("../../../styles/Colors.json");
  const [api, contextHolder] = notification.useNotification();
  const [loginFailed, setLoginFailed] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm()
  useEffect(() => {
    if (loginFailed) {
      notification.open({
        message: "Erro ao por os Dados",
        description: "Por favor verifique os dados postos",
      });
      setLoginFailed(false);
    }
  }, [loginFailed]);
  const onFinish = (values) => {
    AdminService.returnAdminByLogin(values.email, values.password)
      .then((res) => {
        localStorage.setItem("@adminData", res.data._id.$oid);
        navigate("/manager");
      })
      .catch((err) => {
        console.log(err);
        setLoginFailed(true);
        form.resetFields()
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.error(errorInfo);
    setLoginFailed(true);
    form.resetFields()
  };
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        paddingTop: "8rem",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
        paddingRight: "1rem",
        paddingLeft: "1rem",
      }}
    >
      <div>
        <img
          src="https://redcanidsstorage.blob.core.windows.net/redcanids/Logo_RC_Big.png"
          alt="logo"
          width={200}
        />
      </div>
      <br></br>
      <br></br>
      <ConfigProvider
        theme={{
          components: {
            Input: {
              colorTextPlaceholder: colors.GrayscaleSlot5,
              hoverBorderColor: colors.BaseSlot4,
              activeBorderColor: colors.BaseSlot4,
              colorText: "black",
              colorTextPlaceholder: "rgba(0, 0, 0, 0.25)",
            },
            Button: {
              colorPrimary: colors.BaseSlot4,
              colorPrimaryHover: colors.BaseSlot2,
            },
          },
        }}
      >
        <Form
          style={{ width: "100%" }}
          layout={"vertical"}
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Por favor inserir email valido",
              },
            ]}
          >
            <Input type="email" />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Por favor inserir a sua password",
              },
            ]}
          >
            <Input type="password" />
          </Form.Item>
          <Form.Item style={{ justifyContent: "center", display: "flex" }}>
            <Button type="primary" htmlType="submit">
              <strong>Login</strong>
            </Button>
          </Form.Item>
        </Form>
      </ConfigProvider>
    </div>
  );
}
