import { createSlice } from '@reduxjs/toolkit'

export const accountAdminFeatures = createSlice({
    name: 'accountAdmin',
    initialState: {},
    reducers: {
        addUserAdmin: (state, action) => {
            state = action.payload
            return state
        },
    },
})

export const { addUserAdmin } = accountAdminFeatures.actions

export default accountAdminFeatures.reducer