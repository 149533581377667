import React from "react";


export default function TextBoldH2({ text, colorText, textAlign }) {
    return (
        <>
            <span
                style={{
                    fontFamily: "Montserrat",
                    fontWeight: 700,
                    fontSize: 44,
                    lineHeight: "56px",
                    textAlign: textAlign ? textAlign : "center",
                    color: colorText,
                }}>{text}
            </span>
        </>
    )
}