import React from "react";

export default function ImageSmile({ width, height, style }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="41" height="40" viewBox="0 0 41 40" fill="none"
            style={{
                width: width ? width : 30,
                height: height ? height : 30,
                ...style,
            }}>
            <g filter="url(#filter0_d_496_7452)">
                <path d="M35.769 20.0002C35.769 28.4321 28.9336 35.2675 20.5017 35.2675C12.0698 35.2675 5.23438 28.4321 5.23438 20.0002C5.23438 11.5683 12.0698 4.73291 20.5017 4.73291C28.9336 4.73291 35.769 11.5683 35.769 20.0002Z" fill="url(#paint0_radial_496_7452)" />
                <path d="M35.769 20.0002C35.769 28.4321 28.9336 35.2675 20.5017 35.2675C12.0698 35.2675 5.23438 28.4321 5.23438 20.0002C5.23438 11.5683 12.0698 4.73291 20.5017 4.73291C28.9336 4.73291 35.769 11.5683 35.769 20.0002Z" fill="url(#paint1_radial_496_7452)" />
                <ellipse cx="14.7408" cy="12.6822" rx="1.94584" ry="2.20122" fill="#7F2F17" />
                <ellipse cx="26.2622" cy="12.6822" rx="1.94584" ry="2.20122" fill="#7F2F17" />
                <path d="M27.1195 18.437C28.04 18.437 28.8018 19.191 28.6118 20.0916C27.8687 23.6152 24.8061 26.7227 20.5005 26.7227C16.1058 26.7227 13.0061 24.1327 12.3461 20.0976C12.1975 19.1892 12.961 18.437 13.8815 18.437H27.1195Z" fill="url(#paint2_linear_496_7452)" />
            </g>
            <defs>
                <filter id="filter0_d_496_7452" x="-1.43229" y="-1.93376" width="43.8685" height="43.868" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset />
                    <feGaussianBlur stdDeviation="3.33333" />
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.780392 0 0 0 0 0 0 0 0 0.3 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_496_7452" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_496_7452" result="shape" />
                </filter>
                <radialGradient id="paint0_radial_496_7452" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(27.1359 31.1442) rotate(-121.418) scale(28.0239 29.1635)">
                    <stop stop-color="#FF9D41" />
                    <stop offset="1" stop-color="#FFD75C" />
                </radialGradient>
                <radialGradient id="paint1_radial_496_7452" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(15.0016 12.1769) rotate(58.815) scale(20.9316 17.862)">
                    <stop stop-color="#FFEFA5" />
                    <stop offset="1" stop-color="#FFE25E" stop-opacity="0" />
                </radialGradient>
                <linearGradient id="paint2_linear_496_7452" x1="21.0134" y1="18.5265" x2="21.0134" y2="26.5122" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FF3E12" />
                    <stop offset="1" stop-color="#FF7D16" stop-opacity="0.9" />
                </linearGradient>
            </defs>
        </svg>
    )
} 