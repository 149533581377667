import React from 'react'

export default function ImageDoubts({ width, height, style }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none"
            style={{
                width: width ? width : 30,
                height: height ? height : 30,
                ...style,
            }}
        >
            <g filter="url(#filter0_d_496_13351)">
                <path d="M35.267 20.0002C35.267 28.4321 28.4316 35.2675 19.9997 35.2675C11.5678 35.2675 4.73242 28.4321 4.73242 20.0002C4.73242 11.5683 11.5678 4.73291 19.9997 4.73291C28.4316 4.73291 35.267 11.5683 35.267 20.0002Z" fill="url(#paint0_radial_496_13351)" />
                <path d="M35.267 20.0002C35.267 28.4321 28.4316 35.2675 19.9997 35.2675C11.5678 35.2675 4.73242 28.4321 4.73242 20.0002C4.73242 11.5683 11.5678 4.73291 19.9997 4.73291C28.4316 4.73291 35.267 11.5683 35.267 20.0002Z" fill="url(#paint1_radial_496_13351)" />
                <path d="M15.3281 26.0155C15.3281 25.2432 15.9542 24.6172 16.7264 24.6172H23.2709C24.0432 24.6172 24.6692 25.2432 24.6692 26.0155C24.6692 26.7878 24.0432 27.4138 23.2709 27.4138H16.7264C15.9542 27.4138 15.3281 26.7878 15.3281 26.0155Z" fill="#7F2F17" />
                <ellipse cx="14.2408" cy="16.8409" rx="1.94584" ry="2.20122" fill="#7F2F17" />
                <ellipse cx="25.7603" cy="16.8409" rx="1.94584" ry="2.20122" fill="#7F2F17" />
            </g>
            <defs>
                <filter id="filter0_d_496_13351" x="-1.93424" y="-1.93376" width="43.8685" height="43.868" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset />
                    <feGaussianBlur stdDeviation="3.33333" />
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.780392 0 0 0 0 0 0 0 0 0.3 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_496_13351" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_496_13351" result="shape" />
                </filter>
                <radialGradient id="paint0_radial_496_13351" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(26.634 31.1442) rotate(-121.418) scale(28.0239 29.1635)">
                    <stop stop-color="#FF9D41" />
                    <stop offset="1" stop-color="#FFD75C" />
                </radialGradient>
                <radialGradient id="paint1_radial_496_13351" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(14.4996 12.1769) rotate(58.815) scale(20.9316 17.862)">
                    <stop stop-color="#FFEFA5" />
                    <stop offset="1" stop-color="#FFE25E" stop-opacity="0" />
                </radialGradient>
            </defs>
        </svg>
    )
}
