import { Card, Dropdown, Modal, Space } from "antd";
import Meta from "antd/es/card/Meta";
import React, { useState } from "react";
import {
  EditOutlined,
  EllipsisOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import ConfigProvider, { ConfigContext } from "antd/es/config-provider";
import { Link, useNavigate } from "react-router-dom";
import { ProductService } from "red-canids-client-module/client-web";

export default function CardManagerProductsOrganisms({
  allValues,
  callBackClickEditProduct,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const colors = require("../../../styles/Colors.json");
  const navigate = useNavigate();
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    ProductService.deleteProductById(allValues._id.$oid)
      .then(() => {
        window.location.reload();
      })
      .catch((err) => console.error(err));
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleEditButton = (product) => {
    callBackClickEditProduct(product);
  };
  const items = [
    {
      label: "Excluir",
      key: "delete",
    },
  ];
  const onClick = ({ key }) => {
    if (key === "delete") {
      showModal();
    }
  };
  return (
    <>
      <Card
        hoverable
        style={{
          width: 240,
          height: 510,
        }}
        actions={[
          //<Link to={"/missions/detail/" + allValues._id.$oid} target="_blank">
          //<ExportOutlined key={"goToMission"} />
          //</Link>,
          <EditOutlined
            key="edit"
            onClick={() => {
              handleEditButton(allValues);
            }}
          />,
          <Dropdown
            trigger={["click"]}
            menu={{
              items,
              onClick,
            }}
          >
            <EllipsisOutlined key="ellipsis" />
          </Dropdown>,
        ]}
        cover={
          <img
            alt="example"
            src={allValues.image_product}
            height={300}
            style={{ objectFit: "cover" }}
          />
        }
      >
        <Meta
          title={allValues.name_product}
          description={allValues.description_product}
          style={{ height: 120 }}
        />
      </Card>
      <ConfigProvider
        theme={{
          components: {
            Modal: {
              contentBg: "white",
            },
          },
        }}
      >
        <Modal
          title="Excluir"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          okText="Sim"
          cancelText="Cancelar"
        >
          <p>Certeza que deseja excluir esse produto?</p>
        </Modal>
      </ConfigProvider>
    </>
  );
}
