import React from 'react'

export default function ImageCrown({ width, height, style }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="41" height="40" viewBox="0 0 41 40" fill="none"
            style={{
                width: width ? width : 30,
                height: height ? height : 30,
                ...style,
            }}>
            <g filter="url(#filter0_d_496_6450)">
                <g filter="url(#filter1_di_496_6450)">
                    <path d="M19.8398 28.7734L17.2197 23.0526C17.0595 22.7027 17.1058 22.2673 17.3341 21.9779L19.9542 18.6571C20.252 18.2797 20.75 18.2797 21.0478 18.6571L23.6679 21.9779C23.8962 22.2673 23.9426 22.7027 23.7823 23.0526L21.1622 28.7734C20.8744 29.4018 20.1276 29.4018 19.8398 28.7734Z" fill="#FFF8DE" />
                </g>
                <path d="M10.3594 28.3109C10.3594 27.8296 10.7613 27.4395 11.2572 27.4395H29.7377C30.2335 27.4395 30.6355 27.8296 30.6355 28.3109V31.7917C30.6355 32.273 30.2335 32.6632 29.7377 32.6632H11.2572C10.7613 32.6632 10.3594 32.273 10.3594 31.7917V28.3109Z" fill="#FF7A00" />
                <path d="M14.926 14.2547L18.9569 6.14259C19.5939 4.86067 21.4039 4.86067 22.0409 6.14259L26.0718 14.2547C26.5531 15.2233 27.7825 15.5184 28.6431 14.8718L31.9021 12.4232C33.1167 11.5106 34.8222 12.508 34.6453 14.0274L32.8568 29.3845C32.7545 30.263 32.018 30.9251 31.143 30.9251H9.85486C8.97988 30.9251 8.24338 30.263 8.14107 29.3845L6.35258 14.0274C6.17563 12.508 7.88109 11.5106 9.09569 12.4232L12.3547 14.8718C13.2153 15.5184 14.4447 15.2233 14.926 14.2547Z" fill="url(#paint0_radial_496_6450)" />
                <path d="M19.1441 17.7008C19.835 16.8146 21.1638 16.8146 21.8547 17.7008L24.5289 21.1308C25.0227 21.7642 25.0227 22.6573 24.5289 23.2907L21.8547 26.7207C21.1638 27.6069 19.835 27.6069 19.1441 26.7207L16.47 23.2907C15.9762 22.6573 15.9762 21.7642 16.47 21.1308L19.1441 17.7008Z" fill="url(#paint1_radial_496_6450)" />
            </g>
            <defs>
                <filter id="filter0_d_496_6450" x="-0.326823" y="-1.48551" width="41.6517" height="40.8153" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset />
                    <feGaussianBlur stdDeviation="3.33333" />
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.780392 0 0 0 0 0 0 0 0 0.3 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_496_6450" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_496_6450" result="shape" />
                </filter>
                <filter id="filter1_di_496_6450" x="10.4583" y="13.374" width="20.0853" height="24.2039" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="1.66667" />
                    <feGaussianBlur stdDeviation="3.33333" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.68 0" />
                    <feBlend mode="overlay" in2="BackgroundImageFix" result="effect1_dropShadow_496_6450" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_496_6450" result="shape" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="-1.66667" />
                    <feGaussianBlur stdDeviation="1.66667" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.914286 0 0 0 0 0.55 0 0 0 1 0" />
                    <feBlend mode="normal" in2="shape" result="effect2_innerShadow_496_6450" />
                </filter>
                <radialGradient id="paint0_radial_496_6450" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(14.7285 15.3916) rotate(56.9028) scale(18.542 16.5016)">
                    <stop stop-color="#FFEB81" />
                    <stop offset="0.682292" stop-color="#FFC839" />
                </radialGradient>
                <radialGradient id="paint1_radial_496_6450" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(18.7345 20.1759) rotate(65.4804) scale(6.97072 7.42398)">
                    <stop stop-color="#FF7D16" />
                    <stop offset="1" stop-color="#FF3E12" />
                </radialGradient>
            </defs>
        </svg>
    )
}
