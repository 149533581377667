import React, { useState } from 'react';
import { Collapse, ConfigProvider, Flex, Space } from 'antd';
import './FAQComponent.css';
const { Panel } = Collapse;

const faqData = [
    {
        question: 'Quando poderei resgatar produtos usando RED Coins?',
        answer: 'Quando você alcançar a quantidade certa de RED COINS, o Rufus vai te avisar pra que você resgate prêmios em nossa loja! O resgate é assim: Você recebe um cupom no valor do seu pedido e o utilizará na nossa loja!',
    },
    {
        question: 'Posso resgatar qualquer produto da Loja RED Canids?',
        answer: 'Os produtos disponíveis para resgate estarão assinalados com o preço de RED COINS ao lado!',
    },
    {
        question: 'Minhas RED Coins podem expirar?',
        answer: 'Não! Após conquistar suas RED COINS, elas são suas para que você possa stackar e resgatar os prêmios que quiser!',
    },
    {
        question: 'As missões para ganhar RED Coins renovam?',
        answer: 'As missões tem seu tempo determinado, após ele, ela expira e poderá voltar (ou não, então fique ligado!)',
    },
    {
        question: 'Preciso ter conta na Twitch ou Tiktok pra farmar RED Coins e XP?',
        answer: 'Não, você precisa estar cadastrado e ter um perfil na nossa plataforma!',
    }
];

const FAQComponent = () => {
    const [activeKey, setActiveKey] = useState([]);

    const handlePanelChange = (keys) => {
        setActiveKey(keys);
    };

    return (
        <ConfigProvider
            theme={{
                components: {
                    Collapse: {
                        contentBg: { background: 'var(--Grayscale-Slot-7, #121617)' },
                    },
                },
            }}
        >
            <Collapse
                accordion
                onChange={handlePanelChange}
                activeKey={activeKey}
                bordered={false}
                expandIcon={() => null}
                style={{
                    width: '50%', alignSelf: 'center', border: 'none', marginBottom: '20px',
                }}
                className='div-collapse-faq'
            >
                {
                    faqData.map((item, index) => (
                        <Panel
                            header={
                                <Flex vertical align="center" justify='center' style={{ borderRadius: '8px', background: 'var(--Grayscale-Slot-7, #121617)', boxShadow: '0px 2px 5px rgba(28, 36, 51, 0.10)' }}>
                                    <Flex justify='center' align='center' className='div-collapse-faq-panel-space'>
                                        <Space style={{
                                            color: 'var(--Grayscale-Slot-1, #FFF)',
                                            fontFamily: 'Montserrat',
                                            fontSize: '20px',
                                            fontWeight: 700,
                                            inlineSize: '100%',
                                            overflowWrap: 'break-word',
                                        }}> {item.question}
                                        </Space>
                                        <Space className='show-menu-icon'>

                                            {activeKey.includes(`${index}`) ?
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path d="M18 12H6" stroke="#FE304E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <path d="M12 6V12M12 12V18M12 12H18M12 12H6" stroke="#FE304E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                </svg>}
                                        </Space>
                                    </Flex>
                                </Flex>
                            }
                            key={index.toString()}
                            className='div-collapse-faq-panel'
                            style={{
                                background: 'var(--Grayscale-Slot-7, #121617)',
                                boxShadow: '0px 2px 5px rgba(28, 36, 51, 0.10)',
                                maxWidth: "80vw",
                                borderRadius: "10px",
                                marginTop: "20px",
                                justifyContent: "center",
                                alignItems: "center",
                                borderBottom: "1px solid var(--Base-Slot-5)",
                            }}
                        >
                            <p
                                style={{
                                    color: 'var(--Grayscale-Slot-1, #FFF)',
                                    fontFamily: 'Montserrat',
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    lineHeight: '24px',
                                }}
                            >
                                {item.answer}
                            </p>
                        </Panel>
                    ))
                }
            </Collapse >
        </ConfigProvider >
    );
};

export default FAQComponent;
