import React from 'react'

export default function ImageGift({ width, height, style }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none"
            style={{
                width: width ? width : 30,
                height: height ? height : 30,
                ...style,
            }}
        >
            <g filter="url(#filter0_d_496_11962)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.5849 9.4108L12.5212 6.16386C11.5924 5.56833 10.3555 6.01083 10.0074 7.06316L9.02037 10.0469C8.62259 11.2494 9.61972 12.4533 10.865 12.274L16.6147 11.4464C16.5993 11.5794 16.5914 11.7148 16.5914 11.852C16.5914 13.7724 18.1365 15.3292 20.0423 15.3292C21.9482 15.3292 23.4932 13.7724 23.4932 11.852C23.4932 11.7191 23.4858 11.5879 23.4714 11.4589L29.1341 12.274C30.3793 12.4533 31.3765 11.2494 30.9787 10.0469L29.9916 7.06316C29.6435 6.01083 28.4066 5.56833 27.4779 6.16386L22.4665 9.37725C21.8433 8.75741 20.9872 8.37479 20.0423 8.37479C19.0806 8.37479 18.2107 8.77124 17.5849 9.4108Z" fill="url(#paint0_linear_496_11962)" />
                <path d="M7.67383 17.0342C7.67383 16.0925 8.43142 15.3291 9.36597 15.3291H30.6299C31.5644 15.3291 32.322 16.0925 32.322 17.0342V32.2243C32.322 33.166 31.5644 33.9294 30.6299 33.9294H9.36597C8.43142 33.9294 7.67383 33.166 7.67383 32.2243V17.0342Z" fill="url(#paint1_linear_496_11962)" />
                <path d="M5.83203 12.5053C5.83203 11.5637 6.58963 10.8003 7.52417 10.8003H32.4712C33.4057 10.8003 34.1633 11.5637 34.1633 12.5053V17.1039C34.1633 18.0455 33.4057 18.8089 32.4712 18.8089H7.52417C6.58962 18.8089 5.83203 18.0455 5.83203 17.1039V12.5053Z" fill="url(#paint2_radial_496_11962)" />
                <path d="M15.9434 10.8003H24.0532V18.8089H15.9434V10.8003Z" fill="#FED546" />
                <path d="M16.502 18.8091H23.4893V33.9294H16.502V18.8091Z" fill="url(#paint3_linear_496_11962)" />
            </g>
            <defs>
                <filter id="filter0_d_496_11962" x="-2.5013" y="-2.43685" width="44.9987" height="44.6994" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset />
                    <feGaussianBlur stdDeviation="4.16667" />
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.528167 0 0 0 0 0.379167 0 0 0 0.29 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_496_11962" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_496_11962" result="shape" />
                </filter>
                <linearGradient id="paint0_linear_496_11962" x1="19.9995" y1="5.89648" x2="19.9995" y2="11.752" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFB81E" />
                    <stop offset="1" stop-color="#FED546" />
                </linearGradient>
                <linearGradient id="paint1_linear_496_11962" x1="19.9979" y1="15.3291" x2="19.9979" y2="33.9294" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FF2B2B" />
                    <stop offset="1" stop-color="#FF2E6C" />
                </linearGradient>
                <radialGradient id="paint2_radial_496_11962" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(10.4709 14.0697) rotate(61.073) scale(16.4761 15.3755)">
                    <stop stop-color="#FF5B6F" />
                    <stop offset="0.46875" stop-color="#FF444F" />
                </radialGradient>
                <linearGradient id="paint3_linear_496_11962" x1="19.9956" y1="18.8091" x2="19.9956" y2="33.9294" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFB81E" stop-opacity="0.66" />
                    <stop offset="0.608277" stop-color="#FFB81E" />
                </linearGradient>
            </defs>
        </svg>
    )
}
