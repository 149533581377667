import React from "react";


export default function TextCaption({ text, colorText, textAlign }) {
    return (
        <>
            <span
                style={{
                    fontFamily: "Urbanist",
                    fontWeight: 400,
                    fontSize: 12,
                    lineHeight: "22px",
                    textAlign: textAlign ? textAlign : "center",
                    color: colorText,
                }}>{text}
            </span>
        </>
    )
}