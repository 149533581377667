import React from "react";


export default function TextSemiboldH6({ text, colorText, fontSize }) {
    return (
        <>
            <span
                style={{
                    fontFamily: "Montserrat",
                    fontWeight: 600,
                    fontSize: fontSize == undefined ? 16 : fontSize,
                    lineHeight: "28px",
                    textAlign: "center",
                    color: colorText,
                }}>{text}
            </span>
        </>
    )
}