import { Button, Space } from "antd";
import React from "react";


export default function TextBoldParagraph({ text, textRed, colorText, textAlign, fontSize }) {
    return (
        <div style={{ display: "flex", justifyContent: "center", gap: "5px", alignItems: "center" }}>
            <span
                style={{
                    fontFamily: "Montserrat",
                    fontWeight: 700,
                    fontSize: 12,
                    lineHeight: fontSize ? fontSize : "24px",
                    textAlign: textAlign ? textAlign : "center",
                    color: colorText,
                }}>
                {text}
            </span>
            <span style={{
                fontFamily: "Montserrat",
                fontWeight: 700,
                fontSize: 12,
                lineHeight: "24px",
                textAlign: textAlign ? textAlign : "center",
                color: "red"
            }}>
                {textRed}
            </span>
        </div>
    )
}