import React, { useEffect, useMemo, useState } from "react";
import "./DetailMission.css";
import { Button, Flex, Skeleton } from "antd";
import ImageNormal from "../../atomos/ImageNormal";
import TextBoldParagraph from "../../atomos/TextBoldParagraph";
import TextBoldH2 from "../../atomos/TextBoldH2";
import TextRegularLabel from "../../atomos/TextRegularLabel";
import ImageRedCoins from "../../atomos/ImageRedCoins";
import TextBoldLabel from "../../atomos/TextBoldLabel";
import ImageTimerRed from "../../atomos/ImageTimerRed";
import { useNavigate } from "react-router-dom";
import TextBoldH4 from "../../atomos/TextBoldH4";
import checkIsMobile from "../../../utils/CheckIsMobile";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ImageRare from "../../atomos/ImageRare";
import { MissionService } from "red-canids-client-module/client-web";
export default function DetailMission() {
  const colors = require("../../../styles/Colors.json");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [missionSelec, setMissionSelec] = useState({});
  const [loading, setLoading] = useState(true);
  const [timeRemaining, setTimeRemaining] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  const { id_mission } = useParams();
  const calculateTimeRemaining = () => {
    const expirationDate = new Date(missionSelec?.expiration_date || 0);
    const now = new Date();
    const difference = expirationDate - now;

    const hours = Math.floor(difference / 3600000);
    const minutes = Math.floor((difference % 3600000) / 60000);
    const seconds = Math.floor((difference % 60000) / 1000);

    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");

    return { hours, minutes: formattedMinutes, seconds: formattedSeconds };
  };

  useEffect(() => {
    try {
      MissionService.returnMissionById(id_mission).then((res) => {
        setMissionSelec((prevMission) => {
          if (prevMission) {
            return res.data;
          }
          return prevMission;
        });

        setLoading(false);
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
      });
    } catch (err) {
      console.error(err);
      setLoading(false);
    }

    return () => {
      
    };
  }, [id_mission, setMissionSelec]);
  useEffect(() => {
    if (!loading) {
      const interval = setInterval(() => {
        setTimeRemaining(calculateTimeRemaining());
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [calculateTimeRemaining]);

  const navigateToMission = () => {
    navigate(`/missions/detail/watch-video/${id_mission}`);
  };

  return (
    <>
      {!checkIsMobile() ? (
        <Flex
          style={{
            height: 600,
            width: "90%",
            alignSelf: "center",
            marginTop: 50,
          }}
          gap={"middle"}
        >
          <Flex flex={1} style={{}} justify="center">
            <Flex
              flex={1}
              justify={"center"}
              align={"center"}
              style={{ maxWidth: 600, borderRadius: 20, overflow: "hidden" }}
            >
              {loading ? (
                <Skeleton.Image
                  active={true}
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              ) : (
                <img
                  src={missionSelec.image_mission}
                  className="image-detail-mission"
                />
              )}
            </Flex>
          </Flex>
          <Flex flex={1} align="center" style={{ flexDirection: "column" }}>
            <Flex
              flex={1}
              style={{ flexDirection: "column", maxWidth: 600, width: 500 }}
              justify="space-evenly"
              align={"flex-start"}
            >
              {loading ? (
                <Skeleton.Input
                  active
                  style={{
                    width: "30%",
                    height: "100%",
                    objectFit: "cover",
                    background: "#0a0d0d",
                  }}
                />
              ) : missionSelec.classification === "rare" ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ImageRare />
                  <TextBoldParagraph
                    text={"Missão Rara"}
                    colorText={colors.AlertSlotColor}
                  />
                </div>
              ) : (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ImageNormal />
                  <TextBoldParagraph
                    text={"Missão Regular"}
                    colorText={colors.BaseSlot2}
                  />
                </div>
              )}
              {loading ? (
                <Skeleton.Input
                  active
                  style={{
                    width: "20vw",
                    height: "100%",
                    objectFit: "cover",
                    background: "#0a0d0d",
                  }}
                />
              ) : (
                <TextBoldH2
                  text={missionSelec.name_mission}
                  textAlign={"start"}
                />
              )}

              <Flex gap={30} style={{ width: "100%" }}>
                {loading ? (
                  <>
                    <Skeleton.Input
                      active
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        background: "#0a0d0d",
                      }}
                    />
                    <Skeleton.Input
                      active
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        background: "#0a0d0d",
                      }}
                    />
                  </>
                ) : (
                  <>
                    {/* <div className="div-children-info-mission-detail">
                      <div
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <ImageRedCoins />
                        <TextBoldLabel
                          text={"Ganhe"}
                          colorText={colors.Grayscale}
                        />
                      </div>
                      <div
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <TextBoldParagraph
                          text={missionSelec.points}
                          colorText={colors.BaseSlot2}
                        />
                        <TextBoldParagraph
                          text={
                            missionSelec.type_points == "red_coins"
                              ? "RED Coins"
                              : "RED XP"
                          }
                          colorText={colors.BaseSlot2}
                        />
                      </div>
                    </div> */}
                    <div className="div-children-info-mission-detail">
                      <div
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          alignItems: "center",
                          gap: 5,
                        }}
                      >
                        <ImageTimerRed />
                        <TextBoldLabel
                          text={"Tempo restante"}
                          colorText={colors.Grayscale}
                        />
                      </div>
                      <div
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <TextBoldParagraph
                          text={`${timeRemaining.hours}:${timeRemaining.minutes}:${timeRemaining.seconds}`}
                          colorText={colors.Grayscale}
                        />
                      </div>
                    </div>
                  </>
                )}
              </Flex>
              {loading ? (
                <Skeleton.Input
                  active
                  style={{
                    width: "100%",
                    background: "#0a0d0d",
                  }}
                />
              ) : (
                <TextRegularLabel
                  textAlign={"start"}
                  text={missionSelec.description_mission}
                  colorText={colors.Grayscale}
                />
              )}

              <div
                style={{
                  height: 100,
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={loading}
                  className="button-border"
                  style={{ width: "100%" }}
                  onClick={navigateToMission}
                >
                  Aceitar desafio
                </Button>
              </div>
            </Flex>
          </Flex>
        </Flex>
      ) : (
        <Flex
          style={{ width: "95%", alignSelf: "center", marginTop: 50 }}
          gap={30}
          align={"center"}
          vertical
        >
          <Flex
            flex={1}
            style={{ maxWidth: 600, borderRadius: 20, overflow: "hidden" }}
          >
            {loading ? (
              <Skeleton.Image
                active={true}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            ) : (
              <img
                src={missionSelec.image_mission}
                className="image-detail-mission"
              />
            )}
          </Flex>
          <Flex
            flex={1}
            align="center"
            style={{ flexDirection: "column", width: "100%" }}
          >
            <Flex
              flex={1}
              gap={30}
              style={{ flexDirection: "column", width: "100%" }}
              justify="space-evenly"
              align={"flex-start"}
            >
              <Flex>
                {loading ? (
                  <Skeleton.Input
                    active
                    style={{
                      background: "#0a0d0d",
                    }}
                  />
                ) : missionSelec.classification === "rare" ? (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <ImageRare />
                    <TextBoldParagraph
                      text={"Missão Rara"}
                      colorText={colors.AlertSlotColor}
                    />
                  </div>
                ) : (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <ImageNormal />
                    <TextBoldParagraph
                      text={"Missão Regular"}
                      colorText={colors.BaseSlot2}
                    />
                  </div>
                )}
              </Flex>
              <Flex>
                {loading ? (
                  <Skeleton.Input
                    active
                    style={{
                      background: "#0a0d0d",
                    }}
                  />
                ) : (
                  <TextBoldH4
                    text={missionSelec.name_mission}
                    textAlign={"start"}
                  />
                )}
              </Flex>
              <Flex gap={20} style={{ width: "100%" }}>
                {loading ? (
                  <>
                    <Skeleton.Input
                      active
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        background: "#0a0d0d",
                      }}
                    />
                    <Skeleton.Input
                      active
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        background: "#0a0d0d",
                      }}
                    />
                  </>
                ) : (
                  <>
                    {/* <div className="div-children-info-mission-detail">
                      <div
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <ImageRedCoins />
                        <TextBoldLabel
                          text={"Ganhe"}
                          colorText={colors.Grayscale}
                        />
                      </div>
                      <div
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <TextBoldParagraph
                          text={missionSelec.points}
                          colorText={colors.BaseSlot2}
                        />
                        <TextBoldParagraph
                          text={
                            missionSelec.type_points == "red_coins"
                              ? "RED Coins"
                              : "RED XP"
                          }
                          colorText={colors.BaseSlot2}
                        />
                      </div>
                    </div> */}
                    <div className="div-children-info-mission-detail">
                      <div
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          alignItems: "center",
                          gap: 5,
                        }}
                      >
                        <ImageTimerRed />
                        <TextBoldLabel
                          text={"Tempo restante"}
                          colorText={colors.Grayscale}
                        />
                      </div>
                      <div
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <TextBoldParagraph
                          text={`${timeRemaining.hours}:${timeRemaining.minutes}:${timeRemaining.seconds}`}
                          colorText={colors.Grayscale}
                        />
                      </div>
                    </div>
                  </>
                )}
              </Flex>
              {loading ? (
                <Skeleton.Input
                  active
                  style={{
                    width: "100%",
                    background: "#0a0d0d",
                  }}
                />
              ) : (
                <TextRegularLabel
                  textAlign={"start"}
                  text={missionSelec.description_mission}
                  colorText={colors.Grayscale}
                />
              )}
              <div
                style={{
                  height: 100,
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  className="button-border"
                  disabled={loading}
                  style={{ width: "100%" }}
                  onClick={navigateToMission}
                >
                  Aceitar desafio
                </Button>
              </div>
            </Flex>
          </Flex>
        </Flex>
      )}
    </>
  );
}
