import React from 'react'
import Spline from "@splinetool/react-spline";

export default function LogoRedDimension({setLoading}) {
  return (
    <div style={{ height: "100vh", width: "100%" }}>
      {/* <Spline
        scene="https://prod.spline.design/f9421fe6GUxtObHr/scene.splinecode"
        onLoad={(laod) => {
          setLoading(laod.disposed)
        }}
      /> */}
      <img onLoad={() => setLoading(false)} src='https://redcanids.com.br/imgTime/bg/league-of-legends.jpg' style={{height: "100%", width: "100%", objectFit: "cover"}}/>
    </div>
  )
}
