import { ConfigProvider, Form, InputNumber, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { AccountService } from "red-canids-client-module/client-web";
import { encryptAES } from "../../../utils/Crypt";

export default function ModalAddRedCoinsXp({
  redCoins,
  redXp,
  onCancelRedCoinsModal,
  onCancelRedXpModal,
  redCoinsValue,
  redXpValue,
  selectedUser,
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (redCoins) {
      form.setFieldsValue({ red_value: redCoinsValue });
      form.setFields([
        { name: "red_value", value: redCoinsValue },
        {
          name: "red_value",
          rules: [
            {
              required: true,
              message: "Please input RedCoins!",
            },
            {
              validator: (_, value) =>
                isPositiveNumber(value)
                  ? Promise.resolve()
                  : Promise.reject("RedCoins cannot be negative!"),
            },
          ],
        },
      ]);
    } else if (redXp) {
      form.setFieldsValue({ red_value: redXpValue });
      form.setFields([
        { name: "red_value", value: redXpValue },
        {
          name: "red_value",
          rules: [
            {
              required: true,
              message: "Please input RedXp!",
            },
            {
              validator: (_, value) =>
                isPositiveNumber(value)
                  ? Promise.resolve()
                  : Promise.reject("RedXp cannot be negative!"),
            },
          ],
        },
      ]);
    }
  }, [redCoins, redXp, redCoinsValue, redXpValue, form]);

  const handleFinish = (values, serviceMethod, onCancelModal) => {
    setLoading(true);
    
    serviceMethod(selectedUser, values.red_value, encryptAES(new Date().toISOString()))
      .then((res) => {
        
        onCancelModal();
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
        window.location.reload();
      });
  };

  const handleFinishFailed = (errorInfo) => {
  };

  const isPositiveNumber = (value) => value >= 0;

  return (
    <ConfigProvider theme={{ components: { Modal: { contentBg: "white" } } }}>
      <Modal
        open={redCoins || redXp}
        onCancel={() =>
          redCoins ? onCancelRedCoinsModal() : onCancelRedXpModal()
        }
        onOk={() => form.submit()}
        confirmLoading={loading}
      >
        {redCoins || redXp ? (
          <Form
            form={form}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onFinish={(values) =>
              handleFinish(
                values,
                redCoins ? AccountService.addRedCoins : AccountService.addRedXp,
                redCoins ? onCancelRedCoinsModal : onCancelRedXpModal
              )
            }
            onFinishFailed={handleFinishFailed}
          >
            <Form.Item
              label={`Adicionar ${redCoins ? "RedCoins" : "RedXp"}`}
              name="red_value"
              rules={[
                {
                  required: true,
                  message: "Please input",
                },
                {
                  validator: (_, value) =>
                    isPositiveNumber(value)
                      ? Promise.resolve()
                      : Promise.reject("Cannot be negative!"),
                },
              ]}
            >
              <InputNumber />
            </Form.Item>
          </Form>
        ) : null}
      </Modal>
    </ConfigProvider>
  );
}
